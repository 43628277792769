import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react";
import { PublisherBookType } from "../../api/types";
import { NavLink } from "react-router-dom";
import bookCover from "../../../../assets/default-book-cover.jpg";
import { analyticsEvents } from "../../../../utilities/analyticsEvents";
import { slugify } from "transliteration";
export const PublisherBookItem: React.FC<PublisherBookType> = ({
  id,
  name,
  authors,
  publishingHouse,
  cover,
  transliteratedName,
}) => {
  return (
    <NavLink
      onClick={() => analyticsEvents.selectBook(id)}
      to={`/books/${transliteratedName}`}
    >
      <HStack
        height="100%"
        flexGrow={1}
        minWidth="100%"
        px="auto"
        spacing={{ base: "1rem", sm: "0.5rem", md: "1rem", xl: "1.5rem" }}
        backgroundColor="white"
        borderRadius={{ base: "1rem", sm: "0.5rem", md: "1rem", xl: "1.25rem" }}
        padding={{ base: "0.5rem", sm: "0.35rem", md: "0.5rem", xl: "0.75rem" }}
      >
        <Image
          style={{ aspectRatio: 0.7 }}
          width={{ base: "7.75rem", sm: "5rem", md: "7rem", "2xl": "8.875rem" }}
          objectFit="cover"
          objectPosition="center"
          transition="all 0.3s"
          fallbackStrategy="onError"
          src={cover === "" ? "https://localhost:65536/" : cover}
          fallbackSrc={bookCover}
          _hover={{
            transform: "scale(1.05)",
            transformOrigin: "center center",
          }}
          alt={name}
        />
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          gap={{ base: "0,75rem", sm: "0.5rem", md: "0.75rem", xl: "1rem" }}
        >
          <Text
            fontWeight={400}
            fontSize={{
              base: "0.75rem",
              sm: "0.65rem",
              md: "0.75rem",
              xl: "0.875rem",
            }}
            color="orange"
          >
            {publishingHouse}
          </Text>
          <Box>
            <Text
              noOfLines={4}
              fontWeight={500}
              fontSize={{
                base: "1rem",
                sm: "0.75rem",
                md: "0.875rem",
                xl: "1.25rem",
              }}
              variant="primary"
              marginBottom={{
                base: "0.31rem",
                sm: "0.25rem",
                md: "0.35rem",
                xl: "0.5rem",
              }}
              as="h4"
            >
              {name}
            </Text>
            <Text
              fontWeight={400}
              fontSize={{
                base: "0.75rem",
                sm: "0.65rem",
                md: "0.75rem",
                xl: "0.875rem",
              }}
              variant="secondary"
            >
              {authors[0]}
            </Text>
          </Box>
        </Flex>
      </HStack>
    </NavLink>
  );
};
