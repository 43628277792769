import { FunctionComponent } from "react";

interface ThinkingEmojiIconProps {
  width?: string | number;
}

const ThinkingEmojiIcon: FunctionComponent<ThinkingEmojiIconProps> = ({
  width,
}) => {
  return (
    <svg
      width={width ?? "53"}
      height={width??'52'}
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2157 50.7659C13.605 50.7659 0 42.856 0 25.4994C0 8.14282 13.605 0.23291 26.2157 0.23291C33.2216 0.23291 39.6851 2.53808 44.4763 6.74163C49.6742 11.352 52.4314 17.8607 52.4314 25.4994C52.4314 33.1381 49.6742 39.6017 44.4763 44.212C39.6851 48.4155 33.1764 50.7659 26.2157 50.7659Z"
        fill="url(#paint0_radial_5144_761)"
      />
      <path
        d="M26.2157 50.7659C13.605 50.7659 0 42.856 0 25.4994C0 8.14282 13.605 0.23291 26.2157 0.23291C33.2216 0.23291 39.6851 2.53808 44.4763 6.74163C49.6742 11.352 52.4314 17.8607 52.4314 25.4994C52.4314 33.1381 49.6742 39.6017 44.4763 44.212C39.6851 48.4155 33.1764 50.7659 26.2157 50.7659Z"
        fill="url(#paint1_linear_5144_761)"
      />
      <path
        d="M47.8618 10.4797C50.2709 14.3669 51.5275 18.9953 51.5275 24.1435C51.5275 31.7822 48.7703 38.2458 43.5724 42.8561C38.7812 47.0597 32.2725 49.41 25.3118 49.41C17.1487 49.41 8.58797 46.0879 3.53467 39.0413C8.39813 47.0235 17.5465 50.766 26.2158 50.766C33.1765 50.766 39.6852 48.4157 44.4763 44.2121C49.6743 39.6018 52.4315 33.1382 52.4315 25.4995C52.4315 19.7411 50.863 14.6245 47.8618 10.4797Z"
        fill="#EB8F00"
      />
      <path
        opacity="0.66"
        d="M0.397705 31.0183C0.8497 29.8386 2.84752 29.8205 4.32554 30.5347C7.18667 31.9223 6.84767 34.9597 8.28049 35.5654C9.71332 36.1711 14.2378 32.4602 20.5973 31.8726C21.3115 31.8048 25.5286 31.2759 25.9444 33.0478C26.1072 33.7484 24.9139 33.884 24.9139 33.884C22.3285 34.8422 19.6979 35.8185 17.3837 37.3327C14.7576 39.0457 12.4614 41.4684 9.41048 42.1826C8.22173 42.4583 6.87931 42.4176 5.91204 41.6809C4.70973 40.7769 4.44306 39.1181 4.00462 37.6762C3.50291 36.0264 2.67576 34.4896 1.58193 33.1562"
        fill="url(#paint2_linear_5144_761)"
      />
      <path
        d="M0.343396 31.0137C-0.479234 32.0126 1.26999 34.6658 1.58186 36.0173C2.33217 39.2807 1.58186 40.0852 1.5231 42.987C1.47338 46.1781 2.42709 48.863 4.85882 50.576C7.14592 52.1987 9.66353 52.384 13.1891 51.3444C16.7146 50.3048 19.0967 49.8031 19.2775 48.0675C19.3769 47.3488 18.9972 46.6437 18.3373 46.3363C18.6899 46.1691 18.9701 45.8843 19.1283 45.5272C19.969 43.5204 18.2921 43.0955 18.2921 43.0955C18.5226 42.8198 18.717 42.517 18.8707 42.1915C19.3724 41.1926 19.291 39.8366 17.8446 39.3937C17.1802 39.1903 16.9994 39.2988 16.9994 39.2988C17.6864 38.5936 18.5091 38.0287 19.4176 37.649C21.8493 36.6049 24.733 35.9721 25.4517 35.3619C26.4958 34.4579 26.5591 32.7177 24.7149 32.1979C22.0663 31.4205 17.1938 33.2285 14.2287 35.1133C11.2636 36.9981 8.54258 38.0151 7.63859 37.6716C6.7346 37.3281 6.17413 36.7676 5.83061 35.4116C5.1933 32.8895 4.70062 31.9132 3.62036 30.9685C2.72541 30.2001 0.976189 30.2453 0.343396 31.0137Z"
        fill="url(#paint3_radial_5144_761)"
      />
      <path
        d="M15.0423 40.8131C15.0423 40.8131 14.3778 40.3611 17.031 38.2413C18.839 36.7904 19.8831 36.5282 22.0482 35.9813C26.4235 34.9101 26.1478 33.5315 26.1478 33.5315C26.3828 35.561 24.3759 36.0129 22.907 36.474C22.7533 36.5192 22.6086 36.5779 22.455 36.6141C17.4243 37.8978 15.666 41.2154 15.0423 40.8131Z"
        fill="url(#paint4_linear_5144_761)"
      />
      <path
        d="M19.1646 45.8437C19.5578 45.1928 19.6663 44.4018 19.4584 43.6696C19.3137 43.2402 19.038 42.8651 18.6629 42.6074C18.7171 42.5351 18.7713 42.4673 18.8211 42.3859C19.2233 41.7577 19.2821 40.9667 18.9793 40.2842C18.6357 39.7463 18.0753 39.3892 17.447 39.3079C17.8764 39.5158 18.1973 39.9 18.3239 40.361C18.3962 40.917 18.3148 41.4865 18.0979 42.0018C17.8086 42.7069 17.1622 43.1995 16.4074 43.299C15.3136 43.5159 15.6481 43.9679 15.6481 43.9679C15.743 44.3295 17.0357 43.995 18.0165 43.2628C18.2922 43.4075 18.5001 43.6561 18.5996 43.9498C18.7035 44.4064 18.6403 44.89 18.4233 45.3058C18.1205 45.9703 17.5329 46.4675 16.8278 46.6618C15.856 46.9285 16.1226 47.2856 16.1226 47.2856C16.1226 47.2856 16.1678 48.054 18.1657 46.7884C18.4007 47.2404 18.7126 48.2483 18.0888 48.8992C15.9735 51.1275 9.74048 51.9863 9.74048 51.9863C12.9044 51.9863 17.2526 50.7659 18.5996 49.6495C19.1239 49.2382 19.4312 48.6144 19.4358 47.95C19.4945 47.3308 19.264 46.7161 18.8165 46.2821C18.9431 46.1465 19.0606 46.0019 19.1646 45.8437Z"
        fill="url(#paint5_linear_5144_761)"
      />
      <path
        d="M32.2137 29.3006C32.0691 29.2735 31.9335 29.2147 31.8205 29.1198C29.782 27.4836 27.3503 26.4169 24.7694 26.0191C22.1885 25.5717 19.5398 25.8157 17.0855 26.7243C16.9499 26.7785 16.8052 26.7966 16.6606 26.774C16.3442 26.7197 16.0866 26.4847 16.0007 26.1728C15.9148 25.8655 16.0188 25.54 16.2628 25.3366C21.054 21.3591 28.1593 22.019 32.1369 26.8101C32.4623 27.2034 32.7607 27.6192 33.0318 28.0486C33.2036 28.3153 33.2036 28.6588 33.0318 28.9255C32.8556 29.2012 32.5347 29.3458 32.2137 29.3006Z"
        fill="#422B0D"
      />
      <path
        d="M33.9763 11.1306C35.8702 11.1306 37.5923 12.7307 37.5923 15.3884C37.5923 18.0461 35.8702 19.6417 33.9763 19.6417C32.0825 19.6417 30.3604 18.0416 30.3604 15.3884C30.3604 12.7352 32.0825 11.1306 33.9763 11.1306Z"
        fill="#422B0D"
      />
      <path
        d="M18.324 7.01294C16.4301 7.01294 14.708 8.613 14.708 11.2662C14.708 13.9194 16.4301 15.524 18.324 15.524C20.2223 15.524 21.9399 13.9239 21.9399 11.2662C21.9399 8.60848 20.2223 7.01294 18.324 7.01294Z"
        fill="#422B0D"
      />
      <path
        d="M15.4265 1.87372C15.2231 1.53925 15.196 1.12341 15.3542 0.766335C15.526 0.409259 15.865 0.165181 16.2582 0.115462C18.7216 -0.268734 21.2346 0.31434 23.2777 1.73812C24.0415 2.26244 24.7195 2.89523 25.2936 3.62294C25.3297 3.66814 25.3614 3.71786 25.393 3.7721C25.7004 4.25121 25.5964 4.88401 25.1534 5.24108C24.6788 5.63432 23.9828 5.62076 23.5263 5.20492L23.7161 4.89757L23.4856 5.16876C21.5058 3.51446 19.0289 2.56979 16.4525 2.49295C16.0231 2.49295 15.6254 2.26696 15.4084 1.89632L15.4265 1.87372Z"
        fill="#422B0D"
      />
      <path
        d="M39.5045 9.95979C39.8389 9.77447 40.0649 9.43548 40.1101 9.0558C40.1373 8.66257 39.97 8.28289 39.6581 8.04333C37.701 6.48395 35.215 5.73816 32.7245 5.9732C31.8025 6.05004 30.8985 6.26699 30.0397 6.61503L29.877 6.69187C29.3753 6.95855 29.1538 7.5597 29.3617 8.08853C29.5832 8.66257 30.1843 8.988 30.7855 8.85692V8.49533L30.8488 8.84788C33.3799 8.37781 35.997 8.77105 38.2796 9.96431C38.6547 10.1767 39.1112 10.1767 39.4864 9.96431L39.5045 9.95979Z"
        fill="#422B0D"
      />
      <defs>
        <radialGradient
          id="paint0_radial_5144_761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.2157 25.4994) scale(25.7455 25.7455)"
        >
          <stop offset="0.5" stop-color="#FDE030" />
          <stop offset="0.92" stop-color="#F7C02B" />
          <stop offset="1" stop-color="#F4A223" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_5144_761"
          x1="26.2157"
          y1="50.7659"
          x2="26.2157"
          y2="0.23291"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F4A223" />
          <stop offset="0.08" stop-color="#F7C02B" />
          <stop offset="0.54" stop-color="#FDE030" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5144_761"
          x1="13.2766"
          y1="45.1657"
          x2="13.1636"
          y2="30.0782"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BF360C" />
          <stop offset="1" stop-color="#BF360C" stop-opacity="0.2" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_5144_761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.2039 34.725) scale(26.7626 25.1568)"
        >
          <stop offset="0.27" stop-color="#FFF176" />
          <stop offset="1" stop-color="#FFC400" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_5144_761"
          x1="15.2236"
          y1="39.8425"
          x2="26.6094"
          y2="34.1881"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stop-color="#DA6727" />
          <stop offset="1" stop-color="#FFC400" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5144_761"
          x1="14.6352"
          y1="51.0688"
          x2="14.7301"
          y2="33.9065"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stop-color="#DA6727" />
          <stop offset="1" stop-color="#FFC400" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ThinkingEmojiIcon;
