import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  Link,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import bookmark from "../../assets/Bookmark.svg";
import frame from "../../assets/AboutFrame.png";
import mobileFrame from "../../assets/MobileAboutFrame.png";
import phoneImage from "../../assets/Phone.png";
import appleStoreBadge from "../../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import googlePlayBadge from "../../assets/google-play-badge.png";
import franko from "../../assets/Franko.svg";
import Lottie from "lottie-react";
import animation from "../../assets/Splash.json";
import Seo from "../../ui/components/Seo/Seo";

interface AboutPageProps {}

const AboutPage: FunctionComponent<AboutPageProps> = () => {
  const frameSource = useBreakpointValue({ base: mobileFrame, sm: frame });
  return (
    <>
      <Seo description="Про читанку" title="Про читанку" />
      <Flex
        px={{
          base: "0rem",
          sm: "1rem",
          md: "2rem",
          xl: "3rem",
          "2xl": "4.75rem",
        }}
        gap={{
          base: "5rem",
          md: "7.5rem",
          xl: "8.5rem",
          "2xl": "10rem",
        }}
        flexDirection="column"
        alignItems="center"
      >
        <Image
          objectFit="fill"
          objectPosition="center"
          position="relative"
          top={{ base: "-8.5rem", md: "-6.5rem" }}
          marginBottom={{ base: "-8.5rem", md: "-6.5rem" }}
          src={bookmark}
          alt="Bookmark"
        />
        <Box>
          <Text
            fontSize={{
              base: "1rem",
              sm: "1.5rem",
              md: "1,75rem",
              xl: "2.25rem",
            }}
            as="h1"
            margin="0 auto"
            marginBottom={{ base: "5rem", sm: "2rem" }}
            maxWidth={{ base: "86%", sm: "80%", md: "66%" }}
            textAlign="center"
            fontWeight={850}
          >
            Читанка — це книжкова платформа для продажу та читання книжок
            українською мовою. Її історія почалася разом з пандемією, коли все
            життя перевернулося з ніг на голову. 
          </Text>
          <Flex
            alignItems="center"
            flexDirection="column"
            width="100%"
            height="fit-content"
            position="relative"
          >
            <Image
              objectFit="fill"
              width="100%"
              height="100%"
              zIndex={2}
              position="absolute"
              src={frameSource}
              alt="Frame"
            />
            <Image
              alt="Phone image"
              zIndex={3}
              marginTop={{
                base: "2rem",
                md: "7.5rem",
                "2xl": "10rem",
              }}
              marginBottom={{
                base: "5rem",
                sm: "2.5rem",
                md: "5rem",
                "2xl": "10rem",
              }}
              height="auto"
              width={{ base: "46.75%", sm: "30%", md: "30%", xl: "23.25%" }}
              src={phoneImage}
            />
            <Text
              fontWeight={300}
              fontSize={{
                base: "1rem",
                sm: "1.5rem",
                md: "1,75rem",
                xl: "2.25rem",
              }}
              maxWidth={{ base: "86%", sm: "80%", md: "66%" }}
              textAlign="center"
              fontFamily="Garet"
              as="h2"
            >
              Ми прагнемо бути саме тим додатком, котрий ви відкриватимете, коли
              захочеться почитати будь-що українською. Вже зараз нашими
              партнерами є декілька знакових українських видавництв і ми
              постійно працюємо над залученням нових.
            </Text>
            <HStack
              flexDirection={{ base: "column", sm: "row" }}
              marginBottom={{
                base: "5rem",
                md: "7.5rem",
                xl: "8.5rem",
                "2xl": "10rem",
              }}
              marginTop={{ base: "2.5rem", xl: "5rem" }}
              minWidth="fit-content"
              width={{ base: "100%", sm: "auto" }}
              gap={{ base: "0.75rem", md: "1.25rem" }}
            >
              <Link
                maxWidth={{ base: "55%", md: "51.25%" }}
                isExternal
                href="https://play.google.com/store/apps/details?id=com.beleven.chytanka&hl=uk&gl=US"
              >
                <Image
                  width={{ base: "%", sm: "auto" }}
                  height={{
                    base: "auto",
                    sm: "3rem",
                    xl: "4rem",
                  }}
                  src={googlePlayBadge}
                  alt="Google badge"
                />
              </Link>
              <Link
                margin="0 !important"
                width={{ base: "55%", md: "46.25%" }}
                isExternal
                href="https://apps.apple.com/ua/app/%D1%87%D0%B8%D1%82%D0%B0%D0%BD%D0%BA%D0%B0/id1543172038"
              >
                <Image
                  flexShrink={1}
                  width={{ base: "100%", sm: "auto" }}
                  height={{
                    base: "auto",
                    sm: "3rem",
                    xl: "4rem",
                  }}
                  src={appleStoreBadge}
                  alt="App store badge"
                />
              </Link>
            </HStack>
          </Flex>
        </Box>

        <Stack
          alignItems="center"
          px={{
            base: "1.25rem",
            sm: "5rem",
            md: "8rem",
            xl: "12rem",
            "2xl": "17.5rem",
          }}
          gap={{
            base: "2rem",
            sm: "1.5rem",
            md: "3rem",
            "2xl": "6.25rem",
          }}
        >
          <Image
            width={{ base: "100%", sm: "75%", lg: "50.6%" }}
            src={franko}
            alt="Franko"
          />
          <Text
            fontWeight={300}
            fontSize={{
              base: "1rem",
              sm: "1.5rem",
              md: "1,75rem",
              xl: "2.25rem",
            }}
            textAlign="center"
            fontFamily="Garet"
            as="h2"
          >
            Іншим напрямком є оцифрування української класики, котра вже стала
            громадським надбанням. Знана і не дуже відома; та, котру всі читали
            в школі і та, про котру ніхто з друзів і не чув.
          </Text>
        </Stack>
        <Flex
          justifyContent="center"
          width={{ base: "65%", md: "51.5%" }}
          marginTop={{
            base: "-2rem",
            sm: "-1.5rem",
            md: "-3rem",
            "2xl": "-6.25rem",
          }}
        >
          <Lottie
            marginWidth={0}
            style={{ width: "100%" }}
            animationData={animation}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default AboutPage;
