import {
  Box,
  Container,
  Flex,
  HStack,
  Image,
  List,
  ListItem,
  Text,
  IconButton,
  Link,
  Show,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Stack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import logo from "../../../assets/Logo-black.svg";
import { CatalogueIcon } from "../../icons/CatalogueIcon";
import { SearchIcon } from "../../icons/SearchIcon";
import { NavLink, useLocation } from "react-router-dom";
import googlePlayBadge from "../../../assets/google-play-badge.png";
import appleStorebadge from "../../../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import GooglePlayIcon from "../../icons/GooglePlayIcon";
import { AppStoreIcon } from "../../icons/AppStoreIcon";
import AppleIcon from "../../icons/AppleIcon";
import BurgerMenuIcon from "../../icons/BurgerMenuIcon";
import HamburgerMenu from "./HamburgerMenu";
import { FaChevronDown } from "react-icons/fa";
import { ExternalLinkIcon } from "../../icons/ExternalLinkIcon";
import SearchPanel from "../../../features/SearchPanel/components/SearchPanel";
import UserIcon from "../../icons/UserIcon";
import { auth } from "../../../utilities/firebase";
import useActualUser from "../../../utilities/useActualUser";
export const Header: React.FC = () => {
  const [displayBurgerMenu, setDisplayBurgerMenu] = useState<boolean>(false);
  const [displaySearchPanel, setDisplaySearchPanel] = useState<boolean>(false);

  const { user } = useActualUser();
  const location = useLocation();

  const isLoggedIn = user !== null && user?.emailVerified != false;

  return (
    <Container
      zIndex={1001}
      position="sticky"
      top="0"
      px={{
        base: "1.25rem",
        sm: "1.5rem",
        md: "3rem",
        xl: "4rem",
        "2xl": "8.5rem",
      }}
      py={{
        base: "1.125rem",
        sm: "0.625rem",
        md: "0.75rem",
        lg: "0.875rem",
        xl: "1.25rem",
      }}
      bg="white"
      maxWidth="1920px"
      as="header"
    >
      <Show above="sm" ssr={false}>
        <Flex justifyContent="space-between" alignItems="center">
          <NavLink to="/">
            <Image
              width={{
                base: "5rem",
                sm: "6.25rem",
                md: "9.375rem",
                lg: "12.187rem",
                xl: "14.75rem",
              }}
              marginBottom={{ base: "0.25rem", md: "0.4rem", xl: "0.5rem" }}
              src={logo}
              alt="Logo"
            />
          </NavLink>

          <HStack
            spacing={{
              base: "1rem",
              sm: "1.5rem",
              md: "1.75rem",
              xl: "2.5rem",
              "2xl": "3.25rem",
            }}
          >
            <List>
              <HStack
                spacing={{
                  base: "1rem",
                  md: "1.25rem",
                  lg: "1.875rem",
                  xl: "3.125rem",
                }}
              >
                <ListItem _hover={{ opacity: 0.7, transition: "0.3s linear" }}>
                  <NavLink to="/catalogue">
                    {({ isActive, isPending }) => (
                      <HStack
                        spacing={{
                          base: "0.187rem",
                          sm: "0.312rem",
                          md: "0.437rem",
                          lg: "0.562rem",
                          xl: "0.625rem",
                        }}
                      >
                        <CatalogueIcon
                          width={{
                            base: "0.5rem",
                            sm: "0.625rem",
                            md: "0.75rem",
                            lg: "0.9rem",
                            xl: "1.06rem",
                          }}
                          fill={isActive ? "#FF5500" : "black"}
                        />
                        <Text
                          color={isActive ? "#FF5500" : "black"}
                          size={{ base: "xs", md: "sm", xl: "md" }}
                          variant="primary"
                        >
                          Каталог
                        </Text>
                      </HStack>
                    )}
                  </NavLink>
                </ListItem>
                <ListItem _hover={{ opacity: 0.7, transition: "0.3s linear" }}>
                  <NavLink to="/about">
                    {({ isActive, isPending }) => (
                      <Text
                        whiteSpace="nowrap"
                        color={isActive ? "#FF5500" : "black"}
                        size={{ base: "xs", md: "sm", xl: "md" }}
                        variant="primary"
                      >
                        Про Читанку
                      </Text>
                    )}
                  </NavLink>
                </ListItem>

                <ListItem>
                  {isLoggedIn ? (
                    <NavLink to="profile">
                      {({ isActive, isPending }) => (
                        <HStack
                          spacing={{
                            base: "0.187rem",
                            sm: "0.312rem",
                            md: "0.437rem",
                            lg: "0.562rem",
                            xl: "0.625rem",
                          }}
                          alignItems="center"
                        >
                          <UserIcon
                            fill={isActive ? "#FF5500" : "black"}
                            width={{
                              base: "0.5rem",
                              sm: "0.75rem",
                              lg: "1rem",
                              xl: "1.125rem",
                            }}
                          />
                          <Text
                            whiteSpace="nowrap"
                            color={isActive ? "#FF5500" : "black"}
                            size={{ base: "xs", md: "sm", xl: "md" }}
                          >
                            Мій кабінет
                          </Text>
                        </HStack>
                      )}
                    </NavLink>
                  ) : (
                    <NavLink to="login">
                      {({ isActive, isPending }) => (
                        <HStack
                          spacing={{
                            base: "0.187rem",
                            sm: "0.312rem",
                            md: "0.437rem",
                            lg: "0.562rem",
                            xl: "0.625rem",
                          }}
                          alignItems="center"
                        >
                          <UserIcon
                            fill={isActive ? "#FF5500" : "black"}
                            width={{
                              base: "0.5rem",
                              sm: "0.75rem",
                              lg: "1rem",
                              xl: "1.125rem",
                            }}
                          />
                          <Text
                            whiteSpace="nowrap"
                            color={isActive ? "#FF5500" : "black"}
                            size={{ base: "xs", md: "sm", xl: "md" }}
                          >
                            Реєстрація / Вхід
                          </Text>
                        </HStack>
                      )}
                    </NavLink>
                  )}
                </ListItem>
              </HStack>
            </List>
            <Box
              cursor="pointer"
              onClick={() =>
                location.pathname !== "/catalogue" &&
                setDisplaySearchPanel(true)
              }
              width={{
                base: "0.5rem",
                sm: "0.75rem",
                lg: "0.9rem",
                xl: "1.06rem",
              }}
            >
              <SearchIcon fill="black" />
            </Box>
            <Popover
              openDelay={50}
              closeDelay={50}
              placement="bottom-end"
              trigger="hover"
            >
              {({ isOpen }) => (
                <>
                  <PopoverTrigger>
                    <Flex
                      borderRadius={{ base: "0.35rem", md: "0.5rem" }}
                      color={isOpen ? "black" : "white"}
                      backgroundColor={isOpen ? "white" : "black"}
                      border="0.125rem solid black"
                      transition="all 0.3s"
                      px={{
                        base: "0.75rem",
                        sm: "0.5rem",
                        md: "0.7rem",
                        xl: "0.875rem",
                      }}
                      py={{ base: "0.5rem", md: "0.75rem", xl: "1.125rem" }}
                      alignItems="center"
                      gap={{ base: "0.5rem", xl: "0.875rem" }}
                    >
                      <HStack
                        overflowY="clip"
                        maxH={{ base: "1rem", xl: "1.25rem" }}
                        spacing="0.4rem"
                      >
                        <AppleIcon
                          fill={isOpen ? "black" : "white"}
                          height="auto"
                          width={{ base: "1.25rem", xl: "1.5rem" }}
                        />
                        <GooglePlayIcon
                          height="auto"
                          width={{ base: "1.25rem", xl: "1.5rem" }}
                        />
                      </HStack>
                      <Text
                        cursor="default"
                        size={{ base: "xs", md: "sm", xl: "md" }}
                        color="inherit"
                        lineHeight="1rem"
                      >
                        Читайте в додатку
                      </Text>
                      <Box
                        transition="transform 0.3s"
                        transform={isOpen ? "rotate(180deg)" : ""}
                        boxSize={{
                          base: "0.75rem",
                          sm: "0.5rem",
                          md: "0.75rem",
                        }}
                      >
                        <FaChevronDown size="100%" color="inherit" />
                      </Box>
                    </Flex>
                  </PopoverTrigger>
                  <PopoverContent
                    border="0.125rem solid black"
                    width="fit-content"
                  >
                    <PopoverBody padding={{ base: "0.5rem", xl: "0.875rem" }}>
                      <Stack
                        alignItems="flex-end"
                        maxWidth="fit-content"
                        spacing={{
                          base: "0.25rem",
                          sm: "0.375rem",
                          md: "0.5rem",
                          lg: "0.625rem",
                          xl: "0.75rem",
                        }}
                      >
                        <Link
                          as="a"
                          isExternal
                          href="https://play.google.com/store/apps/details?id=com.beleven.chytanka&hl=uk&gl=US"
                        >
                          <Image
                            width={{
                              base: "5rem",
                              md: "7.5rem",
                              xl: "8.5rem",
                              "2xl": "10rem",
                            }}
                            src={googlePlayBadge}
                            alt="Google badge"
                          />
                        </Link>

                        <Link
                          as="a"
                          isExternal
                          href="https://apps.apple.com/ua/app/%D1%87%D0%B8%D1%82%D0%B0%D0%BD%D0%BA%D0%B0/id1543172038"
                        >
                          <Image
                            alt="App store badge"
                            width={{
                              base: "5rem",
                              md: "7.5rem",
                              xl: "8.5rem",
                              "2xl": "10rem",
                            }}
                            src={appleStorebadge}
                          />
                        </Link>
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </>
              )}
            </Popover>
          </HStack>
        </Flex>
      </Show>

      <Show below="sm" ssr={false}>
        <Flex justifyContent="space-between" alignItems="center">
          <NavLink to="/">
            <Image
              width="9.43rem"
              marginBottom="0.25rem"
              src={logo}
              alt="Logo"
            />
          </NavLink>
          <HStack spacing="1.375rem">
            <NavLink to="/profile">
              {({ isActive, isPending }) => (
                <UserIcon
                  fill={isActive ? "#FF5500" : "black"}
                  width="1.375rem"
                />
              )}
            </NavLink>
            <IconButton
              _active={{ background: "inherit" }}
              background="transparent"
              width="auto"
              height="auto"
              minWidth="0"
              aria-label="menu toggler"
              onClick={() => setDisplayBurgerMenu(true)}
              icon={<BurgerMenuIcon height="auto" width="1.5rem" />}
            />
          </HStack>

          {/* <HStack spacing="1.5rem">
            <Link
              as="a"
              isExternal
              href="https://apps.apple.com/ua/app/%D1%87%D0%B8%D1%82%D0%B0%D0%BD%D0%BA%D0%B0/id1543172038"
            >
              <AppStoreIcon height="auto" width="2rem" />
            </Link>

            <Link
              as="a"
              isExternal
              href="https://play.google.com/store/apps/details?id=com.beleven.chytanka&hl=uk&gl=US"
            >
              <GooglePlayIcon height="auto" width="2rem" />
            </Link>
          </HStack> */}
        </Flex>
      </Show>

      <HamburgerMenu
        isOpen={displayBurgerMenu}
        onClose={setDisplayBurgerMenu}
      />
      <Show above="sm">
        <SearchPanel
          isOpen={displaySearchPanel}
          onClose={setDisplaySearchPanel}
        />
      </Show>
    </Container>
  );
};
