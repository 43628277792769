import { Icon } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/react";

interface ArrowIconProps extends StyleProps {
  fill?: string
}

export const ArrowIcon: React.FC<ArrowIconProps> = (props) => {
  return (
    <Icon 
    width='100%'
    height='100%'
    {...props}>
      <path
        d="M12.707 1.29301C12.6139 1.20036 12.5035 1.12696 12.382 1.07701C12.2611 1.02618 12.1312 1 12 1C11.8688 1 11.739 1.02618 11.618 1.07701C11.4966 1.12696 11.3861 1.20036 11.293 1.29301L3.29302 9.29301C3.19751 9.38525 3.12133 9.4956 3.06892 9.6176C3.01651 9.73961 2.98892 9.87083 2.98777 10.0036C2.98662 10.1364 3.01192 10.2681 3.0622 10.391C3.11248 10.5139 3.18673 10.6255 3.28062 10.7194C3.37452 10.8133 3.48617 10.8875 3.60907 10.9378C3.73196 10.9881 3.86364 11.0134 3.99642 11.0123C4.1292 11.0111 4.26042 10.9835 4.38242 10.9311C4.50443 10.8787 4.61477 10.8025 4.70702 10.707L11 4.41401L11 22C11 22.2652 11.1054 22.5196 11.2929 22.7071C11.4804 22.8947 11.7348 23 12 23C12.2652 23 12.5196 22.8947 12.7071 22.7071C12.8947 22.5196 13 22.2652 13 22L13 4.41401L19.293 10.707C19.4816 10.8892 19.7342 10.99 19.9964 10.9877C20.2586 10.9854 20.5094 10.8802 20.6948 10.6948C20.8802 10.5094 20.9854 10.2586 20.9877 9.99641C20.99 9.73421 20.8892 9.48161 20.707 9.29301L12.707 1.29301Z"
        fill= {props?.fill || "white"}
      />
    </Icon>
  );
};
