import { FunctionComponent } from "react";

interface CloseMenuIconProps {
  width?: string | number;
  fill?: string;
}

const CloseMenuIcon: FunctionComponent<CloseMenuIconProps> = ({
  width,
  fill,
}) => {
  return (
    <svg
      width={width ?? "22"}
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.29496 0.914818C3.18038 1.41451 4.12061 1.81926 4.99856 2.3319C5.07817 2.37787 6.30267 3.09059 7.2137 3.61082C8.44099 4.30789 8.57053 4.35391 9.48624 4.87684C11.0907 5.7859 11.4211 6.05721 13.3684 7.21601C14.7165 8.01738 15.3906 8.41807 16.0472 8.76261C17.7964 9.68046 19.3986 10.8529 21.113 11.8312C21.23 11.8988 21.5604 12.0837 21.5822 12.338C21.5913 12.4411 21.5468 12.5074 21.2602 12.9173C20.9636 13.3445 20.939 13.3763 20.8716 13.4064C20.5298 13.5658 20.0134 13.2274 19.8801 13.1447C17.4162 11.561 14.734 10.3232 12.2752 8.73095C9.39325 6.86569 5.90507 5.00138 4.5608 4.32307C4.06816 4.07317 3.58829 3.79036 3.10313 3.52752C1.27927 2.55507 0.23412 2.07247 0.255076 1.54948C0.265452 1.33684 0.452007 1.02453 0.506818 0.929595C0.606174 0.768321 0.713004 0.594101 0.855502 0.509517C1.22938 0.305363 1.87098 0.675788 2.29496 0.914818Z"
        fill={fill ?? "black"}
      />
      <path
        d="M1.33255 11.3419C2.0072 10.8837 2.45037 10.6278 2.45037 10.6278C3.2994 10.1376 3.92442 9.78732 4.13901 9.66343C5.01594 9.16771 5.09321 9.08608 6.21764 8.41574C6.83351 8.0496 7.37227 7.74383 8.41943 7.15512C10.0007 6.26333 11.3416 5.52618 12.3676 4.96552C15.3035 3.36037 14.9579 3.60217 15.2917 3.38304C15.2917 3.38304 17.8705 1.71968 20.011 0.579059C20.1439 0.507611 20.5892 0.276957 20.869 0.406162C20.8852 0.412671 20.8944 0.417909 20.906 0.427112C21.1186 0.547564 21.2214 0.747245 21.2808 0.871587C21.4337 1.19037 21.509 1.3531 21.4987 1.47534C21.4675 1.89521 20.8935 2.24773 20.6859 2.3729C14.4427 6.14655 12.8925 6.93053 12.8925 6.93053C10.1931 8.29874 7.0722 10.1006 5.52282 11.0691C4.97447 11.4122 4.39628 11.7143 3.84564 12.0533C2.25665 13.0394 1.43177 13.7007 0.964664 13.4734C0.768086 13.3807 0.651677 13.136 0.542049 12.9138C0.421061 12.6612 0.327459 12.4667 0.380414 12.2353C0.437949 12.0117 0.611158 11.8377 1.33255 11.3419Z"
        fill={fill ?? "black"}
      />
    </svg>
  );
};

export default CloseMenuIcon;
