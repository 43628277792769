import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD0QLtk-NhI7u4a8RZOkfsHSuKZCJyMvKw",
  authDomain: "bookreader-prod-b68a7.firebaseapp.com",
  databaseURL: "http://bookreader-prod-b68a7.firebaseio.com",
  projectId: "bookreader-prod-b68a7",
  storageBucket: "bookreader-prod-b68a7.appspot.com",
  messagingSenderId: "1020416193781",
  appId: "1:1020416193781:web:a838c4c483de1e533081bc",
  measurementId: "G-8E7NNGP94J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);



