import { keyframes } from "@emotion/react";

const elementAnimationKeyframes = keyframes`
0% { transform: translateY(0) translateX(0); }
7% { transform: translateY(-0.25rem) translateX(-0.75%); }
14% { transform: translateY(-0.5rem) translateX(-1.5%); }
21% { transform: translateY(-0.75rem) translateX(-2.25%); }
28% { transform: translateY(-1rem) translateX(-3%); }
35% { transform: translateY(-0.75rem) translateX(-2.25%); }
42% { transform: translateY(-0.5rem) translateX(-1.5%); }
49% { transform: translateY(0) translateX(0); }
56% { transform: translateY(0.25rem) translateX(0.75%); }
63%  { transform: translateY(0.5rem) translateX(1.5%); }
70% { transform: translateY(0.75rem) translateX(2.25%); 
77% { transform: translateY(1rem) translateX(3%); }
84% { transform: translateY(0.75rem) translateX(2.25%); }
91% { transform: translateY(0.5rem) translateX(1.5%); }
100% { transform: translateY(0) translateX(0); }
`;
export const elementAnimation = `${elementAnimationKeyframes} infinite 10s linear`;

const backgroundAnimationKeyframes = keyframes`
0% { transform: translateY(0rem); }
2% { transform: translateY(0.12rem); }
4% { transform: translateY(0.24rem); }
6% { transform: translateY(0.36rem); }
8% { transform: translateY(0.48rem); }
10% { transform: translateY(0.6rem); }
12% { transform: translateY(0.48rem); }
14% { transform: translateY(0.36rem); }
16% { transform: translateY(0.24rem); }
18% { transform: translateY(0.12rem); }
20% { transform: translateY(0rem); }
22% { transform: translateY(0.12rem); }
24% { transform: translateY(0.24rem); }
26% { transform: translateY(0.36rem); }
28% { transform: translateY(0.48rem); }
30% { transform: translateY(0.6rem); }
32% { transform: translateY(0.48rem); }
34% { transform: translateY(0.36rem); }
36% { transform: translateY(0.24rem); }
38% { transform: translateY(0.12rem); }
40% { transform: translateY(0rem); }
42% { transform: translateY(0.12rem); }
44% { transform: translateY(0.24rem); }
46% { transform: translateY(0.36rem); }
48% { transform: translateY(0.48rem); }
50% { transform: translateY(0.6rem); }
52% { transform: translateY(0.48rem); }
54% { transform: translateY(0.36rem); }
56% { transform: translateY(0.24rem); }
58% { transform: translateY(0.12rem); }
60% { transform: translateY(0rem); }
62% { transform: translateY(0.12rem); }
64% { transform: translateY(0.24rem); }
66% { transform: translateY(0.36rem); }
68% { transform: translateY(0.48rem); }
70% { transform: translateY(0.6rem); }
72% { transform: translateY(0.48rem); }
74% { transform: translateY(0.36rem); }
76% { transform: translateY(0.24rem); }
78% { transform: translateY(0.12rem); }
80% { transform: translateY(0rem); }
82% { transform: translateY(0.12rem); }
84% { transform: translateY(0.24rem); }
86% { transform: translateY(0.36rem); }
88% { transform: translateY(0.48rem); }
90% { transform: translateY(0.6rem); }
92% { transform: translateY(0.48rem); }
94% { transform: translateY(0.36rem); }
96% { transform: translateY(0.24rem); }
98% { transform: translateY(0.12rem); }
100% { transform: translateY(0rem); }
`;

export const backgroundAnimation = (duration: number) =>
  `${backgroundAnimationKeyframes} infinite ${duration}s linear`;
