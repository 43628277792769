interface rgbType {
  r: number;
  g: number;
  b: number;
}

export const hexToRgb = (hex: string): rgbType => {
  hex = hex.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return { r, g, b };
};
