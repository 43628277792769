import { Center, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import loader from "../../../assets/loader.json";
import Lottie from "lottie-react";

interface VerificationDrawerProps {
    isOpen: boolean,
    onClose?: () => void,
}

const VerificationDrawer: FunctionComponent<VerificationDrawerProps> = ({ isOpen, onClose }) => {
  return (
    <Drawer isOpen={isOpen} placement="bottom" onClose={() => {}}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody py="2.5rem">
          <DrawerHeader padding="0">
            <Text
              marginBottom={{ base: "0.5rem", md: "1rem" }}
              lineHeight={{ base: "1.5rem", sm: "auto" }}
              fontSize={{
                base: "1.5rem",
                md: "1,75rem",
                xl: "2.25rem",
              }}
              variant="link"
              fontWeight={800}
              fontFamily="GaretHeavy"
              textAlign="center"
            >
              Лист для підтвердження пошти надіслано
            </Text>
          </DrawerHeader>
          <Flex flexDirection="column" alignItems="center">
            <Text
              textAlign="center"
              size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
            >
              Для реєстрації вам необхідно підтвердити свою електронну адресу.
              Ми надіслали вам лист-підтвердження, він буде дійсним впродовж
              двох хвилин.
            </Text>
            <Center
              flexDirection="column"
              width={{ base: "100%", md: "55.35% !important" }}
              flexGrow={0}
            >
              <Lottie
                marginWidth={0}
                style={{ width: "15%" }}
                animationData={loader}
              />
              <Text
                variant="link"
                size={{ base: "sm", sm: "xs", md: "sm", xl: "md" }}
                fontWeight={300}
              >
                Чекаємо на підтвердження...
              </Text>
            </Center>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default VerificationDrawer;
