import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
  linkWithCredential,
} from "firebase/auth";
import dotPattern from "../../assets/Dot Pattern.svg";
import { FunctionComponent } from "react";
import { auth } from "../../utilities/firebase";
import { GoogleSignInIcon } from "../../ui/icons/GoogleSignInIcon";
import { Formik, Form } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";
import { signInScheme } from "../../utilities/validationSchema";
import FormInput from "../../ui/components/FormInput/FormInput";
import { showErrorToast, showInfoToast } from "../../utilities/toasts";
import Illustration from "../../features/LoginPage/components/Illustration";
import { analyticsEvents } from "../../utilities/analyticsEvents";
import Seo from "../../ui/components/Seo/Seo";
interface LoginPageProps {}

const LoginPage: FunctionComponent<LoginPageProps> = () => {
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const user = await signInWithPopup(auth, provider);
      analyticsEvents.googleLogin();
      navigate(-1);
    } catch (err: any) {
      showErrorToast("Щось пішло не так, спробуйте ще раз.");
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      analyticsEvents.passwordLogin();
      navigate(-1);
    } catch (error: any) {
      let message = "";
      if (error.code === "auth/user-not-found") {
        message = "Аккаунт за даною поштою не знайдено.";
      } else if (error.code === "auth/wrong-password") {
        try {
          const providers = await fetchSignInMethodsForEmail(auth, email);
          if (providers.includes("password")) {
            message = "Хибний пароль, спробуйте ще раз.";
          } else if (providers.length == 1 && providers[0] == "apple.com") {
            message =
              "На даний момент авторизація в аккаунт з Apple ID неможлива. Спробуйти увійти за допомогою Google.";
          } else {
            showInfoToast("Увійдіть в існуючий аккаунт.");
            const provider = new GoogleAuthProvider();
            await signInWithPopup(auth, provider);
            const credential = EmailAuthProvider.credential(email, password);
            auth.currentUser &&
              (await linkWithCredential(auth.currentUser, credential));
            await signInWithEmailAndPassword(auth, email, password);
            analyticsEvents.passwordLogin();
            navigate(-1);
            return;
          }
        } catch (error) {
          showErrorToast("Щось пішло не так, спробуйте ще раз!");
        }
      }
      message !== ""
        ? showErrorToast(message)
        : showErrorToast("Щось пішло не так, спробуйте ще раз!");
    }
  };

  return (
    <>
      <Seo description={`Увійти в Читанку`} title={"Увійти"} />
      <Flex
        px={{ base: "0.5rem", sm: "0rem" }}
        paddingTop={{ base: "1.25rem", sm: "0rem" }}
        flexDirection={{ base: "column", lg: "row" }}
        rowGap="1rem"
        alignItems="center"
        gap={{ base: "1.5rem", lg: "7.7%" }}
      >
        <Illustration marginTop={{ base: "2.5rem", lg: "0rem" }} />

        <Box
          order={{ base: 1, lg: 2 }}
          paddingRight={{
            base: "0rem",
            sm: "1rem",
            md: "2rem",
            xl: "3rem",
            "2xl": "4.75rem",
          }}
          flexGrow={1}
        >
          <Text
            fontWeight={600}
            as="h1"
            fontSize={{
              base: "1.625rem",
              sm: "1.5rem",
              md: "1,75rem",
              xl: "2.25rem",
            }}
            line-height="2.75rem"
            marginBottom={{ base: "0.5rem", xl: "0.75rem" }}
          >
            Вхід
          </Text>
          <HStack
            marginBottom={{
              base: "1rem",
              sm: "1.5rem",
              md: "1.75rem",
              xl: "2rem",
              "2xl": "2.5rem",
            }}
          >
            <Text
              size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
              variant="secondary"
            >
              Не маєте аккаунту?
            </Text>
            <NavLink to="/sign-up" replace>
              <Text
                size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
                fontWeight={600}
                textDecoration="underline"
                variant="link"
                as='h2'
              >
                Зареєструватись
              </Text>
            </NavLink>
          </HStack>
          <HStack
            justifyContent="center"
            alignItems="center"
            minWidth={{ bsae: "auto", lg: "48%" }}
            transition="all 0.3s"
            _hover={{ backgroundColor: "black", color: "white" }}
            color="black"
            onClick={signInWithGoogle}
            marginBottom={{
              base: "1.5rem",
              sm: "0.625rem",
              md: "1.25rem",
              lg: "1.875rem",
              xl: "3.125rem",
            }}
            cursor="pointer"
            spacing={{ base: "0.75rem", md: "1rem", xl: "1.125rem" }}
            borderRadius="0.5rem"
            width={{ base: "100%", sm: "fit-content" }}
            backgroundColor="grey"
            py={{ base: "1rem", sm: "0.5rem", md: "0.75rem", xl: "1.125rem" }}
            px={{
              base: "0.5rem",
              sm: "2rem",
              md: "2.75rem",
              xl: "3.625rem",
            }}
          >
            <GoogleSignInIcon height="1.5rem" width="1.5rem" />
            <Text
              color="inherit"
              textAlign={{ base: "center", sm: "left" }}
              size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
              fontWeight={500}
            >
              Увійти з Google
            </Text>
          </HStack>
          <HStack
            width="100%"
            spacing={{
              base: "1rem",
              sm: "0.75rem",
              xl: "1.25rem",
              "2xl": "1.5rem",
            }}
          >
            <Image
              objectFit="cover"
              backgroundRepeat="repeat"
              width="100%"
              height="0.065rem"
              overflow="hidden"
              src={dotPattern}
              alt='Pattern'
            />
            <Text
              variant="secondary"
              size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
            >
              або
            </Text>
            <Image
              objectFit="cover"
              height="0.065rem"
              width="100%"
              overflow="hidden"
              src={dotPattern}
              alt='Pattern'
            />
          </HStack>
          <Formik
            validationSchema={signInScheme}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{ email: "", password: "" }}
            onSubmit={(values, actions) => {
              signIn(values.email, values.password);
            }}
          >
            {(props) => (
              <Form>
                <Stack
                  marginTop={{
                    base: "1.5rem",
                    sm: "0.75rem",
                    md: "1.25rem",
                    lg: "1.875rem",
                    xl: "3.125rem",
                  }}
                  spacing={{
                    base: "1.25rem",
                    sm: "1rem",
                    md: "1.25rem",
                    lg: "1.5rem",
                    xl: "2rem",
                  }}
                >
                  <FormInput
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Введіть email адресу"
                  />
                  <FormInput
                    name="password"
                    label="Пароль"
                    type="password"
                    placeholder="Введіть пароль"
                    isResetable={true}
                  />

                  <Button
                    margin="0"
                    minWidth={{ base: "100%", sm: "auto", lg: "48%" }}
                    type="submit"
                    height="fit-content"
                    transition="all 0.3s"
                    _active={{}}
                    _hover={{ backgroundColor: "black" }}
                    cursor="pointer"
                    borderRadius={{
                      base: "0.5rem",
                      sm: "0.35rem",
                      xl: "0.5rem",
                    }}
                    backgroundColor="orange"
                    width="fit-content"
                    px={{
                      base: "3rem",
                      sm: "6rem",
                      xl: "7.5rem",
                      "2xl": "8.125rem",
                    }}
                    py={{
                      base: "1.125rem",
                      sm: "0.75rem",
                      md: "1rem",
                      xl: "1.125rem",
                    }}
                  >
                    <Text
                      line-height="1.25rem"
                      fontWeight={600}
                      color="white"
                      size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                    >
                      Увійти
                    </Text>
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
          <ToastContainer />
        </Box>
      </Flex>
    </>
  );
};

export default LoginPage;
