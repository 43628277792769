import { Button, Flex, Image, Stack, Text, keyframes } from "@chakra-ui/react";
import {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import AuthorsApplyLayout from "../../features/AuthorsApplyPage/components/AuthorsApplyLayout/AuthorsApplyLayout";
import TabsSlider from "../../features/AuthorsApplyPage/components/TabsSlider/TabsSlider";
import StartSection from "../../features/AuthorsApplyPage/components/StartSection/StartSection";
import IntroductionSection from "../../features/AuthorsApplyPage/components/IntroductionSection/IntroductionSection";
import SectionsCarousel from "../../features/AuthorsApplyPage/components/SectionsCarousel/SectionsCarousel";
import RegistrationSection from "../../features/AuthorsApplyPage/components/RegistrationSection/RegistrationSection";
import InstructionsSection from "../../features/AuthorsApplyPage/components/InstructionsSection/InstructionsSection";
import PublishingSection from "../../features/AuthorsApplyPage/components/PublishingSection/PublishingSection";
import ShareSection from "../../features/AuthorsApplyPage/components/ShareSection/ShareSection";
import BenefitsSection from "../../features/AuthorsApplyPage/components/BenefitsSection/BenefitsSection";
import CommissionSection from "../../features/AuthorsApplyPage/components/CommissionSection/CommissionSection";
import QuestionsSection from "../../features/AuthorsApplyPage/components/QuestionsSection/QuestionsSection";
import AboutSection from "../../features/AuthorsApplyPage/components/AboutSection/AboutSection";

interface AuthorsLandingPageProps {}

const AuthorsLandingPage: FunctionComponent<AuthorsLandingPageProps> = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const nextStep = () => {
    setTabIndex((prevTabIndex) => prevTabIndex + 1);
  };

  return (
    <AuthorsApplyLayout
      hasLeftPadding={tabIndex == 0}
      hasRightPadding={tabIndex == 9}
    >
      <Stack height="100%" width="100%" flexGrow={1} maxHeight="100%">
        <Stack maxHeight="100%" flexGrow={1}>
          <SectionsCarousel tabIndex={tabIndex}>
            <StartSection
              nextStep={() => setTabIndex((prevTabIndex) => prevTabIndex + 1)}
            />

            <IntroductionSection nextStep={nextStep} />
            <RegistrationSection nextStep={nextStep} />
            <InstructionsSection nextStep={nextStep} />
            <PublishingSection nextStep={nextStep} />
            <ShareSection nextStep={nextStep} />
            <BenefitsSection nextStep={nextStep} />
            <CommissionSection nextStep={nextStep} />
            <QuestionsSection nextStep={nextStep} />
            <AboutSection />
          </SectionsCarousel>
        </Stack>
        <TabsSlider tabIndex={tabIndex} setTabIndex={setTabIndex} />
      </Stack>
    </AuthorsApplyLayout>
  );
};

export default AuthorsLandingPage;
