import { Container, Text, VStack, Center, Show } from "@chakra-ui/react";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import Lottie from "lottie-react";
import loader from "../../../assets/loader.json";
import { MobileFooter } from "./MobileFooter";
export const Layout: React.FC = () => {
  return (
    <VStack overflowX="clip" minWidth="100%" minHeight="100vh">
      <Suspense
        fallback={
          <Center height="100vh">
            <Lottie animationData={loader} />
          </Center>
        }
      >
        <Header />
        <Container
          overflowY="hidden"
          flexGrow={1}
          maxWidth="1920px"
          bg="white"
          paddingBottom={{ base: "3.75rem", md: "3rem", "2xl": "6.25rem" }}
          px={{ base: "0.75rem", md: "1.5rem", lg: "2rem", "2xl": "3.75rem" }}
        >
          <Outlet />
        </Container>
        <Show above="sm">
          <Footer />
        </Show>
        <Show below="sm">
          <MobileFooter />
        </Show>
      </Suspense>
    </VStack>
  );
};
