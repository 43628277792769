import { Icon } from "@chakra-ui/react";
import { StyleProps } from "@chakra-ui/react";

interface ExternalLinkIconProps extends StyleProps {
  fill?: string;
}

export const ExternalLinkIcon: React.FC<ExternalLinkIconProps> = (props) => {
  return (
    <Icon
      width="100%"
      height="100%"
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.19534 13.8047C2.07036 13.6796 2.00014 13.5101 2.00014 13.3333C2.00014 13.1566 2.07036 12.987 2.19534 12.862L11.724 3.33333H8C7.82319 3.33333 7.65362 3.2631 7.5286 3.13807C7.40357 3.01305 7.33334 2.84348 7.33334 2.66667C7.33334 2.48986 7.40357 2.32029 7.5286 2.19526C7.65362 2.07024 7.82319 2 8 2H13.3333C13.5101 2 13.6797 2.07024 13.8047 2.19526C13.9298 2.32029 14 2.48986 14 2.66667V8C14 8.17681 13.9298 8.34638 13.8047 8.4714C13.6797 8.59643 13.5101 8.66667 13.3333 8.66667C13.1565 8.66667 12.987 8.59643 12.8619 8.4714C12.7369 8.34638 12.6667 8.17681 12.6667 8V4.276L3.138 13.8047C3.01298 13.9296 2.84345 13.9999 2.66667 13.9999C2.48989 13.9999 2.32035 13.9296 2.19534 13.8047V13.8047Z"
        fill={ props.fill??"black" }
      />
    </Icon>
  );
};
