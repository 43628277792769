import { defineStyleConfig } from "@chakra-ui/react"
// definePartStyle
export const CardStyles = defineStyleConfig({
    baseStyle: {
        display: 'flex',
        borderRadius: '1.25rem',
        alignItems: 'center'
    },

    variants: {
        vertical: {
            flexDirection: 'column',
            gap: '6px'
        },
        horizontal: {
            flexDirection: 'row',
            gap: '24px',
            padding: '0.75rem'
        }
    },

    defaultProps: {
        variant: 'vertical',
    }
})