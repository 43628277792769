import { defineStyleConfig } from "@chakra-ui/react";

export const ButtonStyles = defineStyleConfig({
  baseStyle: {
    _active: {},
  },
  variants: {
    transparent: {
      background: "white",
      borderRadius: "3.75rem",
      textTransform: "uppercase",
      lineHeight: "normal",
      letterSpacing: "-0.8px",
      fontWeight: "600",
      fontFamily: "Work Sans, sans-serif",
      border: "0.065rem solid black",
      boxShadow: "3px 3px black",
      height: "fit-content",
    },
  },

  sizes: {
    base: {
      padding: "0.75rem 0.875rem",
      fontSize:'0.75rem',
      letterSpacing: "-0.6px"
    },
    "2xl": {
      padding: "0.875rem 2.25rem",
      fontSize: "1rem",
    },
  },
});
