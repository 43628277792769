import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Stack,
  Text,
  forwardRef,
  useBreakpointValue,
  useTheme,
} from "@chakra-ui/react";
import element1 from "../../../../assets/Authors-landing/tab-7-element-1.png";
import element2 from "../../../../assets/Authors-landing/tab-7-element-2.png";
import element3 from "../../../../assets/Authors-landing/tab-7-element-3.png";

import { LandingSlide } from "../../../types";
import { backgroundAnimation } from "../../utils/animations";
import { Carousel } from "../../../../ui/components/Carousel/Carousel/Carousel";
import { ReactElement } from "react";

const benefitsItems = [
  {
    image: element1,
    title: "Захист від копіювання",
    description:
      "Ми гарантуємо, що твоя книжка буде захищена від копіювання та неліцензійного розповсюдження.",
  },
  {
    image: element2,
    title: "Cтатистика продажів",
    description:
      "У тебе буде доступ до дашборду зі статистикою продажів та більше інформації по темі.",
  },
  {
    image: element3,
    title: "Підтримка від Читанки",
    description:
      "Матимеш можливість взяти потенційну участь в розробці платформи.",
  },
];

interface BenefitsSectionProps extends LandingSlide {}

const BenefitsSection = forwardRef<BenefitsSectionProps, "div">(
  ({ nextStep }, ref) => {
    const buttonIconWidth = useBreakpointValue({
      base: "1rem",
      lg: "1.5rem",
      "2xl": "2.5rem",
    });

    const theme = useTheme();

    return (
      <Flex
        ref={ref}
        width="100%"
        flexShrink={0}
        flexGrow={1}
        justifyContent="flex-end"
      >
        <Stack
          height="100%"
          flex="0 0 auto"
          maxHeight="100%"
          justifyContent="space-between"
          width="100%"
          gap="3rem"
          maxWidth={1812}
        >
          <Stack
            px={{ base: "1.25rem", sm: "1.5rem", md: "2.5rem", "2xl": "5rem" }}
            pr={{ base: "0", md: "auto" }}
            flexGrow={1}
            justifyContent="center"
          >
            <Flex
              justifyContent="flex-start"
              gap="2rem"
              width="100%"
              height="fit-content"
              maxWidth={1580}
            >
              <Carousel hasButtons={false} gap={{ base: "3rem", md: "2rem" }}>
                {benefitsItems.map((item, index, array) => {
                  return (
                    <Stack
                      alignItems="center"
                      gap={{ base: "1.25rem", md: "2.5rem" }}
                      pr={index == array.length - 1 ? "0.5rem" : "0"}
                      flexBasis="0"
                      flexGrow={1}
                    >
                      <Center width={220} sx={{ aspectRatio: "1" }}>
                        <Image
                          src={item.image}
                          width="100%"
                          height="auto"
                          objectFit="cover"
                          objectPosition="center"
                          animation={backgroundAnimation(29.95)}
                        />
                      </Center>

                      <Stack
                        mt="0 !important"
                        gap="0.75rem"
                        alignItems="center"
                      >
                        <Text
                          fontSize={{ base: "1.5rem", lg: "2rem" }}
                          fontWeight="600"
                          fontFamily="OddvalText"
                          lineHeight={{ base: "2rem", lg: "2.5rem" }}
                          textAlign="center"
                        >
                          {item.title}
                        </Text>
                        <Text
                          mt="0 !important"
                          textAlign="center"
                          whiteSpace="pre-wrap"
                          fontFamily="Work Sans, sans-serif"
                          letterSpacing={{ base: "-0.8px", "2xl": "-1px" }}
                          lineHeight={{ base: "1.5rem", "2xl": "1.75rem" }}
                          fontSize={{ base: "1rem", lg: "1.25rem" }}
                        >
                          {item.description}
                        </Text>
                      </Stack>
                    </Stack>
                  );
                })}
              </Carousel>
            </Flex>
          </Stack>

          <Flex
            position="relative"
            height="3.75rem"
            width={{ base: "90%", md: "70%" }}
            alignSelf="flex-end"
            backgroundColor="black"
            display="flex"
            alignItems="center"
            mt="0 !important"
            pr={{ base: "1.5rem", md: "2.5rem" }}
          >
            <Flex width="100%" justifyContent="flex-end" maxWidth={1110}>
              <Text
                fontSize={{ base: "1.25rem", md: "1.5rem", "2xl": "2rem" }}
                fontWeight="600"
                fontFamily="OddvalText"
                lineHeight={{ base: "1.5rem", md: "2rem", "2xl": "2.5rem" }}
                textAlign="center"
                color="white"
                textTransform="uppercase"
              >
                Додаткові переваги
              </Text>
            </Flex>

            <Box
              position="absolute"
              left="0"
              top="50%"
              transform="translateY(-50%) rotate(180deg)"
              width="0"
              height="0"
              borderStyle="solid"
              borderWidth="30px 30px 30px 0"
              borderColor={`transparent ${theme.colors.pale} transparent transparent`}
            />
          </Flex>
        </Stack>
      </Flex>
    );
  }
);

export default BenefitsSection;
