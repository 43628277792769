import { FunctionComponent, ReactElement } from "react";
import { LandingSlide } from "../../../types";
import {
  Box,
  Button,
  SimpleGrid,
  Stack,
  Text,
  forwardRef,
  useBreakpointValue,
} from "@chakra-ui/react";
import QuestionItem from "../QuestionItem/QuestionItem";
import QuestionMarkIcon from "../../../../ui/icons/QuestionMarkIcon";
import ScrollableList from "../ScrollableList/ScrollableList";

interface QuestionsSectionProps extends LandingSlide {}

const questionItems = [
  {
    question: "Скільки сторінок має містити книжка, щоб її видали?",
    answer:
      "Кількість сторінок, яку має містити книжка, може значно варіюватися в залежності від багатьох факторів, таких як жанр, розмір шрифту, міжстроковий інтервал, розмір сторінки та багато інших. Наприклад, роман може мати від кількох сотень до кількох тисяч сторінок. Навчальний посібник або енциклопедія може бути ще більшим.",
  },
  {
    question: "Скільки сторінок має містити книжка, щоб її видали?",
    answer:
      "Кількість сторінок, яку має містити книжка, може значно варіюватися в залежності від багатьох факторів, таких як жанр, розмір шрифту, міжстроковий інтервал, розмір сторінки та багато інших. Наприклад, роман може мати від кількох сотень до кількох тисяч сторінок. Навчальний посібник або енциклопедія може бути ще більшим.",
  },
  {
    question: "Скільки сторінок має містити книжка, щоб її видали?",
    answer:
      "Кількість сторінок, яку має містити книжка, може значно варіюватися в залежності від багатьох факторів, таких як жанр, розмір шрифту, міжстроковий інтервал, розмір сторінки та багато інших. Наприклад, роман може мати від кількох сотень до кількох тисяч сторінок. Навчальний посібник або енциклопедія може бути ще більшим.",
  },

  {
    question: "Скільки сторінок має містити книжка, щоб її видали?",
    answer:
      "Кількість сторінок, яку має містити книжка, може значно варіюватися в залежності від багатьох факторів, таких як жанр, розмір шрифту, міжстроковий інтервал, розмір сторінки та багато інших. Наприклад, роман може мати від кількох сотень до кількох тисяч сторінок. Навчальний посібник або енциклопедія може бути ще більшим.",
  },

  {
    question: "Скільки сторінок має містити книжка, щоб її видали?",
    answer:
      "Кількість сторінок, яку має містити книжка, може значно варіюватися в залежності від багатьох факторів, таких як жанр, розмір шрифту, міжстроковий інтервал, розмір сторінки та багато інших. Наприклад, роман може мати від кількох сотень до кількох тисяч сторінок. Навчальний посібник або енциклопедія може бути ще більшим.",
  },
  {
    question: "Скільки сторінок має містити книжка, щоб її видали?",
    answer:
      "Кількість сторінок, яку має містити книжка, може значно варіюватися в залежності від багатьох факторів, таких як жанр, розмір шрифту, міжстроковий інтервал, розмір сторінки та багато інших. Наприклад, роман може мати від кількох сотень до кількох тисяч сторінок. Навчальний посібник або енциклопедія може бути ще більшим.",
  },
];

const QuestionsSection = forwardRef<QuestionsSectionProps, "div">(
  ({ nextStep }, ref) => {
    const buttonIconHeight = useBreakpointValue({
      base: "1rem",
      lg: "1.5rem",
      "2xl": "2.5rem",
    });

    const displayCarousel = useBreakpointValue({ base: true, lg: false });

    const wrapper = (children: ReactElement[]) => {
      return displayCarousel ? (
        <ScrollableList>{children}</ScrollableList>
      ) : (
        <SimpleGrid rowGap="1.25rem" gap="2rem" columns={2}>
          {children}
        </SimpleGrid>
      );
    };

    return (
      <Stack
        width="100%"
        flexShrink={0}
        flexGrow={1}
        height="100%"
        maxHeight="100%"
        justifyContent={{ base: "flex-start", lg: "center" }}
        alignItems="center"
        position="relative"
        gap={{ base: "1.5rem", lg: "3rem", "2xl": "5rem" }}
        px={{ base: "1rem", lg: 0 }}
        ref={ref}
      >
        <Stack
          height="100%"
          maxHeight="100%"
          width="100%"
          maxWidth={{ base: "100%", lg: 1082, "2xl": 1368 }}
        >
          <Stack
            height="100%"
            maxHeight="100%"
            flexShrink={1}
            gap={{ base: "1.5rem", lg: "2.5rem" }}
          >
            <Text
              fontFamily="OddvalText"
              fontWeight="600"
              color="#000100"
              lineHeight={{ base: "2rem", lg: "3rem", "2xl": "4rem" }}
              fontSize={{ base: "1.75rem", lg: "2.5rem", "2xl": "3.75rem" }}
              fontStyle="normal"
              maxW={807}
            >
              Популярні запитання
            </Text>

            {wrapper(
              questionItems.map((questionItem) => {
                return (
                  <QuestionItem {...questionItem} key={questionItem.question} />
                );
              })
            )}
          </Stack>
        </Stack>
        <Button
          py={{ base: "1.5rem", "2xl": "2.375rem" }}
          mt="0 !important"
          boxShadow="0.35rem 0.35rem black"
          variant="transparent"
          letterSpacing="-1.2px"
          fontSize={{ lg: "1.25rem", "2xl": "1.5rem" }}
          width={{ base: "100%", lg: "20rem", "2xl": "24rem" }}
          iconSpacing="0"
          gap={{ base: "0.75rem", lg: "1rem", "2xl": "1.5rem" }}
          leftIcon={<QuestionMarkIcon height={buttonIconHeight} />}
          onClick={nextStep}
        >
          Спитай нас
        </Button>
      </Stack>
    );
  }
);

export default QuestionsSection;
