export const wordExchanger = (length: number): string => {
  const lastCharacter = Number(length.toString().slice(-1));
  if (lastCharacter === 1) {
    return "книга";
  } else if (lastCharacter > 0 && lastCharacter < 5) {
    return "книги";
  } else {
    return "книг";
  }
};
