import { FunctionComponent } from "react";

interface InstagramIconProps {
  width?: string | number;
}

const InstagramIcon: FunctionComponent<InstagramIconProps> = ({ width }) => {
  return (
    <svg
      width={width ?? "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 30C25.5228 30 30 25.5228 30 20C30 14.4771 25.5228 10 20 10C14.4771 10 10 14.4771 10 20C10 25.5228 14.4771 30 20 30ZM20 26.6667C23.6818 26.6667 26.6667 23.6818 26.6667 20C26.6667 16.3181 23.6818 13.3333 20 13.3333C16.3181 13.3333 13.3333 16.3181 13.3333 20C13.3333 23.6818 16.3181 26.6667 20 26.6667Z"
        fill="#0F0F0F"
      />
      <path
        d="M29.9999 8.3335C29.0794 8.3335 28.3333 9.0797 28.3333 10.0002C28.3333 10.9206 29.0794 11.6668 29.9999 11.6668C30.9204 11.6668 31.6666 10.9206 31.6666 10.0002C31.6666 9.0797 30.9204 8.3335 29.9999 8.3335Z"
        fill="#0F0F0F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.75668 7.1266C1.66675 9.26572 1.66675 12.066 1.66675 17.6665V22.3332C1.66675 27.9337 1.66675 30.734 2.75668 32.873C3.71541 34.7547 5.24521 36.2845 7.12685 37.2432C9.26596 38.3332 12.0662 38.3332 17.6667 38.3332H22.3334C27.9339 38.3332 30.7342 38.3332 32.8732 37.2432C34.7549 36.2845 36.2847 34.7547 37.2434 32.873C38.3334 30.734 38.3334 27.9337 38.3334 22.3332V17.6665C38.3334 12.066 38.3334 9.26572 37.2434 7.1266C36.2847 5.24497 34.7549 3.71517 32.8732 2.75644C30.7342 1.6665 27.9339 1.6665 22.3334 1.6665H17.6667C12.0662 1.6665 9.26596 1.6665 7.12685 2.75644C5.24521 3.71517 3.71541 5.24497 2.75668 7.1266ZM22.3334 4.99984H17.6667C14.8115 4.99984 12.8705 5.00244 11.3702 5.125C9.90881 5.2444 9.16148 5.46082 8.64015 5.72645C7.38573 6.36562 6.36586 7.38549 5.7267 8.6399C5.46106 9.16124 5.24465 9.90857 5.12525 11.37C5.00268 12.8703 5.00008 14.8112 5.00008 17.6665V22.3332C5.00008 25.1885 5.00268 27.1293 5.12525 28.6297C5.24465 30.0912 5.46106 30.8385 5.7267 31.3598C6.36586 32.6142 7.38573 33.634 8.64015 34.2732C9.16148 34.5388 9.90881 34.7553 11.3702 34.8747C12.8705 34.9972 14.8115 34.9998 17.6667 34.9998H22.3334C25.1887 34.9998 27.1296 34.9972 28.6299 34.8747C30.0914 34.7553 30.8387 34.5388 31.3601 34.2732C32.6144 33.634 33.6342 32.6142 34.2734 31.3598C34.5391 30.8385 34.7556 30.0912 34.8749 28.6297C34.9974 27.1293 35.0001 25.1885 35.0001 22.3332V17.6665C35.0001 14.8112 34.9974 12.8703 34.8749 11.37C34.7556 9.90857 34.5391 9.16124 34.2734 8.6399C33.6342 7.38549 32.6144 6.36562 31.3601 5.72645C30.8387 5.46082 30.0914 5.2444 28.6299 5.125C27.1296 5.00244 25.1887 4.99984 22.3334 4.99984Z"
        fill="#0F0F0F"
      />
    </svg>
  );
};

export default InstagramIcon;
