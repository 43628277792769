import { Box, Stack } from "@chakra-ui/react";
import { FunctionComponent, ReactNode, UIEvent, useEffect, useRef, useState } from "react";

interface ScrollableListProps {
  children: ReactNode;
}

const ScrollableList: FunctionComponent<ScrollableListProps> = ({
  children,
}) => {
  const [dropShadow, setDropShadow] = useState(true);

  const containterRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (containterRef.current) {
      setDropShadow(
        containterRef.current.scrollTop + containterRef.current.offsetHeight <
          containterRef.current.scrollHeight
      );
    }
  };

  useEffect(() => {
    handleScroll()
  },[])

  return (
    <Stack
      maxH="100%"
      flexShrink={1}
      flexBasis={0}
      position="relative"
      flexGrow={1}
    >
      <Stack
        flexBasis={0}
        flexGrow={1}
        ref={containterRef}
        onScroll={handleScroll}
        gap="0.5rem"
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        overflow="scroll"
      >
        {children}
      </Stack>
      {dropShadow && (
        <Box
          position="absolute"
          height="2rem"
          bgGradient="linear(to-b, transparent, rgba(0, 0, 0, 0.2))"
          left="0"
          bottom="0"
          right="0"
        />
      )}
    </Stack>
  );
};

export default ScrollableList;
