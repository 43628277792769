import { FunctionComponent } from "react";

interface ChevronDownIconProps {
  width?: number | string;
}

const ChevronDownIcon: FunctionComponent<ChevronDownIconProps> = ({
  width,
}) => {
  return (
    <svg
      width={width ?? "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.72287 9.82552C4.96829 7.99933 3.82753 6.47553 3.06043 5.30724C2.60821 4.6231 1.70155 3.19161 0.835638 3.34489C0.502766 3.40871 0.214527 3.6832 0.080198 3.99324C-0.318547 4.93811 0.788084 6.23225 2.98842 8.66461C7.05119 13.1316 7.39496 13.2722 7.83125 13.2904C8.43195 13.3251 8.7119 13.1329 13.7212 8.29146C15.1835 6.88105 16.2269 5.84653 15.9575 4.85437C15.8303 4.41202 15.4459 3.96381 14.9927 3.88664C14.0672 3.7218 13.1972 5.13747 12.1648 6.4881C11.4815 7.38711 10.4867 8.53537 9.11274 9.80863C8.74368 10.3147 7.94844 11.2953 7.71992 11.1689C7.4914 11.0425 6.96 10.2206 6.72287 9.82552Z"
        fill="#000100"
      />
    </svg>
  );
};

export default ChevronDownIcon;
