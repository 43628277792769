import { Icon } from "@chakra-ui/icon";
import { StyleProps } from "@chakra-ui/react";

interface LockIconProps extends StyleProps {
  fill?: string;
}

const LockIcon: React.FC<LockIconProps> = (props) => {
  return (
    <Icon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1C10.6744 1.00159 9.40356 1.52888 8.46622 2.46622C7.52888 3.40356 7.00159 4.67441 7 6V8.765C5.70615 9.80091 4.76599 11.2131 4.30946 12.8065C3.85293 14.3998 3.90256 16.0956 4.45149 17.6595C5.00043 19.2234 6.02156 20.5783 7.37378 21.5367C8.726 22.4952 10.3425 23.01 12 23.01C13.6575 23.01 15.274 22.4952 16.6262 21.5367C17.9784 20.5783 18.9996 19.2234 19.5485 17.6595C20.0974 16.0956 20.1471 14.3998 19.6905 12.8065C19.234 11.2131 18.2939 9.80091 17 8.765V6C16.9984 4.67441 16.4711 3.40356 15.5338 2.46622C14.5964 1.52888 13.3256 1.00159 12 1ZM9 6C9 5.20435 9.31607 4.44129 9.87868 3.87868C10.4413 3.31607 11.2044 3 12 3C12.7956 3 13.5587 3.31607 14.1213 3.87868C14.6839 4.44129 15 5.20435 15 6V7.589C13.0771 6.80371 10.9229 6.80371 9 7.589V6ZM18 15C18 16.1867 17.6481 17.3467 16.9888 18.3334C16.3295 19.3201 15.3925 20.0892 14.2961 20.5433C13.1997 20.9974 11.9933 21.1162 10.8295 20.8847C9.66557 20.6532 8.59647 20.0818 7.75736 19.2426C6.91824 18.4035 6.3468 17.3344 6.11529 16.1705C5.88378 15.0067 6.0026 13.8003 6.45672 12.7039C6.91085 11.6075 7.67988 10.6705 8.66658 10.0112C9.65327 9.35189 10.8133 9 12 9C13.5908 9.00159 15.116 9.63424 16.2409 10.7591C17.3658 11.884 17.9984 13.4092 18 15ZM14 14C13.999 14.3497 13.9061 14.6929 13.7306 14.9953C13.5551 15.2978 13.3031 15.5487 13 15.723V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18V15.723C10.6956 15.5473 10.4429 15.2944 10.2674 14.99C10.0918 14.6855 9.99962 14.3401 10 13.9887C10.0004 13.6372 10.0934 13.2921 10.2697 12.988C10.4459 12.6839 10.6992 12.4317 11.0039 12.2566C11.3087 12.0816 11.6542 11.99 12.0057 11.991C12.3571 11.992 12.7021 12.0856 13.0059 12.2623C13.3097 12.4391 13.5615 12.6928 13.736 12.9978C13.9105 13.3029 14.0016 13.6485 14 14Z"
        fill={props.fill??'black'}
      />
    </Icon>
  );
};

export default LockIcon;
