import { FunctionComponent, useState } from "react";
import { useBooks } from "../../features/HomePage/api/client";
import { Paginate } from "../../ui/components/Pagination/Pagination";
import { Box, Center, Stack, Text } from "@chakra-ui/layout";
import Breadcrumb from "../../features/CategoriesPage/components/Breadcrumb/Breadcrumb";
import ErrorMessage from "../../ui/components/ErrorMessage/ErrorMessage";
import BookList from "../../features/SelectedCategoryPage/components/BookList/BookList";
import Loader from "../../ui/components/Loader/Loader";
import { wordExchanger } from "../../utilities/wordExchanger";
import { useSearchParams } from "react-router-dom";
import Seo from "../../ui/components/Seo/Seo";

interface BooksPageProps {
  type: "bestsellerBooks" | "newcomingBooks";
  title: string;
}

const BooksPage: FunctionComponent<BooksPageProps> = ({ type, title }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(searchParams.get("page") ?? "1")
  );

  const { data: bookList, isLoading, error } = useBooks(type, currentPage, 15);

  const pageCount = Math.ceil(
    (bookList?.paging?.totalElements ?? 1) /
      parseInt(bookList?.paging?.limit ?? "1")
  );

  const handleChange = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
      setSearchParams({ page: (currentPage - 1).toString() });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const nextPage = () => {
    if (currentPage !== pageCount) {
      setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
      setSearchParams({ page: (currentPage + 1).toString() });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  if (error) {
    return <ErrorMessage />;
  }
  if (bookList?.books.length === 0) {
    return (
      <Center paddingTop="3rem" height={{ base: "50vh", sm: "fit-content" }}>
        <Text
          opacity={0.5}
          size={{ base: "md", sm: "xs", md: "md", xl: "lg" }}
          fontWeight={500}
        >
          На даний момент книжок немає
        </Text>
      </Center>
    );
  }

  return (
    <>
      <Seo
        description={
          type == "bestsellerBooks"
            ? "Бетселери в Читанці"
            : "Новинки в Читанці"
        }
        title={
          type == "bestsellerBooks"
            ? "Бетселери в Читанці"
            : "Новинки в Читанці"
        }
      />
      <Box
        paddingTop={{
          base: "0rem",
          sm: "1.25rem",
          md: "1.75rem",
          xl: "2.5rem",
        }}
        px={{
          base: "0.5rem",
          md: "1rem",
          xl: "2rem",
          "2xl": "4.75rem",
        }}
      >
        <Breadcrumb
          breadcrumbItems={[
            { title: "Головна", path: "/" },
            { title: title ?? "" },
          ]}
        />
        <Box
          marginBottom={{
            base: "2rem",
            sm: "1.25rem",
            md: "2rem",
            "2xl": "3rem",
          }}
          marginTop={{
            base: "1rem",
            sm: "0.75rem",
            md: "1.75rem",
            xl: "2.5rem",
          }}
        >
          <Stack
            justifyContent="space-between"
            flexDirection={{ base: "column", sm: "row" }}
            alignItems={{ base: "flex-start", sm: "flex-end" }}
            gap={{ base: "0.5rem", sm: "1rem", xl: "2rem" }}
            marginBottom={{ base: "1rem", md: "1.5rem", xl: "2rem" }}
          >
            <Text
              fontWeight={{ base: 600, sm: 400 }}
              as="h1"
              lineHeight="1"
              fontSize={{
                base: "1.75rem",
                sm: "1.5rem",
                md: "1,75rem",
                xl: "2.25rem",
              }}
              variant="primary"
            >
              {title}
            </Text>
            <Text
              marginTop="0rem !important"
              fontWeight={400}
              lineHeight="1rem"
              variant="secondary"
              size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
            >
              {bookList &&
                `${bookList?.paging.totalElements} ${wordExchanger(
                  bookList?.paging.totalElements
                )}`}
            </Text>
          </Stack>
        </Box>
        {isLoading ? (
          <Loader width="100%" />
        ) : (
          <BookList books={bookList?.books ?? []} />
        )}
        <Paginate
          pageCount={pageCount ?? 1}
          previousPage={previousPage}
          nextPage={nextPage}
          currentPage={currentPage}
          onChange={handleChange}
        />
      </Box>
    </>
  );
};

export default BooksPage;
