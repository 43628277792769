import { Icon, StyleProps } from "@chakra-ui/react";

interface CatalogueIconProps extends StyleProps {
    fill?:string
}

export const CatalogueIcon: React.FC<CatalogueIconProps> = (props) => {
  return (
    <Icon
      width="18"
      height="18"
      {...props}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.49999 8.16614H7.33332C7.55434 8.16614 7.7663 8.07834 7.92258 7.92206C8.07886 7.76578 8.16666 7.55382 8.16666 7.3328V1.49947C8.16666 1.27846 8.07886 1.0665 7.92258 0.910215C7.7663 0.753935 7.55434 0.666138 7.33332 0.666138H1.49999C1.27898 0.666138 1.06701 0.753935 0.910734 0.910215C0.754454 1.0665 0.666656 1.27846 0.666656 1.49947V7.3328C0.666656 7.55382 0.754454 7.76578 0.910734 7.92206C1.06701 8.07834 1.27898 8.16614 1.49999 8.16614ZM2.33332 2.3328H6.49999V6.49947H2.33332V2.3328ZM17.3333 1.49947C17.3333 1.27846 17.2455 1.0665 17.0892 0.910215C16.933 0.753935 16.721 0.666138 16.5 0.666138H10.6667C10.4456 0.666138 10.2337 0.753935 10.0774 0.910215C9.92112 1.0665 9.83332 1.27846 9.83332 1.49947V7.3328C9.83332 7.55382 9.92112 7.76578 10.0774 7.92206C10.2337 8.07834 10.4456 8.16614 10.6667 8.16614H16.5C16.721 8.16614 16.933 8.07834 17.0892 7.92206C17.2455 7.76578 17.3333 7.55382 17.3333 7.3328V1.49947ZM15.6667 6.49947H11.5V2.3328H15.6667V6.49947ZM0.666656 16.4995C0.666656 16.7205 0.754454 16.9324 0.910734 17.0887C1.06701 17.245 1.27898 17.3328 1.49999 17.3328H7.33332C7.55434 17.3328 7.7663 17.245 7.92258 17.0887C8.07886 16.9324 8.16666 16.7205 8.16666 16.4995V10.6661C8.16666 10.4451 8.07886 10.2332 7.92258 10.0769C7.7663 9.9206 7.55434 9.8328 7.33332 9.8328H1.49999C1.27898 9.8328 1.06701 9.9206 0.910734 10.0769C0.754454 10.2332 0.666656 10.4451 0.666656 10.6661V16.4995ZM2.33332 11.4995H6.49999V15.6661H2.33332V11.4995ZM17.3333 16.4995V10.6661C17.3333 10.4451 17.2455 10.2332 17.0892 10.0769C16.933 9.9206 16.721 9.8328 16.5 9.8328H10.6667C10.4456 9.8328 10.2337 9.9206 10.0774 10.0769C9.92112 10.2332 9.83332 10.4451 9.83332 10.6661V16.4995C9.83332 16.7205 9.92112 16.9324 10.0774 17.0887C10.2337 17.245 10.4456 17.3328 10.6667 17.3328H16.5C16.721 17.3328 16.933 17.245 17.0892 17.0887C17.2455 16.9324 17.3333 16.7205 17.3333 16.4995ZM15.6667 15.6661H11.5V11.4995H15.6667V15.6661Z"
      />
    </Icon>
  );
};
