import { Icon } from "@chakra-ui/icon";
import { StyleProps } from "@chakra-ui/react";
interface AppleIconProps extends StyleProps {
  fill?: string;
}

const AppleIcon: React.FC<AppleIconProps> = (props) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0007 11.8701C17.0104 11.1226 17.2089 10.3898 17.5779 9.73971C17.9468 9.08963 18.4742 8.54344 19.111 8.15196C18.7065 7.57423 18.1728 7.09878 17.5524 6.76338C16.932 6.42797 16.2419 6.24185 15.537 6.21979C14.0332 6.06196 12.5754 7.1196 11.8092 7.1196C11.0281 7.1196 9.84827 6.23547 8.57783 6.2616C7.75606 6.28815 6.95519 6.52712 6.25324 6.95521C5.5513 7.3833 4.97221 7.98593 4.5724 8.70436C2.84054 11.7028 4.13235 16.1095 5.79133 18.5333C6.62136 19.7201 7.59142 21.0458 8.86072 20.9988C10.1028 20.9473 10.5667 20.2067 12.066 20.2067C13.5514 20.2067 13.9866 20.9988 15.2817 20.9689C16.6145 20.9473 17.4542 19.7768 18.2551 18.5787C18.8515 17.7331 19.3104 16.7984 19.6149 15.8094C18.8405 15.4819 18.1797 14.9337 17.7149 14.2332C17.25 13.5327 17.0016 12.7108 17.0007 11.8701Z"
        fill={props.fill??"white"}
      />
      <path
        d="M14.5546 4.62579C15.2813 3.7534 15.6394 2.6321 15.5527 1.5C14.4424 1.61661 13.4169 2.14724 12.6803 2.98615C12.3202 3.39598 12.0444 3.87277 11.8687 4.38926C11.693 4.90574 11.6207 5.4518 11.6562 5.99622C12.2115 6.00194 12.7609 5.88157 13.2629 5.64419C13.765 5.40681 14.2067 5.0586 14.5546 4.62579Z"
        fill={props.fill??"white"}
      />
    </Icon>
  );
};
export default AppleIcon;
