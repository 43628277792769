import {
  Box,
  Flex,
  IconButton,
  Stack,
  StackDivider,
  Text,
  keyframes,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useMemo,
} from "react";
import ContentIcon from "../../../../ui/icons/ContentIcon";
import CloseMenuIcon from "../../../../ui/icons/CloseMenuIcon";

interface TabsSliderProps {
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
}

const navItems = [
  {
    label: "1. Вступ",
    offset: "0",
  },
  {
    offset: "15%",
  },
  {
    label: "2. Що робити?",
    offset: "30%",
  },
  {
    offset: "40%",
  },
  {
    offset: "50%",
  },
  { offset: "60%" },
  {
    label: "3. Переваги",
    offset: "70%",
  },
  {
    label: "4. Про комісію",
    offset: "80%",
  },
  {
    label: "5. Запитання",
    offset: "90%",
  },
  {
    label: "6. Про Читанку",
    offset: "100%",
  },
];

const TabsSlider: FunctionComponent<TabsSliderProps> = ({
  tabIndex,
  setTabIndex,
}) => {
  const {
    isOpen: isNavigationOpen,
    onClose: onNavigationClose,
    onOpen: onNavigationOpen,
  } = useDisclosure();

  const currentMajorTabIndex = useMemo(() => {
    return (
      navItems.length -
      1 -
      [...navItems]
        .reverse()
        .findIndex(
          (item, idx) => item.label && idx >= navItems.length - 1 - tabIndex
        )
    );
  }, [navItems, tabIndex]);

  const fade = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

  useEffect(() => {
    onNavigationClose();
  }, [tabIndex]);

  const showProgress = useBreakpointValue({ base: false, lg: true });

  return (
    <Flex
      mt="0 !important"
      justifyContent="center"
      pb={{ base: "1.25rem", lg: "3.75rem" }}
      pr="2rem"
      pl={{ base: "1.25rem", lg: "0" }}
      width="100%"
    >
      <Flex
        maxWidth={{ base: "100%", lg: 980, "2xl": 1368 }}
        width="100%"
        gap="1.25rem"
        position="relative"
      >
        <IconButton
          aria-label="content"
          icon={
            isNavigationOpen ? <CloseMenuIcon fill="white" /> : <ContentIcon />
          }
          width="2.5rem"
          sx={{ aspectRatio: "1" }}
          background="black"
          borderRadius="50%"
          _hover={{}}
          zIndex={3}
          onClick={() =>
            isNavigationOpen ? onNavigationClose() : onNavigationOpen()
          }
        />
        {showProgress && (
          <Box
            zIndex={3}
            position="relative"
            flexGrow={1}
            width="100%"
            paddingTop="0.5rem"
          >
            <Flex
              flexGrow={1}
              height="0.125rem"
              borderRadius="3rem"
              background={`linear-gradient(to right, black 0%, black ${navItems[tabIndex].offset}, #E0C9A9 ${navItems[tabIndex].offset}, #E0C9A9 100%)`}
            />
            {navItems.map((item, index, array) => {
              return (
                <Stack
                  position="absolute"
                  left={item.offset}
                  onClick={() => setTabIndex(index)}
                  key={item.label}
                  top="0.275rem"
                  cursor="pointer"
                >
                  {item.label && (
                    <Box
                      borderRadius="50%"
                      background={index <= tabIndex ? "black" : "#E0C9A9"}
                      width="0.5rem"
                      sx={{ aspectRatio: "1" }}
                    />
                  )}
                  {index == tabIndex && (
                    <Text
                      position="absolute"
                      right={index == array.length - 1 ? "0" : "auto"}
                      left={index < array.length - 1 ? "0" : "auto"}
                      top="0.565rem"
                      mt="0 !important"
                      whiteSpace="nowrap"
                      letterSpacing="-0.7px"
                      size="sm"
                      lineHeight="normal"
                    >
                      {item.label}
                    </Text>
                  )}
                </Stack>
              );
            })}
          </Box>
        )}
        {isNavigationOpen && (
          <Stack
            zIndex={2}
            borderRadius={{ base: "2.5rem", lg: "1.25rem" }}
            backgroundColor="rgba(255, 255, 255, 0.7)"
            backdropFilter="blur(0.65rem)"
            left={{ base:"-0.75rem", lg:"-1.25rem"}}
            right={{ base:'0', lg:"-2.5rem"}}
            bottom={{ base:'-0.75rem', lg:"-1.25rem"}}
            border="0.065rem solid black"
            paddingTop="2rem"
            paddingRight="2rem"
            paddingBottom={{ base:'2.75rem', lg:"5rem"}}
            paddingLeft={{ base: "4rem", lg: "5rem" }}
            position="absolute"
            gap="1.25rem"
            animation={`${fade} 0.3s ease-in-out`}
            divider={<StackDivider margin="0 !important" borderColor="black" />}
          >
            {navItems
              .map((item, index) => ({ ...item, index }))
              .filter((item) => item.label)
              .map((item) => {
                return (
                  <Text
                    cursor="pointer"
                    _hover={{
                      color: "main",
                    }}
                    onClick={() => setTabIndex(item.index)}
                    color={
                      currentMajorTabIndex == item.index ? "main" : "black"
                    }
                    transition="all 0.3s"
                    fontFamily="OddvalText"
                    lineHeight="normal"
                    textTransform="uppercase"
                    fontWeight="600"
                    letterSpacing="-1.2px"
                    fontSize={{ lg: "1.25rem", "2xl": "1.5rem" }}
                  >
                    Розділ {item.label}
                  </Text>
                );
              })}
          </Stack>
        )}
      </Flex>
    </Flex>
  );
};

export default TabsSlider;
