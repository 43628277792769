import ReactGA from "react-ga4";


export const signInWithGoogle = () => ReactGA.event('login', {
    method: 'Google'
});

export const signInWithEmailAndPassword = () => ReactGA.event('login', {
    method: 'Password'
})

export const signUpWithGoogle = () => ReactGA.event('sign_up', {
    method: 'Google'
});

export const signUpWithEmailAndPassword = () => ReactGA.event('sign_up', {
    method: 'Password'
})

export const purchaseBook = (invoiceId: string, bookId: string, name: string, publishingHouse: string, author: string, price: number) => ReactGA.event('purchase', {
    transaction_id: invoiceId,
    currency: 'UAH',
    value: price,
    items: [{
        item_id: bookId,
        price: price,
        item_name: name,
        item_brand: author,
        affiliation: publishingHouse 
    }]
})

export const obtainFreeBook = (bookId: string) => ReactGA.event({
    action: 'Obtain free book',
    category: 'Purchases',
    label: bookId
})

export const selectBook = (bookId: string) => ReactGA.event('select_content', {
    content_type: 'Book',
    content_id: bookId
})

export const selectPublisher = (publisher: string) => ReactGA.event('select_content', {
    content_type: 'Publisher',
    content_id: publisher
})


export const search = (searchParam: string) => ReactGA.event('search', {
    search_term: searchParam
})

export const analyticsEvents = {
    googleLogin: signInWithGoogle,
    passwordLogin: signInWithEmailAndPassword,
    googleSignUp: signUpWithGoogle,
    passwordSignUp: signUpWithEmailAndPassword,
    purchaseBook: purchaseBook,
    obtainFreeBook: obtainFreeBook,
    selectBook: selectBook,
    selectPublisher: selectPublisher,
    search: search
}
