import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import dotPattern from "../../assets/Dot Pattern.svg";
import { FunctionComponent, useState } from "react";
import { auth } from "../../utilities/firebase";
import { GoogleSignInIcon } from "../../ui/icons/GoogleSignInIcon";
import { Formik, Form } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  NavLink,
  useLocation,
  useNavigate,
  useBeforeUnload,
} from "react-router-dom";
import { registrationScheme } from "../../utilities/validationSchema";
import { useUpdateUserData } from "../../features/ProfilePage/api/client";
import FormInput from "../../ui/components/FormInput/FormInput";
import Illustration from "../../features/LoginPage/components/Illustration";
import { showErrorToast, showInfoToast } from "../../utilities/toasts";
import useActualUser from "../../utilities/useActualUser";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDeleteUser } from "../../features/SignUpPage/api/client";
import VerificationDrawer from "../../features/SignUpPage/components/VerificationDrawer";
import Seo from "../../ui/components/Seo/Seo";
import { analyticsEvents } from "../../utilities/analyticsEvents";
interface LoginPageProps {}

const SignUpPage: FunctionComponent<LoginPageProps> = () => {
  const {
    data: response,
    mutate: updateUser,
    isLoading: userUpdating,
    error: updateError,
  } = useUpdateUserData();
  const {
    mutate: deleteUser,
    data: deleteStatus,
    error: deleteError,
  } = useDeleteUser();

  const { isOpen: isDrawerOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const { user } = useActualUser();

  const [initTimestamp, setInitTimestamp] = useState<number>(0);

  const location = useLocation();

  useBeforeUnload((e) => {
    e.preventDefault();
    if (user && !user?.emailVerified && initTimestamp) {
      signOut(auth);
      deleteUser({ userId: user.uid });
    }
  });

  const {
    data: newUser,
    isLoading,
    error,
  } = useQuery(
    ["userStatus"],
    async () => {
      if (!initTimestamp) {
        // first reload
        setInitTimestamp(Date.now());
        onOpen();
      }
      await auth.currentUser?.reload();
      return {
        ...auth.currentUser,
        currentTimestamp: Date.now(),
      };
    },
    {
      refetchInterval: 2000,
      onSuccess: async (data) => {
        if (data?.emailVerified == true) {
          analyticsEvents.passwordSignUp();
          location.pathname == "/sign-up" || location.pathname == "/login"
            ? navigate(-1)
            : window.location.reload();
        }
      },
      enabled:
        !!user &&
        user?.emailVerified !== true &&
        (Date.now() - initTimestamp < 120000 || initTimestamp == 0),
    }
  );

  useEffect(() => {
    const removeUser = async () => {
      await signOut(auth);
      newUser?.uid && deleteUser({ userId: newUser.uid });
      setInitTimestamp(0);
      onClose();
      showInfoToast(
        "Час на підтвердження вичерпався, спробуйте зареєструватись знову."
      );
    };

    if (
      (newUser?.currentTimestamp ?? 0) - initTimestamp >= 120000 &&
      !newUser?.emailVerified
    ) {
      removeUser();
    }
  }, [newUser]);

  const signUpWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const user = await signInWithPopup(auth, provider);
      updateUser(
        {
          userId: user.user.uid,
          userData: {
            name: user.user?.displayName?.split(" ")[0] ?? "",
            surname:
              user.user?.displayName?.split(" ").slice(1).join(" ") ?? "",
            email: user.user.email ?? "",
          },
        },
        {
          onSuccess: () => {
            analyticsEvents.googleSignUp();
            navigate(-1);
          },
          onError: () => showErrorToast("Щось пішло не так! Спробуйте ще раз."),
        }
      );
    } catch (err: any) {
      showErrorToast("Щось пішло не так, спробуйте ще раз.");
    }
  };

  const signUp = async (
    email: string,
    password: string,
    name: string,
    surname: string
  ) => {
    try {
      const user = await createUserWithEmailAndPassword(auth, email, password);

      auth.currentUser && sendEmailVerification(auth.currentUser);
      await auth.currentUser?.reload();

      await updateProfile(user.user, {
        displayName: `${name} ${surname}`,
      });
      updateUser({
        userId: user.user.uid,
        userData: {
          name: name,
          surname: surname,
          email: user.user.email ?? "",
        },
      });
    } catch (error: any) {
      const message =
        error.code === "auth/email-already-in-use"
          ? "Аккаунт з даною поштою вже існує."
          : "Щось пішло не так, спробуйте ще раз.";
      showErrorToast(message);
    }
  };

  return (
    <>
      <Seo description={`Реєстрація в Читанці`} title={"Реєстрація"} />
      <Flex
        px={{ base: "0.5rem", sm: "0rem" }}
        paddingTop={{ base: "1.25rem", sm: "0rem" }}
        flexDirection={{ base: "column", lg: "row" }}
        rowGap="1rem"
        alignItems="center"
        gap={{ base: "1.5rem", lg: "7.7%" }}
      >
        <Illustration marginTop={{ base: "2.5rem", lg: "0rem" }} />
        <Box
          order={{ base: 1, lg: 2 }}
          paddingRight={{
            base: "0rem",
            sm: "1rem",
            md: "2rem",
            xl: "3rem",
            "2xl": "4.75rem",
          }}
          flexGrow={1}
        >
          <Text
            fontWeight={600}
            as="h1"
            fontSize={{
              base: "1.625rem",
              sm: "1.5rem",
              md: "1,75rem",
              xl: "2.25rem",
            }}
            line-height="2.75rem"
            marginBottom={{ base: "0.5rem", xl: "0.75rem" }}
          >
            Реєстрація
          </Text>
          <HStack
            marginBottom={{
              base: "1rem",
              sm: "1.5rem",
              md: "1.75rem",
              xl: "2rem",
              "2xl": "2.5rem",
            }}
          >
            <Text
              size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
              variant="secondary"
            >
              Вже зареєстровані?
            </Text>
            <NavLink to="/login" replace>
              <Text
                size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
                fontWeight={600}
                textDecoration="underline"
                variant="link"
                as="h2"
              >
                Увійти
              </Text>
            </NavLink>
          </HStack>
          <HStack
            justifyContent="center"
            alignItems="center"
            minWidth={{ bsae: "auto", lg: "48%" }}
            transition="all 0.3s"
            _hover={{ backgroundColor: "black", color: "white" }}
            color="black"
            onClick={signUpWithGoogle}
            marginBottom={{
              base: "1.5rem",
              sm: "0.625rem",
              md: "1.25rem",
              lg: "1.875rem",
              xl: "3.125rem",
            }}
            cursor="pointer"
            spacing={{ base: "0.75rem", md: "1rem", xl: "1.125rem" }}
            borderRadius="0.5rem"
            width={{ base: "100%", sm: "fit-content" }}
            backgroundColor="grey"
            py={{ base: "1rem", sm: "0.5rem", md: "0.75rem", xl: "1.125rem" }}
            px={{
              base: "0.5rem",
              sm: "2rem",
              md: "2.75rem",
              xl: "3.625rem",
            }}
          >
            <GoogleSignInIcon height="1.5rem" width="1.5rem" />
            <Text
              color="inherit"
              textAlign={{ base: "center", sm: "left" }}
              size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
              fontWeight={500}
            >
              Зареєструватись через Google
            </Text>
          </HStack>
          <HStack
            width="100%"
            spacing={{
              base: "1rem",
              sm: "0.75rem",
              xl: "1.25rem",
              "2xl": "1.5rem",
            }}
          >
            <Image
              alt="Pattern"
              objectFit="cover"
              backgroundRepeat="repeat"
              width="100%"
              height="0.065rem"
              overflow="hidden"
              src={dotPattern}
            />
            <Text
              variant="secondary"
              size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
            >
              або
            </Text>
            <Image
              alt="Pattern"
              objectFit="cover"
              height="0.065rem"
              width="100%"
              overflow="hidden"
              src={dotPattern}
            />
          </HStack>
          <Formik
            validationSchema={registrationScheme}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{ name: "", surname: "", email: "", password: "" }}
            onSubmit={(values, actions) => {
              signUp(
                values.email,
                values.password,
                values.name,
                values.surname
              );
            }}
          >
            {(props) => (
              <Form>
                <Stack
                  marginTop={{
                    base: "1.5rem",
                    sm: "0.75rem",
                    md: "1.25rem",
                    lg: "1.875rem",
                    xl: "3.125rem",
                  }}
                  spacing={{
                    base: "1.25rem",
                    sm: "1rem",
                    md: "1.25rem",
                    lg: "1.5rem",
                    xl: "2rem",
                  }}
                >
                  <HStack
                    flexDirection={{ base: "column", sm: "row" }}
                    alignItems="flex-start"
                    rowGap="1.25rem"
                    spacing={{
                      base: "0rem",
                      sm: "1rem",
                      xl: "1.5rem",
                      "2xl": "2rem",
                    }}
                  >
                    <FormInput
                      name="name"
                      label="Ім'я"
                      type="name"
                      placeholder="Введіть ваше ім'я"
                    />
                    <FormInput
                      name="surname"
                      label="Прізвище"
                      type="name"
                      placeholder="Введіть ваше прізвище"
                    />
                  </HStack>
                  <FormInput
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Введіть email адресу"
                  />
                  <FormInput
                    name="password"
                    label="Пароль"
                    type="password"
                    placeholder="Придумайте пароль"
                  />

                  <Button
                    margin="0"
                    isLoading={userUpdating}
                    minWidth={{ base: "100%", sm: "auto", lg: "48%" }}
                    type="submit"
                    height="fit-content"
                    transition="all 0.3s"
                    _active={{}}
                    _hover={{ backgroundColor: "black" }}
                    cursor="pointer"
                    borderRadius={{
                      base: "0.5rem",
                      sm: "0.35rem",
                      xl: "0.5rem",
                    }}
                    backgroundColor="orange"
                    width="fit-content"
                    px={{
                      base: "3rem",
                      sm: "6rem",
                      xl: "7.5rem",
                      "2xl": "8.125rem",
                    }}
                    py={{
                      base: "1.125rem",
                      sm: "0.75rem",
                      md: "1rem",
                      xl: "1.125rem",
                    }}
                  >
                    <Text
                      line-height="1.25rem"
                      fontWeight={600}
                      color="white"
                      size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                    >
                      Зареєструватись
                    </Text>
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
          <ToastContainer />
          <VerificationDrawer isOpen={isDrawerOpen} />
        </Box>
      </Flex>
    </>
  );
};

export default SignUpPage;
