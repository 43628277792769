import {
  Box,
  Button,
  Center,
  HStack,
  Show,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {  Formik, Form } from "formik";
import { FunctionComponent } from "react";
import { resetPasswordScheme } from "../../../../utilities/validationSchema";
import { ToastContainer } from "react-toastify";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import LockIcon from "../../../../ui/icons/LockIcon";
import SignInModal from "../SignInModal/SignInModal";
import { showErrorToast, showSuccessToast } from "../../../../utilities/toasts";
import FormInput from "../../../../ui/components/FormInput/FormInput";
import useActualUser from "../../../../utilities/useActualUser";
import Breadcrumb from "../../../CategoriesPage/components/Breadcrumb/Breadcrumb";
interface PersonalInfoProps {}

const PasswordChange: FunctionComponent<PersonalInfoProps> = () => {
  const { user } = useActualUser();

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  if (
    !user?.providerData?.some(
      (provider: any) => provider?.providerId == "password"
    )
  ) {
    return (
      <Center width="100%" height={{ base: "50vh" }}>
        <Text
          textAlign="center"
          opacity={0.5}
          size={{ base: "md", sm: "xs", md: "md", xl: "lg" }}
          fontWeight={500}
        >
          Ця дія неможлива з даним типом реєстрації
        </Text>
      </Center>
    );
  }

  return (
    <Box
      flexGrow={1}
      paddingTop={{
        base: "1rem",
        sm: "1.5rem",
        md: "1.75rem",
        xl: "2rem",
        "2xl": "2.5rem",
      }}
    >
      <Show below="sm">
        <Breadcrumb
          breadcrumbItems={[
            { title: "Головна", path: "/" },
            { path: "/profile", title: "Мій кабінет" },
            { title: "Змінити пароль" },
          ]}
        />
      </Show>
      <Text
        marginTop={{ base: "1rem !important", sm: "0" }}
        fontWeight={600}
        fontSize={{
          base: "1.25rem",
          sm: "0.75rem",
          md: "1rem",
          xl: "1.5rem",
        }}
      >
        Змінити пароль
      </Text>
      <Formik
        validationSchema={resetPasswordScheme}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          password: "",
          newPassword: "",
          confirmedNewPassword: "",
        }}
        onSubmit={async (values, actions) => {
          try {
            if (user) {
              const credential = EmailAuthProvider.credential(
                user.email,
                values.password
              );
              await reauthenticateWithCredential(user, credential);
              await updatePassword(user, values.newPassword);
              showSuccessToast("Пароль успішно змінено.");
            }
          } catch (error: any) {
            if (error?.code == "auth/requires-recent-login") {
              onModalOpen();
            } else if (error.code === "auth/wrong-password") {
              showErrorToast(
                "Введений пароль не співпадає з вашим поточним паролем."
              );
            } else {
              showErrorToast("Шось пішло не так! Спробуйте ще раз.");
            }
          }
        }}
      >
        {(props) => (
          <Form>
            <Stack
              width={{ base: "100%", md: "80%", "2xl": "69.7%" }}
              marginTop={{
                base: "2rem",
                sm: "1.5rem",
                md: "1.75rem",
                xl: "2rem",
                "2xl": "2.5rem",
              }}
              spacing={{
                base: "1.25rem",
                sm: "1rem",
                md: "1.25rem",
                lg: "1.5rem",
                xl: "2rem",
              }}
            >
              <FormInput
                name="password"
                label="Поточний пароль"
                type="password"
                placeholder="Ваш поточний пароль"
                isResetable={true}
              />
              <FormInput
                name="newPassword"
                label="Новий пароль"
                type="password"
                placeholder="Придумайте пароль"
                isResetable={false}
              />
              <FormInput
                name="confirmedNewPassword"
                label="Підтвердження паролю"
                type="password"
                placeholder="Повторіть новий пароль"
                isResetable={false}
              />

              <Button
                minWidth={{ base: "100%", sm: "60%", lg: "42.5%" }}
                type="submit"
                height="fit-content"
                transition="all 0.3s"
                _active={{}}
                _hover={{ backgroundColor: "black" }}
                cursor="pointer"
                borderRadius={{ base: "0.5rem", sm: "0.35rem", xl: "0.5rem" }}
                backgroundColor="orange"
                width="fit-content"
                py={{
                  base: "1.125rem",
                  sm: "0.75rem",
                  md: "1rem",
                  xl: "1.125rem",
                }}
              >
                <HStack
                  alignItems="center"
                  spacing={{ base: "0.5rem", xl: "0.75rem" }}
                >
                  <LockIcon
                    height="fit-content"
                    fill="white"
                    width={{
                      base: "1.25rem",
                      sm: "1rem",
                      xl: "1.25rem",
                    }}
                  />
                  <Text
                    line-height="1.25rem"
                    fontWeight={400}
                    color="white"
                    size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                  >
                    Змінити пароль
                  </Text>
                </HStack>
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
      <SignInModal
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        onModalOpen={onModalOpen}
      />
      <ToastContainer />
    </Box>
  );
};

export default PasswordChange;
