import { FunctionComponent } from "react";
import imageCover from "../../assets/Vyshniia.svg";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import { Field, Formik, Form } from "formik";
import { emailScheme } from "../../utilities/validationSchema";
import { auth } from "../../utilities/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { showErrorToast, showSuccessToast } from "../../utilities/toasts";
import { ToastContainer } from "react-toastify";
import FormInput from "../../ui/components/FormInput/FormInput";
import Seo from "../../ui/components/Seo/Seo";
interface ResetPasswordPageProps {}

const ResetPasswordPage: FunctionComponent<ResetPasswordPageProps> = () => {
  return (
    <>
      <Seo
        description={`Відновити пароль в Читанці`}
        title={"Відновити пароль"}
      />
      <Flex
        margin="0 auto"
        width={{ base: "100%", sm: "60%", "2xl": "42%" }}
        flexDir="column"
        alignItems="center"
      >
        <Image
        alt='Reset password'
          width={{
            base: "11.875rem",
            sm: "12.5rem",
            xl: "17.2rem",
          }}
          marginBottom={{ base: "1rem", md: "1.5rem", xl: "3rem" }}
          src={imageCover}
        />
        <Text
          marginBottom={{ base: "0.5rem", xl: "0.75rem" }}
          fontWeight={600}
          fontSize={{
            base: "1.75rem",
            sm: "1.5rem",
            md: "1,75rem",
            xl: "2.25rem",
          }}
        >
          Забули пароль?
        </Text>
        <Text
          marginBottom={{
            base: "1rem",
            sm: "1.5rem",
            md: "1.75rem",
            xl: "2rem",
          }}
          size={{
            base: "md",
            sm: "xs",
            md: "sm",
            xl: "md",
          }}
          textAlign="center"
          variant="secondary"
        >
          Введіть ваш email і ми надішлемо вам посилання для скидання паролю.
        </Text>
        <Formik
          validationSchema={emailScheme}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={{
            email: "",
          }}
          onSubmit={async (values, actions) => {
            try {
              await sendPasswordResetEmail(auth, values.email, {
                url: "https://chytanka.com/profile",
              });
              showSuccessToast("Лист надіслано.");
            } catch (error) {
              showErrorToast("Щось пішло не так.і");
            }
          }}
        >
          {(props) => (
            <Form style={{ width: "100%" }}>
              <Flex
                gap={{
                  base: "1.25rem",
                  sm: "1.5rem",
                  md: "1.75rem",
                  xl: "2rem",
                  "2xl": "2.5rem",
                }}
                flexDir="column"
                alignItems="center"
              >
                <FormInput
                  name="email"
                  placeholder="Введіть email адресу"
                  label="Email"
                  type="email"
                />

                <Button
                  minWidth={{ base: "100%", sm: "60%", lg: "42.5%" }}
                  type="submit"
                  height="fit-content"
                  transition="all 0.3s"
                  _hover={{ backgroundColor: "black" }}
                  cursor="pointer"
                  borderRadius={{ base: "0.5rem", sm: "0.35rem", xl: "0.5rem" }}
                  backgroundColor="orange"
                  width="fit-content"
                  py={{
                    base: "1.125rem",
                    sm: "0.75rem",
                    md: "1rem",
                    xl: "1.125rem",
                  }}
                >
                  <HStack alignItems="center" spacing="0.75rem">
                    <Text
                      line-height="1.25rem"
                      fontWeight={400}
                      color="white"
                      size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                    >
                      Скинути пароль
                    </Text>
                  </HStack>
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </Flex>
    </>
  );
};

export default ResetPasswordPage;
