import { useQuery, useMutation } from "react-query";
import api from "../../../services/api/api";
import {
  AuthorBookdResponseType,
  BookType,
  HasBookType,
  PaymentResponseType,
  PublisherBooksCount,
} from "./types";
import { BooksResponseType } from "../../HomePage/api/types";
export const useBookDetails = (id: string) => {
  return useQuery(
    [id],
    () => {
      return api.get<string, BookType>(`/bookInfo?id=${id}`);
    },
    {
      refetchOnWindowFocus: false,
      enabled: id !== "",
    }
  );
};

export const useBookDetailsByTransliteration = (transliteration: string) => {
  return useQuery(
    [transliteration],
    () => {
      return api.get<string, BookType>(
        `/bookByTransliteration?id=${transliteration}`
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: transliteration !== "",
    }
  );
};

export const useUserHasBook = (userId: string, bookId: string) => {
  return useQuery(
    ["userHasBook", userId, bookId],
    () => {
      return api.get<string, HasBookType>(
        `/userHasBook?userId=${userId}&bookId=${bookId}`
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: userId !== "" && bookId !== "",
    }
  );
};

export const useBookList = (type: string) => {
  return type === "publishers" ? usePublishersBooks : useAuthorsBooks;
};

export const useBooksByTransliteratedAuthor = (
  transliteratedName: string,
  page?: number,
  limit?: number
) => {
  return useQuery(
    [transliteratedName, page, "booksByTransliteratedAuthor"],
    () => {
      return api.get<string, AuthorBookdResponseType>(
        `/booksByTransliteratedAuthor?author=${transliteratedName}${
          page !== undefined ? `&page=${page}` : ""
        }${limit !== undefined ? `&limit=${limit}` : ""}`
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: transliteratedName !== "",
    }
  );
};

export const usePublishersBooks = (
  name: string,
  page?: number,
  limit?: number,
  searchParameter?: string,
  options?: { suspense: boolean },
  fetchAll?: boolean
) => {
  return useQuery(
    [name, page, "booksByPublisher", searchParameter],
    () => {
      return api.get<string, BooksResponseType>(
        `/booksByPublisher?publisher=${name}${
          page !== undefined ? `&page=${page}` : ""
        }${limit !== undefined ? `&limit=${limit}` : ""}${
          searchParameter !== "" && searchParameter !== undefined
            ? `&searchParameter=${searchParameter}`
            : ""
        }`
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: name !== "",
      suspense: options?.suspense || false,
    }
  );
};

export const usePublishersBooksCount = (name: string) => {
  return useQuery(
    ["publisherBookCount", name],
    () => {
      return api.get<string, PublisherBooksCount>(
        `/publisherBookCount?publisherName=${name}`
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: name !== "",
      suspense: false,
    }
  );
};

export const useAuthorsBooks = (
  name: string,
  page?: number,
  limit?: number
) => {
  return useQuery(
    [name, page, "booksByAuthor"],
    () => {
      return api.get<string, BooksResponseType>(
        `/booksByAuthor?author=${name}&page=${page}&limit=${limit}`
      );
    },
    {
      refetchOnWindowFocus: false,
      enabled: name !== "",
    }
  );
};

export const usePurchaseBook = (userId: string, bookId: string) => {
  return useMutation((params: { userId: string; bookId: string }) => {
    return api.post<string, PaymentResponseType>("/purchaseBook", params);
  });
};
