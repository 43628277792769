import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  forwardRef,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { backgroundAnimation, elementAnimation } from "../../utils/animations";
import step from "../../../../assets/Authors-landing/tab-3-step.png";
import element from "../../../../assets/Authors-landing/tab-3-element.png";
import background from "../../../../assets/Authors-landing/tab-3-bg.png";
import ThinkingEmojiIcon from "../../../../ui/icons/MonocleIcon";
import DiscoverMoreIcon from "../../../../ui/icons/DiscoverMoreIcon";
import { LandingSlide } from "../../../types";

interface RegistrationSectionProps extends LandingSlide {}

const RegistrationSection = forwardRef<RegistrationSectionProps, "div">(
  ({ nextStep }, ref) => {
    const buttonIconWidth = useBreakpointValue({
      base: "1rem",
      lg: "1.5rem",
      "2xl": "2.5rem",
    });

    return (
      <Flex
        ref={ref}
        maxHeight="100%"
        height="100%"
        flex="0 0 auto"
        alignItems="center"
        flexDirection={{ base: "column", lg: "row" }}
        gap={{ base: "1.25rem", lg: "4rem", xl: "6.25rem" }}
        width="100%"
        position="relative"
        px={{ base: "1.25rem", lg: 0 }}
      >
        <Flex
          justifyContent="flex-end"
          position="relative"
          alignSelf="flex-start"
          flexGrow={1}
          height="100%"
          width="100%"
          maxWidth={{ base: "100%", lg: 450, "2xl": 698 }}
        >
          <Image
            position="absolute"
            width={{ base: "auto", lg: "100%" }}
            height={{ base: "100%", lg: "auto" }}
            maxH="100%"
            left="5%"
            animation={backgroundAnimation(20)}
            src={background}
          />
          <Image
            animation={elementAnimation}
            width="auto"
            zIndex={2}
            mx="auto"
            position="absolute"
            top={{ base: "1rem", lg: "0" }}
            left="25%"
            height={{ base: "97%", "2xl": "100%" }}
            src={element}
          />
        </Flex>
        <Stack width={{ base:'100%', lg:"fit-content"}} gap={{ base: "1.25rem", lg: "3rem" }}>
          <Stack
            gap={{ base: "0.75rem", lg: "1.25rem" }}
            maxWidth={{ base: "100%", lg: 680, "2xl": 807 }}
          >
            <Stack gap={{ base: "1.25rem", lg: "2rem" }}>
              <Image width="7.5rem" src={step} />

              <Text
                mt="0 !important"
                fontFamily="OddvalText"
                fontWeight="600"
                color="#000100"
                lineHeight={{ base: "2rem", lg: "3rem", "2xl": "4rem" }}
                fontSize={{ base: "1.75rem", lg: "2.5rem", "2xl": "3.75rem" }}
                fontStyle="normal"
              >
                Зареєструйся на платформі
              </Text>
            </Stack>

            <Text
              mt="0 !important"
              whiteSpace="pre-wrap"
              fontFamily="Work Sans, sans-serif"
              letterSpacing={{ base: "-0.8px", "2xl": "-1px" }}
              lineHeight={{ base: "1.5rem", "2xl": "1.75rem" }}
              maxWidth={668}
              fontSize={{ base: "1rem", lg: "1.25rem" }}
            >
              Перейди на платформу Читанка та зареєструйся, щоб опублікувати
              свою книжку. Опис, деталі, подробиці.
            </Text>
          </Stack>
          <Button
            py={{ base: "1.5rem", "2xl": "2.375rem" }}
            mt="0 !important"
            boxShadow="0.35rem 0.35rem black"
            variant="transparent"
            letterSpacing="-1.2px"
            fontSize={{ lg: "1.25rem", "2xl": "1.5rem" }}
            width={{ base: "auto", lg: "20rem", "2xl": "24rem" }}
            iconSpacing="0"
            gap={{ base: "0.75rem", lg: "1rem", "2xl": "1.5rem" }}
            rightIcon={<DiscoverMoreIcon width={buttonIconWidth} />}
            onClick={nextStep}
          >
            Нумо! Гайда!
          </Button>
        </Stack>
      </Flex>
    );
  }
);

export default RegistrationSection;
