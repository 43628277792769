import { FunctionComponent } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

interface DeepLinkRouteProps {}

const DeepLinkRoute: FunctionComponent<DeepLinkRouteProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const bookId = searchParams.get("id");
  return (
    <>
      <Navigate to={`/books/${bookId}?id=${bookId}`} replace />
    </>
  );
};

export default DeepLinkRoute;
