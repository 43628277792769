import { Button } from "@chakra-ui/button";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Center, Flex, VStack, Text } from "@chakra-ui/layout";
import { SearchIcon } from "../../../../ui/icons/SearchIcon";
import { Image } from "@chakra-ui/image";
import heroFrame from "../../../../assets/Hero-frame.svg";
import heroMobile from "../../../../assets/Hero-mobile.svg";
import { useBreakpoint } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
export const Hero: React.FC = () => {
  const breakpoint = useBreakpoint();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      searchBooks();
    }
  };
  const searchBooks = () => {
    search !== "" && navigate(`/catalogue?searchParameter=${search}`);
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      gap={{ base: "1rem", md: "2rem" }}
      borderRadius={{ base: "1rem", md: "2rem" }}
      width="100%"
      py={{
        base: "2.5rem",
        sm: "2.5rem",
        md: "4rem",
        xl: "5.5rem",
        "2xl": "7.65rem",
      }}
      px={{
        base: "1.25rem",
        sm: "4.25rem",
        md: "7rem",
        xl: "8.5rem",
        "2xl": "11.5rem",
      }}
      backgroundColor="grey"
      position="relative"
    >
      <Text
        as="h1"
        zIndex={1000}
        fontWeight={600}
        fontSize={{
          base: "1.25rem",
          sm: "1.5rem",
          md: "1,75rem",
          xl: "2.25rem",
        }}
        variant="primary"
        lineHeight="1em"
      >
        Знайди свою книжку
      </Text>
      <InputGroup
        zIndex={1000}
        minWidth="8rem"
        width={{ base: "100%", sm: "56.5%" }}
        position="relative"
        borderRadius={{
          base: "0.5rem",
          sm: "0.3rem",
          md: "0.5rem",
          xl: "0.75rem",
        }}
      >
        <Input
          spellCheck={false}
          onKeyDown={handleKeyDown}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          outline="none !important"
          _focus={{
            border: "0.0625rem solid black",
            outline: "none !important",
            boxShadow: "none",
          }}
          border="0.0625rem solid transparent"
          _hover={{ border: "0.0625rem solid grey" }}
          transition="all 0.1s linear"
          boxShadow={{
            base: "none",
            sm: "0.25rem 0.25rem 0.5rem 0.124rem rgba(0, 0, 0, 0.1)",
            xl: "none !important",
          }}
          borderRadius="inherit !important"
          py={{ base: "1.25rem !important", sm: "auto" }}
          size={{ base: "mobile", sm: "sm", md: "md", xl: "lg" }}
          background="white"
          placeholder="Що шукаєте?"
        />
        <InputRightElement
          right={{ base: "0.5rem", sm: "0.25rem", md: "0.5rem" }}
          top="50%"
          transform="translateY(-50%)"
          h={{
            base: "2.5rem",
            sm: "1.75rem",
            md: "2rem",
            xl: "2.75rem",
          }}
          w="7.42%"
          maxW="7.42% !important"
          minWidth={{
            base: "3.25rem",
            sm: "2.25rem",
            md: "2.75rem",
            xl: "3.5rem",
          }}
        >
          <Button
            onClick={searchBooks}
            _hover={{ opacity: "0.8" }}
            _active={{ filter: "brightness(150%)" }}
            background="black"
            p="0rem"
            minWidth="100%"
            maxWidth="100% !important"
            maxH={{
              base: "2.5rem",
              sm: "1.75rem",
              md: "2.5rem",
              xl: "2.75rem",
            }}
            height="100%"
            borderRadius={{
              base: "0.5rem",
              sm: "0.3rem",
              md: "0.5rem",
              xl: "0.75rem",
            }}
          >
            <Center>
              <Flex
                width={{
                  base: "1.25rem",
                  sm: "0.75rem",
                  lg: "1rem",
                  "2xl": "1.25rem",
                }}
                alignItems="center"
                justifyContent="center"
              >
                <SearchIcon fill="white" />
              </Flex>
            </Center>
          </Button>
        </InputRightElement>
      </InputGroup>
      <Image
        maxHeight={{ base: "72.5%", sm: "100%" }}
        width="auto"
        right={{ base: "0.25rem", sm: "4.22%" }}
        top={{ base: "auto", sm: "0" }}
        bottom={{ base: "0.25rem", sm: "auto" }}
        position="absolute"
        src={breakpoint === "base" ? heroMobile : heroFrame}
        alt="Hero"
      />
    </Flex>
  );
};
