import { checkboxAnatomy } from "@chakra-ui/anatomy";
import {
  border,
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  label: {
    fontFamily: "Commissioner", // change the font family of the label
  },
  control: {
    backgroundColor: "transparent",
    border: "0.125rem solid black !important",
    borderRadius: "0.25rem",
    transition: "all 0.3s",
    outline: 0,

    _hover: {
      border: "0.125rem solid #FF5500 !important",
    },

    _checked: {
      backgroundColor: "#FF5500",
      border: "none !important",
    },

    _focus: {
      boxShadow: "none",
      borderColor: "transparent",
    }

  },
});

const sizes = {
  lg: definePartsStyle({
    control: defineStyle({
      boxSize: 5,
    }),
    label: defineStyle({
      fontSize: "md",
    }),
  }),
  md: definePartsStyle({
    control: defineStyle({
      boxSize: 4,
    }),
    label: defineStyle({
      fontSize: "sm",
    }),
  }),
  sm: definePartsStyle({
    control: defineStyle({
      boxSize: 3,
    }),
    label: defineStyle({
      fontSize: "xs",
    }),
  }),
  mobile: definePartsStyle({
    control: defineStyle({
      boxSize: 5,
    }),
    label: defineStyle({
      fontSize: "1rem"
    }),
    icon: {
      boxSize: 2.5
    }
  }),
};

export const CheckboxStyles = defineMultiStyleConfig({ baseStyle, sizes });
