import { useQuery } from "react-query";
import api from "../../../services/api/api";
import { BooksResponseType } from "../../HomePage/api/types";
export const useBooksByCategory = (categoryName: string, page: number, limit: number) => {
  return useQuery(
    [categoryName, page, limit],
    () => {
      return api.get<string, BooksResponseType>(
        `/booksByCategory?category=${categoryName}&page=${page}&limit=${limit}`
      );
    },
    {
      refetchOnWindowFocus: false,
      suspense: true,
      enabled: categoryName !== '',
    }
  );
};

