import { Box, Image, Text, forwardRef } from "@chakra-ui/react";
import { GenreProps } from "../../api/types";
import defaultCover from "../../../../assets/Genre-default.svg";
const Genre= forwardRef<GenreProps, 'div'>(({
  id,
  name,
  backgroundImageUrl,
  colorCode,
  isActive,
  handleClick,
}, ref) => {
  return (
    <Box
      ref={ref}
      display="flex"
      alignItems="flex-end"
      maxHeight={{ base: "3.875rem", sm: "3rem", md: "3.75rem", xl: "4.75rem" }}
      transition={"all 0.3s"}
      _hover={{
        transform: "translate(-0.3rem, -0.3rem)",
        boxShadow: "0.3rem 0.3rem  rgba(0,0,0,1)",
      }}
      transform={isActive ? "translate(-0.3rem, -0.3rem)" : ""}
      boxShadow={isActive ? "0.3rem 0.3rem  rgba(0,0,0,1)" : ""}
      cursor="pointer"
      overflow="hidden"
      position="relative"
      borderRadius={{ base: "0.75rem", sm: "0.5rem", xl: "0.75rem" }}
      px={{ base: "0.75rem", sm: "0.65rem", md: "0.8rem", xl: "1rem" }}
      paddingBottom={{
        base: "0.75rem",
        sm: "0.5rem",
        md: "0.7rem",
        xl: "0.875rem",
      }}
      paddingTop={{ base: "1.25rem", sm: "1.35rem", md: "1.5rem", xl: "2rem" }}
      maxWidth={{ base: "160px", md: "160px", xl: "180px" }}
      flexGrow={1}
      minWidth={{ base: "10rem", sm: "1rem", "2xl": "170px" }}
      width={{
        base: "50%",
        sm: "25%",
        md: "16%",
        xl: "13%",
        "2xl": "12.65%",
      }}
      backgroundColor={colorCode}
      onClick={() => handleClick(id, name)}
    >
      <Text
        sx={{ wordWrap: "normal !important" }}
        maxWidth="70%"
        color="white"
        size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
        variant="primary"
      >
        {name ?? "Назва"}
      </Text>
      <Image
        height="100%"
        bottom="0"
        right="0.25rem"
        position="absolute"
        src={backgroundImageUrl}
        fallbackSrc={defaultCover}
        alt={name}
      />
    </Box>
  );
})

export default Genre;
