import { extendTheme } from "@chakra-ui/react";
import { TextStyles as Text } from "./components/TextStyles";
import { InputStyle as Input } from "./components/InputStyles";
import { CardStyles as Card } from "./components/CardStyles";
import { DrawerStyles as Drawer } from "./components/DrawerStyles";
import { CheckboxStyles as Checkbox } from "./components/CheckboxStyles";
import { ButtonStyles as Button } from "./components/ButtonStyles";
// example theme
export const mainTheme = extendTheme({
  colors: {
    main: "#FF5500",
    grey: "#F0F0F0",
    orange: "#FF5500",
    black: "#000000",
    white: "#FFF",
    pale: "#F3E7D7",
  },

  components: {
    Text,
    Input,
    Card,
    Drawer,
    Checkbox,
    Button,
  },

  breakpoints: {
    sm: "37em", // 480px
    md: "51em", // 768px
    lg: "62em", // 992px
    xl: "87em", // 1280px
    "2xl": "90.5em", // 1536px
  },
});
