import { useQuery } from "react-query";
import api from "../../../services/api/api";
import { AuthorsResponseType, FilterOptionsType } from "./types";
import { BooksResponseType } from "../../HomePage/api/types";
import objectToUrlParams from "../../../utilities/objectToUrlParams";
const useFilteredBook = (
  filterOptions: FilterOptionsType,
  page: number,
  limit: number,
) => {

  const urlParams = `${objectToUrlParams(filterOptions)}&page=${page}&limit=${limit}`.replace('publishers','publishingHouse');

  return useQuery(["filteredBooks", filterOptions, page], () => {
    return api.get<string, BooksResponseType>(
      `/filteredBooks?${urlParams}`
    );
  });

};

export const useAuthors = () => {
  return useQuery(
    ["authors"],
    () => {
      return api.get<string, AuthorsResponseType>("/authors?fetchAll=true");
    },
    {
      refetchOnWindowFocus: false,
      suspense: false,
      staleTime: Infinity
    }
  );
};


export default useFilteredBook;
