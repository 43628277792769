import { Icon } from "@chakra-ui/icon";

interface AboutIcon {
  width?: number | string;
  height?: number | string;
  fill?: string
}

const AboutIcon: React.FC<AboutIcon> = (props) => {
  return (
    <Icon
      {...props}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill={props.fill??'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.2345"
        y="1.0625"
        width="22"
        height="22"
        rx="1"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M20 6.60402L17.5945 6.17536L15.9633 9.63166L17.9002 9.97682L20 6.60402Z"
        fill="black"
      />
      <path
        d="M7.00376 16.7296C6.05935 16.7296 5.32043 16.476 4.78701 15.9688C4.26234 15.4616 4 14.7533 4 13.8439V11.6927H6.4922V13.5029C6.4922 13.8964 6.57528 14.1849 6.74142 14.3686C6.90757 14.5522 7.16553 14.644 7.51532 14.644C7.7864 14.644 8.0706 14.6003 8.36791 14.5128V11.6927H10.8601V18.461H8.36791V16.5591C7.9132 16.6728 7.45848 16.7296 7.00376 16.7296Z"
        fill="black"
      />
      <path
        d="M12.6928 11.6927H15.1325V14.9064L18.1232 11.6927H19.789V18.461H17.3493V15.2474L14.3717 18.461H12.6928V11.6927Z"
        fill="black"
      />
    </Icon>
  );
};

export default AboutIcon;
