import { Box, Button, HStack, Stack, Text } from "@chakra-ui/react";
import { ArrowIcon } from "../../icons/ArrowIcon";

interface PaginationProps {
  pageCount: number;
  currentPage: number;
  onChange: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
}

export const Paginate: React.FC<PaginationProps> = ({
  pageCount,
  onChange,
  currentPage,
  previousPage,
  nextPage,
}) => {
  const pageNumbers = [];

  if (pageCount < 6) {
    for (let i = 1; i <= pageCount; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage < pageCount - 4) {
    for (let i = currentPage - 1 || currentPage; i < currentPage + 3; i++) {
      pageNumbers.push(i);
    }
    pageNumbers.push("...");
    pageNumbers.push(pageCount);
  } else {
    for (let i = pageCount - 5; i <= pageCount; i++) {
      pageNumbers.push(i);
    }
  }

  const handleClick = (number: string | number) => {
    if (typeof number === "number") {
      onChange(number);
    }
  };

  return (
    <>
      {pageCount > 1 && (
        <Stack
          flexWrap="wrap"
          justifyContent="center"
          alignItems={{ base: "flex-end", sm: "center" }}
          flexDirection="row"
          gap={{ base: "1rem", md: "1.75rem", xl: "2.5rem" }}
          rowGap="2rem"
        >
          <HStack
            order={1}
            onClick={previousPage}
            cursor="pointer"
            spacing={{ base: "0.5rem", md: "0.75rem", xl: "0.875rem" }}
            flexGrow="0"
            borderRadius="0.5rem"
            background='white'
            border={`0.0625rem solid ${currentPage === 1 ? 'grey': '#FF5500'}`}
            px={{ base: "1.625rem", sm: "1rem", xl: "1.3rem" }}
            py={{
              base: "0.875rem",
              sm: "0.5rem",
              xl: "0.875rem",
            }}
          >
            <Box
              width={{
                base: "1.15rem",
                sm: "0.75rem",
                md: "0.865rem",
                xl: "1.15rem",
              }}
              transform="rotate(-90deg)"
            >
              <ArrowIcon fill={`${currentPage === 1 ? 'grey': '#FF5500'}`} />
            </Box>
            <Text
              fontWeight={500}
              variant={currentPage === 1 ? 'secondary': 'link'}
              size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
            >
              Назад
            </Text>
          </HStack>
          <Stack
            minWidth={{ base: "100%", sm: "fit-content" }}
            order={{ base: 3, sm: 2 }}
            alignItems="baseline"
            justifyContent="center"
            flexDirection="row"
            gap={{ base: "0.35rem", md: "0.5rem", xl: "0.75rem" }}
            margin="0rem !important"
          >
            {pageNumbers.map((number) => (
              <Button
                _hover={{}}
                _active={{}}
                transition="all 0.3s"
                padding="0rem !important"
                minWidth={{
                  base: "2rem",
                  sm: "1.75rem",
                  xl: "2.5rem",
                }}
                maxWidth={{
                  base: "2rem",
                  sm: "1.75rem",
                  xl: "2.5rem",
                }}
                height={{
                  base: "2rem",
                  sm: "1.75rem",
                  xl: "2.5rem",
                }}
                borderRadius="50%"
                backgroundColor={
                  currentPage === number ? "orange" : "transparent"
                }
                key={number}
                cursor={typeof number === "number" ? "pointer" : "text"}
                onClick={() => handleClick(number)}
              >
                <Text
                  fontWeight={500}
                  size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
                  color={currentPage === number ? "white" : "black"}
                  lineHeight="1.25rem"
                >
                  {number}
                </Text>
              </Button>
            ))}
          </Stack>
          <HStack
            margin="0rem !important"
            order={{ base: 2, sm: 3 }}
            onClick={nextPage}
            cursor="pointer"
            spacing="0.875rem"
            flexGrow="0"
            borderRadius="0.5rem"
            background={`${currentPage === pageCount ? 'white': 'orange'}`}
            border={`0.0625rem solid ${currentPage === pageCount ? 'grey': 'orange'}`}
            px={{ base: "1.625rem", sm: "1rem", xl: "1.3rem" }}
            py={{
              base: "0.875rem",
              sm: "0.5rem",
              xl: "0.875rem",
            }}
          >
            <Text
              fontWeight={500}
              variant={currentPage === pageCount ? 'secondary': ''}
              size={{ base: "md", sm: "xs", md: "sm", xl: "md" }}
            >
              Далі
            </Text>
            <Box
              width={{
                base: "1.15rem",
                sm: "0.75rem",
                md: "0.865rem",
                xl: "1.15rem",
              }}
              transform="rotate(90deg)"
            >
              <ArrowIcon fill={`${currentPage === pageCount ? 'grey': 'white'}`} />
            </Box>
          </HStack>
        </Stack>
      )}
    </>
  );
};
