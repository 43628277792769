import { NavLink } from "react-router-dom";
import { PublisherBookItem } from "../PublisherBookItem/PublisherBookItem";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { BiChevronRight } from "react-icons/bi";
import { PublisherBookType } from "../../api/types";
import { useBookList } from "../../../BookDetailsPage/api/client";
import { analyticsEvents } from "../../../../utilities/analyticsEvents";

interface MobilePublisherBookListProps {
  publisherName: string;
  publisherId: string;
}

export const MobilePublisherBookList: React.FC<
  MobilePublisherBookListProps
> = ({ publisherId, publisherName }) => {
  const {
    data: bookList,
    isLoading,
    error,
  } = useBookList("publishers")(publisherName, 1, 3, undefined, {
    suspense: true,
  });

  return (
    <Flex
      flexDirection="column"
      width="100%"
      gap="1.25rem"
      alignItems="flex-start"
    >
      <Text
        lineHeight="1"
        fontWeight={400}
        fontSize="1.25rem"
        variant="primary"
        marginLeft="0.5rem"
        as='h3'
      >
        Книжки видавництва
      </Text>
      {bookList?.books.length === 0 ? (
        <Center width="100%">
          <Text as="h3" opacity={0.5} size="md" fontWeight={500}>
            На даний момент книжок немає
          </Text>
        </Center>
      ) : (
        <Flex gap="0.75rem" width="100%" flexDirection="column">
          {bookList?.books.slice(0, 3).map((book: PublisherBookType) => {
            return <PublisherBookItem key={book.id} {...book} />;
          })}
        </Flex>
      )}

      <NavLink
        onClick={() => analyticsEvents.selectPublisher(publisherName)}
        style={{ alignSelf: "center" }}
        to={`/publishers/${publisherName ?? ""}`}
      >
        <Flex alignItems="center" gap="0.5rem">
          <Text size="caption" variant="link">
            До видавництва
          </Text>
          <Box boxSize="1rem">
            <BiChevronRight size="100%" color="#FF5500" />
          </Box>
        </Flex>
      </NavLink>
    </Flex>
  );
};
