import { Flex, useBreakpoint, useBreakpointValue } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import ContentLoader from "react-content-loader";
interface ContentLoaderProps {}

const MobileContentLoader: FunctionComponent<ContentLoaderProps> = () => {
  const breakpoint = useBreakpoint();
  const imageWidth = useBreakpointValue({
    base: "20rem",
    md: "23rem",
    xl: "30rem",
    "2xl": "31.75rem",
  });
  const imageHeight = parseFloat(imageWidth ?? "") * 1.41 * 16;
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={window.outerHeight}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="1rem" ry="1rem" width="100%" height="50%" />
      <rect
        x={0}
        y="calc(70% + 1rem)"
        rx="0.5rem"
        ry="0.5rem"
        width="70%"
        height={"2rem"}
      ></rect>
      <rect
        x={0}
        y="calc(50% + 4rem)"
        rx="0.5rem"
        ry="0.5rem"
        width="40%"
        height={"1.5rem"}
      ></rect>
      <rect
        x="calc(100% - 20%)"
        y="calc(50% + 3.5rem)"
        rx="0.5rem"
        ry="0.5rem"
        width="20%"
        height="2rem"
      />
      <rect
        x={0}
        y="calc(50% + 8rem)"
        rx="0.5rem"
        ry="0.5rem"
        width="50%"
        height={"1.5rem"}
      ></rect>
      <rect
        x={0}
        y="calc(50% + 10rem)"
        rx="0.5rem"
        ry="0.5rem"
        width="30%"
        height={"1.5rem"}
      ></rect>
    
    </ContentLoader>
  );
};

export default MobileContentLoader;
