import { Button, Flex, Image, Stack, keyframes } from "@chakra-ui/react";
import { FunctionComponent, ReactNode } from "react";
import logo from "../../../../assets/Logo-black.svg";

interface AuthorsApplyLayoutProps {
  children: ReactNode;
  hasLeftPadding: boolean;
  hasRightPadding: boolean;
}

const AuthorsApplyLayout: FunctionComponent<AuthorsApplyLayoutProps> = ({
  children,
  hasLeftPadding,
  hasRightPadding,
}) => {
  return (
    <Flex
      height="100vh"
      maxHeight="100vh"
      transition="all 1s"
      pl={
        hasLeftPadding
          ? { base: "0.65rem", sm: "1.5rem", lg: "2.5rem", "2xl": "5rem" }
          : 0
      }
      pr={
        hasRightPadding
          ? { base: "0.65rem", sm: "1.5rem", lg: "2.5rem", "2xl": "5rem" }
          : 0
      }
      pb={{ base: "0.65rem", sm: "2rem", lg: "3.25rem", "2xl": "7.25rem" }}
      maxWidth={1920}
      mx="auto"
    >
      <Stack
        borderRadius={
          hasLeftPadding
            ? "0 0 0 2.5rem"
            : hasRightPadding
            ? "0 0 2.5rem 0"
            : "0"
        }
        width="100%"
        height="100%"
        maxHeight="100%"
        background="pale"
        borderBottomWidth="0.125rem"
        borderLeftWidth={hasLeftPadding ? "0.125rem" : "0"}
        borderRightWidth={hasRightPadding ? "0.125rem" : "0"}
        paddingBottom={{ base: "0.25rem", lg: "0.65rem" }}
        borderColor="black"
        paddingLeft={hasLeftPadding ? { base: "0.25rem", lg: "0.65rem" } : "0"}
        paddingRight={
          hasRightPadding ? { base: "0.25rem", lg: "0.65rem" } : "0"
        }
        transition="all 1s"
      >
        <Stack
          flexGrow={1}
          borderRadius={
            hasLeftPadding
              ? "0 0 0 2.5rem"
              : hasRightPadding
              ? "0 0 2.5rem 0"
              : "0"
          }
          borderBottomWidth="0.125rem"
          borderLeftWidth={hasLeftPadding ? "0.125rem" : "0"}
          borderRightWidth={hasRightPadding ? "0.125rem" : "0"}
          maxHeight="100%"
          borderColor="black"
          paddingBottom={{ base: "0.25rem", lg: "0.65rem" }}
          paddingLeft={
            hasLeftPadding ? { base: "0.25rem", lg: "0.65rem" } : "0"
          }
          paddingRight={
            hasRightPadding ? { base: "0.25rem", lg: "0.65rem" } : "0"
          }
          transition="all 1s"
        >
          <Stack
            flexGrow={1}
            borderBottomWidth="0.125rem"
            borderLeftWidth={hasLeftPadding ? "0.125rem" : "0"}
            borderRadius={
              hasLeftPadding
                ? "0 0 0 2.5rem"
                : hasRightPadding
                ? "0 0 2.5rem 0"
                : "0"
            }
            borderRightWidth={hasRightPadding ? "0.125rem" : "0"}
            paddingRight={
              hasRightPadding ? { base: "0.25rem", lg: "0.65rem" } : "0"
            }
            borderColor="black"
            borderTopWidth="0"
            maxHeight="100%"
            transition="all 1s"
            gap="1.75rem"
          >
            <Flex
              alignSelf="center"
              maxWidth={1666}
              pl={{ base: "0.5rem", sm: "2rem", "2xl": "3.75rem" }}
              width="100%"
              justifyContent="space-between"
              pt="1.25rem"
              pr={{ base: "0.75rem", lg: "2rem" }}
            >
              <Image
                width={{ base: "8.125rem", lg: "14.25rem" }}
                src={logo}
                alt="Logo"
              />
              <Button
                letterSpacing="-0.8px"
                variant="transparent"
                size={{ base: "base", lg: "2xl" }}
              >
                Видати книжку
              </Button>
            </Flex>
            <Stack maxHeight="100%" mt="0 !important" flexGrow={1}>
              {children}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default AuthorsApplyLayout;
