import { CategoryType } from "../../../HomePage/api/types";
import { Box, SimpleGrid, useBreakpoint } from "@chakra-ui/react";
import { Category } from "../Category/Category";
import { Carousel } from "../../../../ui/components/Carousel/Carousel/Carousel";

interface CategoryListProps {
  categories: CategoryType[];
  activeCategory: CategoryType | null;
  pickCategory: (id: string) => void;
}
export const CategoryList: React.FC<CategoryListProps> = ({
  categories,
  activeCategory,
  pickCategory,
}) => {
  const breakpoint = useBreakpoint();

  return (
    <Box>
      {breakpoint !== "base" ? (
        <SimpleGrid
          columns={{ base: 3, sm: 4, md: 2, xl: 3 }}
          gap={{ base: "0.5rem", md: "3%" }}
        >
          {categories.map((item, index) => {
            return (
              <Category
                handleClick={pickCategory}
                isActive={item.id === activeCategory?.id}
                key={index}
                {...item}
              />
            );
          })}{" "}
        </SimpleGrid>
      ) : (
        <Carousel hasButtons={false} gap="0.5rem">
          {categories.map((item) => {
            return (
              <Category
                handleClick={pickCategory}
                isActive={item.id === activeCategory?.id}
                key={item.id}
                {...item}
              />
            );
          })}
        </Carousel>
      )}
    </Box>
  );
};
