import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  forwardRef,
  useBreakpointValue,
} from "@chakra-ui/react";
import { backgroundAnimation, elementAnimation } from "../../utils/animations";
import step from "../../../../assets/Authors-landing/tab-5-step.png";
import element from "../../../../assets/Authors-landing/tab-5-element.png";
import background from "../../../../assets/Authors-landing/tab-5-bg.png";
import { LandingSlide } from "../../../types";
import QuestionMarkIcon from "../../../../ui/icons/QuestionMarkIcon";

interface PublishingSectionProps extends LandingSlide {}

const PublishingSection = forwardRef<PublishingSectionProps, "div">(
  ({ nextStep }, ref) => {
    const buttonIconHeight = useBreakpointValue({
      base: "1rem",
      lg: "1.5rem",
      "2xl": "2.5rem",
    });

    return (
      <Flex
        ref={ref}
        maxHeight="100%"
        height="100%"
        flex="0 0 auto"
        alignItems="center"
        flexDirection={{ base: "column", lg: "row" }}
        gap={{ base: "1.25rem", lg: "4rem", xl: "6.25rem" }}
        px={{ base: "1.25rem", lg: 0 }}
        width="100%"
        position="relative"
      >
        <Flex
          justifyContent="flex-end"
          position="relative"
          alignSelf="flex-start"
          flexGrow={1}
          flexShrink={1}
          maxH="100%"
          height='100%'
          width="100%"
          maxWidth={{ base: "100%",lg: 550, "2xl": 735 }}
        >
          <Image
            position={{ base: "static", lg: "absolute" }}
            width={{ base: "62%", sm: "57.8%" }}
            bottom="0"
            top={{ base: "1rem", lg: "0" }}
            left="0"
            right="0"
            margin="auto"
            animation={backgroundAnimation(20)}
            src={background}
          />
          <Image
            animation={elementAnimation}
            width={{ base: "100%", sm: "82%" }}
            zIndex={2}
            right="0"
            mx="auto"
            position="absolute"
            top="0"
            bottom="0"
            margin="auto"
            src={element}
          />
        </Flex>
        <Stack width={{ base:'100%', lg:"fit-content"}} gap={{ base: "1.25rem", lg: "3rem" }}>
          <Stack
            gap={{ base: "0.75rem", lg: "1.25rem" }}
            maxWidth={{ base:'100%', lg: 680, "2xl": 807 }}
          >
            <Stack gap={{ base: "1.25rem", lg: "2rem" }}>
              <Image width="7.5rem" src={step} />

              <Text
                mt="0 !important"
                fontFamily="OddvalText"
                fontWeight="600"
                color="#000100"
                lineHeight={{ base: "2rem", lg: "3rem", "2xl": "4rem" }}
                fontSize={{ base: "1.75rem", lg: "2.5rem", "2xl": "3.75rem" }}
                fontStyle="normal"
              >
                Завантаж epub на портал
              </Text>
            </Stack>

            <Text
              mt="0 !important"
              whiteSpace="pre-wrap"
              fontFamily="Work Sans, sans-serif"
              letterSpacing={{ base: "-0.8px", "2xl": "-1px" }}
              lineHeight={{ base: "1.5rem", "2xl": "1.75rem" }}
              maxWidth={668}
              fontSize={{ base: "1rem", lg: "1.25rem" }}
            >
              Перейди на платформу Читанка та зареєструйся, щоб опублікувати
              свою книжку. Опис, деталі, подробиці. Договір нам потрібен, тому
              що... опис, деталі, подробиці
            </Text>
          </Stack>
          <Button
            py={{ base: "1.5rem", "2xl": "2.375rem" }}
            mt="0 !important"
            boxShadow="0.35rem 0.35rem black"
            variant="transparent"
            letterSpacing="-1.2px"
            fontSize={{ lg: "1.25rem", "2xl": "1.5rem" }}
            width={{ base: "auto", lg: "20rem", "2xl": "24rem" }}
            iconSpacing="0"
            gap={{ base: "0.75rem", lg: "1rem", "2xl": "1.5rem" }}
            leftIcon={<QuestionMarkIcon height={buttonIconHeight} />}
            onClick={nextStep}
          >
            Що далі
          </Button>
        </Stack>
      </Flex>
    );
  }
);

export default PublishingSection;
