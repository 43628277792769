import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  forwardRef,
  useBreakpointValue,
} from "@chakra-ui/react";
import { backgroundAnimation, elementAnimation } from "../../utils/animations";
import step from "../../../../assets/Authors-landing/tab-4-step.png";
import element from "../../../../assets/Authors-landing/tab-4-element.png";
import background from "../../../../assets/Authors-landing/tab-4-bg.png";
import DiscoverMoreIcon from "../../../../ui/icons/DiscoverMoreIcon";
import { LandingSlide } from "../../../types";
import QuestionMarkIcon from "../../../../ui/icons/QuestionMarkIcon";

interface InstructionsSectionProps extends LandingSlide {}

const InstructionsSection = forwardRef<InstructionsSectionProps, "div">(
  ({ nextStep }, ref) => {
    const buttonIconHeight = useBreakpointValue({
      base: "1rem",
      lg: "1.5rem",
      "2xl": "2.5rem",
    });

    return (
      <Flex
        ref={ref}
        width="100%"
        flexShrink={0}
        flexGrow={1}
        justifyContent="flex-end"
      >
        <Flex
          height="100%"
          flex="0 0 auto"
          alignItems="center"
          maxHeight="100%"
          justifyContent="space-between"
          width="100%"
          pl="2rem"
          maxWidth={{ base: "100%", lg: 1320, "2xl": 1670 }}
          flexDirection={{ base: "column", lg: "row" }}
          gap={{ base: "1.25rem", lg: "4rem", xl: "6.25rem" }}
          px={{ base: "1.25rem", lg: 0 }}
          mb="-4.25rem !important"
        >
          <Stack
            order={{ base: 2, lg: 1 }}
            width={{ base: "100%", lg: "fit-content" }}
            gap={{ base: "1.25rem", lg: "3rem" }}
          >
            <Stack
              gap={{ base: "0.75rem", lg: "1.25rem" }}
              maxWidth={{ base: 680, "2xl": 700 }}
            >
              <Stack gap={{ base: "1.25rem", lg: "2rem" }}>
                <Image width="7.5rem" src={step} />

                <Text
                  mt="0 !important"
                  fontFamily="OddvalText"
                  fontWeight="600"
                  color="#000100"
                  lineHeight={{ base: "2rem", lg: "3rem", "2xl": "4rem" }}
                  fontSize={{ base: "1.75rem", lg: "2.5rem", "2xl": "3.75rem" }}
                  fontStyle="normal"
                  maxWidth={668}
                >
                  Підпиши договір з Читанкою
                </Text>
              </Stack>

              <Text
                mt="0 !important"
                whiteSpace="pre-wrap"
                fontFamily="Work Sans, sans-serif"
                letterSpacing={{ base: "-0.8px", "2xl": "-1px" }}
                lineHeight={{ base: "1.5rem", "2xl": "1.75rem" }}
                fontSize={{ base: "1rem", lg: "1.25rem" }}
              >
                Перейди на платформу Читанка та зареєструйся, щоб опублікувати
                свою книжку. Опис, деталі, подробиці. Договір нам потрібен, тому
                що... опис, деталі, подробиці
              </Text>
            </Stack>
            <Button
              py={{ base: "1.5rem", "2xl": "2.375rem" }}
              mt="0 !important"
              boxShadow="0.35rem 0.35rem black"
              variant="transparent"
              letterSpacing="-1.2px"
              fontSize={{ lg: "1.25rem", "2xl": "1.5rem" }}
              width={{ base: "auto", lg: "20rem", "2xl": "24rem" }}
              iconSpacing="0"
              gap={{ base: "0.75rem", lg: "1rem", "2xl": "1.5rem" }}
              leftIcon={<QuestionMarkIcon height={buttonIconHeight} />}
              onClick={nextStep}
            >
              Що далі
            </Button>
          </Stack>
          <Flex
            justifyContent={{ base: "center", lg: "flex-end" }}
            position="relative"
            alignSelf="flex-start"
            flexGrow={1}
            flexShrink={{ base: "auto", lg: 0 }}
            width="100%"
            height="100%"
            order={{ base: 1, lg: 2 }}
            maxWidth={{ base: "100%", lg: 545, "2xl": 734 }}
          >
            <Image
              position={{ base: "static", lg: "absolute" }}
              left="1.5%"
              top={{ base: "0", sm: "2rem", lg: "5rem", "2xl": "7.5rem" }}
              animation={backgroundAnimation(20)}
              width="78.2%"
              src={background}
            />
            <Image
              animation={elementAnimation}
              top={{ base: "1rem", sm: "2rem" }}
              width="100%"
              right={{ base: "0", lg: "2.5%" }}
              zIndex={2}
              position="absolute"
              src={element}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }
);

export default InstructionsSection;
