import { Flex, useBreakpoint, useBreakpointValue } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import ContentLoader from "react-content-loader";
interface ContentLoaderProps {}

const MyContentLoader: FunctionComponent<ContentLoaderProps> = () => {
  const breakpoint = useBreakpoint();
  const imageWidth = useBreakpointValue({
    base: "20rem",
    md: "23rem",
    xl: "30rem",
    "2xl": "31.75rem",
  });
  const imageHeight = parseFloat(imageWidth ?? "") * 1.41 * 16;

  return (
    <ContentLoader
      title="Завантаження..."
      speed={2}
      width="100%"
      height={window.innerHeight}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect
        x="0"
        y="0"
        rx="1.25rem"
        ry="1.25rem"
        width={imageWidth}
        height={imageHeight}
      />
      <rect
        x={`calc(${imageWidth} + 3.5%)`}
        y="2rem"
        rx="0.5rem"
        ry="0.5rem"
        width="20rem"
        height={"3rem"}
      />
      <rect
        x={`calc(${imageWidth} + 3.5%)`}
        y="6rem"
        rx="0.5rem"
        ry="0.5rem"
        width="12rem"
        height={"1.5rem"}
      />
      {breakpoint !== "sm" && breakpoint !== "md" && (
        <rect
          x="calc(100% - 12rem)"
          y="5rem"
          rx="0.5rem"
          ry="0.5rem"
          width="12rem"
          height="2.5rem"
        />
      )}
      <rect
        x={`calc(${imageWidth} + 3.5%)`}
        y="10rem"
        rx="0.25rem"
        ry="0.25rem"
        width={`calc(70% - calc(${imageWidth} + 3.5%))`}
        height={"1rem"}
      />
      <rect
        x={`calc(${imageWidth} + 3.5%)`}
        y="12rem"
        rx="0.25rem"
        ry="0.25rem"
        width={`calc(70% - calc(${imageWidth} + 3.5%))`}
        height={"1rem"}
      />
      <rect
        x={`calc(${imageWidth} + 3.5%)`}
        y="14rem"
        rx="0.25rem"
        ry="0.25rem"
        width={`calc(70% - calc(${imageWidth} + 3.5%))`}
        height={"1rem"}
      />
      <rect
        x={`calc(${imageWidth} + 3.5%)`}
        y="16rem"
        rx="0.25rem"
        ry="0.25rem"
        width={`calc(70% - calc(${imageWidth} + 3.5%))`}
        height={"1rem"}
      />
      <rect
        x={`calc(${imageWidth} + 3.5%)`}
        y="18rem"
        rx="0.25rem"
        ry="0.25rem"
        width={`calc(70% - calc(${imageWidth} + 3.5%))`}
        height={"1rem"}
      />
      <rect
        x={`calc(${imageWidth} + 3.5%)`}
        y="22rem"
        rx="0.75rem"
        ry="0.75rem"
        width="12rem"
        height={"2.5rem"}
      />
      <rect
        x={`calc(${imageWidth} + 3.5%)`}
        y="26rem"
        rx="0.75rem"
        ry="0.75rem"
        width={`calc(100% - calc(${imageWidth} + 3.5%))`}
        height={"6rem"}
      />
      {breakpoint !== "sm" && breakpoint !== "md" && (
        <rect
          x={`calc(${imageWidth} + 3.5%)`}
          y="35rem"
          rx="0.75rem"
          ry="0.75rem"
          width="22rem"
          height={"3rem"}
        />
      )}
    </ContentLoader>
  );
};

export default MyContentLoader;
