import {
  Button,
  Flex,
  Image,
  Stack,
  Text,
  forwardRef,
  useBreakpointValue,
} from "@chakra-ui/react";
import DiscoverMoreIcon from "../../../../ui/icons/DiscoverMoreIcon";
import { backgroundAnimation, elementAnimation } from "../../utils/animations";
import background from "../../../../assets/Authors-landing/tab-1-bg.png";
import element from "../../../../assets/Authors-landing/tab-1-element.png";
import { LandingSlide } from "../../../types";

interface StartSectionProps extends LandingSlide {}

const StartSection = forwardRef<StartSectionProps, "div">(
  ({ nextStep }, ref) => {
    const height = useBreakpointValue({
      base: "1rem",
      lg: "3rem",
      "2xl": "4.18rem",
    });

    const buttonIconWidth = useBreakpointValue({
      base: "1rem",
      lg: "1.5rem",
      "2xl": "2.5rem",
    });

    return (
      <Flex
        ref={ref}
        width="100%"
        flexShrink={0}
        flexGrow={1}
        justifyContent="flex-end"
      >
        <Flex
          height="100%"
          flex="0 0 auto"
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent={{ base: "flex-start", lg: "space-between" }}
          alignItems={"center"}
          maxHeight="100%"
          width="100%"
          pl={{ base: "1.25rem", lg: "2rem" }}
          maxWidth={{ base: "100%", lg: 1320, "2xl": 1670 }}
          mb={{ base: "0 !important", lg: "-4.25rem !important" }}
        >
          <Stack
            gap={{ base: "1.5rem", "2xl": "4rem" }}
            maxHeight="100%"
            pr={{ base: "1.25rem", lg: "0" }}
            textTransform="uppercase"
          >
            <Stack>
              <Text
                mt="0 !Important"
                color="iherit"
                variant="banner"
                fontSize={{ base: "1.5rem", lg: "2rem", "2xl": "3rem" }}
                lineHeight={{
                  base: "2rem",
                  lg: "normal",
                  "2xl": "6.75rem",
                }}
              >
                Видай
              </Text>
              <Text
                mt="0 !Important"
                fontSize={{ base: "4rem", lg: "5rem", "2xl": "10rem" }}
                lineHeight={{
                  base: "4rem",
                  lg: "normal",
                  // lg: "5rem",
                  "2xl": "7.5rem",
                }}
                color="iherit"
                variant="banner"
              >
                Книжку
              </Text>
              <Text
                lineHeight={{
                  base: "2rem",
                  lg: "normal",
                  "2xl": "6.75rem",
                }}
                fontSize={{ base: "1.5rem", lg: "2rem", "2xl": "3rem" }}
                mt="0 !Important"
                color="iherit"
                variant="banner"
              >
                Разом з Читанкою
              </Text>
            </Stack>

            <Button
              py={{ base: "1.5rem", "2xl": "2.375rem" }}
              mt="0 !important"
              boxShadow="0.35rem 0.35rem black"
              variant="transparent"
              letterSpacing="-1.2px"
              fontSize={{ lg: "1.25rem", "2xl": "1.5rem" }}
              width={{ base: "16.25rem", lg: "20rem", "2xl": "24rem" }}
              iconSpacing="0"
              gap={{ base: "0.75rem", lg: "1rem", "2xl": "1.5rem" }}
              rightIcon={<DiscoverMoreIcon width={buttonIconWidth} />}
              onClick={nextStep}
            >
              Розпочати
            </Button>
          </Stack>
          <Flex
            justifyContent="flex-end"
            position="relative"
            alignSelf={{ base: "flex-end", lg: "flex-start" }}
            flexGrow={1}
            flexShrink={0}
            width="100%"
            height={{ base: "auto", lg: "100%" }}
            alignItems="center"
            maxWidth={{ base: "100%", lg: 598, "2xl": 804 }}
          >
            <Image
              position={{ base: "static", lg: "absolute" }}
              right="0"
              top={{ base: "50%", lg: "3rem", "2xl": "4.18rem" }}
              animation={backgroundAnimation(20)}
              height={{ base: "auto", lg: `calc(100% - ${height})` }}
              width="100%"
              src={background}
            />
            <Image
              animation={elementAnimation}
              height={{ base: "90%", lg: "100%" }}
              zIndex={2}
              position="absolute"
              top={{ base: "5%", md: "auto" }}
              right="5%"
              src={element}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  }
);

export default StartSection;
