import { toast } from "react-toastify";

export const showSuccessToast = (message: string) => {
  toast.success(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 1500,
  });
};

export const showErrorToast = (message: string) => {
  toast.error(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 1500,
  });
};

export const showInfoToast = (message: string) => {
  toast.info(message, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 3000,
  });
};
