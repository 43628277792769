import { Box, Flex, Hide } from "@chakra-ui/react";
import { CategoryList } from "../../features/HomePage/components/CategoryList.tsx/CategoryList";
import { BookList } from "../../features/HomePage/components/BookList/BookList";
import { Hero } from "../../features/HomePage/components/Hero/Hero";
import { PublishersSection } from "../../features/HomePage/components/PublishersSection/PublishersSection";
import { MobilePublishersSection } from "../../features/HomePage/components/PublishersSection/MobilePublishersSection";
import Seo from "../../ui/components/Seo/Seo";
export const HomePage: React.FC = () => {
  return (
    <>
      <Seo description={`Отримуйте задоволення від читання з нашого великого асортименту електронних книг. Купуйте та читайте у наших мобільних додатках.`} title={"Читанка - читай електронні книжки"} />
      <Flex
        gap={{ base: "3rem", sm: "2rem", md: "3.5rem", xl: "5rem" }}
        flexDirection="column"
      >
        <Hero />
        <Box marginRight={{ base: "-0.5rem", sm: "0rem" }}>
          <CategoryList />
        </Box>
        <Box
          overflowX={{ base: "clip", sm: "inherit" }}
          marginRight={{ base: "-0.75rem", sm: "0rem" }}
          marginBottom={{ base: "0rem", sm: "-1rem", md: "-3rem" }}
        >
          <BookList title="Бестселери" type="bestsellerBooks" />
        </Box>
        <Box
          padding={{
            base: "0rem",
            sm: "0.75rem",
            md: "1.5rem",
            xl: "2rem",
            "2xl": "3.75rem",
          }}
          paddingBottom={{
            base: "1rem",
            md: "2rem",
            xl: "2.65rem",
            "2xl": "5rem",
          }}
          marginX={{
            base: "-0.75rem",
            md: "-1.5rem",
            lg: "-2rem",
            "2xl": "-3.75rem",
          }}
          background="grey"
        >
          <Hide below="sm">
            <PublishersSection />
          </Hide>

          <Hide above="sm">
            <MobilePublishersSection />
          </Hide>
        </Box>
        <Box
          overflowX={{ base: "clip", sm: "inherit" }}
          marginRight={{ base: "-0.75rem", sm: "0rem" }}
        >
          <BookList title="Новинки в Читанці" type="newcomingBooks" />
        </Box>
      </Flex>
    </>
  );
};
