import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Divider,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent, useMemo, useState } from "react";
import SearchBar from "../SearchBar/SearchBar";
import { useCategories, usePublishers } from "../../../HomePage/api/client";
import { useGenres } from "../../../SelectedCategoryPage/api/client";
import { FilterOptionsType } from "../../api/types";
import { useAuthors } from "../../api/client";
import { useSearchParams } from "react-router-dom";

interface FilterPanelProps {
  setFilterOptions: React.Dispatch<React.SetStateAction<FilterOptionsType>>;
  filterOptions?: FilterOptionsType;
}

const FilterPanel: FunctionComponent<FilterPanelProps> = ({
  filterOptions,
  setFilterOptions,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const handleBookTypeCheck = (e: any) => {
    const { name, checked } = e.target;
    setFilterOptions((prevFilterOptions) => {
      return (
        prevFilterOptions && {
          ...prevFilterOptions,
          [name]: checked,
        }
      );
    });
    setSearchParams({ page: '1'})
  };

  const handleCategoryCheck = (e: any) => {
    setSearchParams({ page: '1'})
    const { name } = e.target;
    setFilterOptions((prevFilterOptions) => {
      return (
        prevFilterOptions && {
          ...prevFilterOptions,
          categories: prevFilterOptions.categories.includes(name)
            ? prevFilterOptions.categories.filter(
                (category) => category !== name
              )
            : [...(prevFilterOptions?.categories ?? []), name],
        }
      );
    });
  };

  const handleGenreCheck = (e: any) => {
    const { name } = e.target;
    setFilterOptions((prevFilterOptions) => {
      return (
        prevFilterOptions && {
          ...prevFilterOptions,
          genres: prevFilterOptions.genres.includes(name)
            ? prevFilterOptions.genres.filter((genre) => genre !== name)
            : [...(prevFilterOptions?.genres ?? []), name],
        }
      );
    });
    setSearchParams({ page: '1'})
  };

  const handleAuthorCheck = (e: any) => {
    const { name } = e.target;
    setFilterOptions((prevFilterOptions) => {
      return (
        prevFilterOptions && {
          ...prevFilterOptions,
          authors: prevFilterOptions.authors.includes(name)
            ? prevFilterOptions.authors.filter((author) => author !== name)
            : [...(prevFilterOptions?.authors ?? []), name],
        }
      );
    });
    setSearchParams({ page: '1'})
  };

  const handlePublisherCheck = (e: any) => {
    const { name } = e.target;
    setFilterOptions((prevFilterOptions) => {
      return (
        prevFilterOptions && {
          ...prevFilterOptions,
          publishers: prevFilterOptions.publishers.includes(name)
            ? prevFilterOptions.publishers.filter(
                (publisher) => publisher !== name
              )
            : [...(prevFilterOptions?.publishers ?? []), name],
        }
      );
    });
    setSearchParams({ page: '1'})
  };

  const { data: publisherList } = usePublishers(true);
  const { data: categoryList, isLoading, error } = useCategories();
  const { data: genreList } = useGenres();
  const { data: authorsList } = useAuthors();
  const [publisherSearchParameter, setPublisherSearchParameter] =
    useState<string>("");
  const [authorSearchParameter, setAuthorSearchParameter] =
    useState<string>("");

  const filteredPublishers = useMemo(() => {
    return publisherList?.publishingHouses.filter(
      (publisher) =>
        publisher.name
          .toLowerCase()
          .includes(publisherSearchParameter.toLowerCase()) ||
        filterOptions?.publishers.includes(publisher.name)
    );
  }, [publisherSearchParameter, publisherList, filterOptions?.publishers]);

  const filteredAuthors = useMemo(() => {
    return authorsList?.authors.filter(
      (author) =>
        author.name
          .toLowerCase()
          .includes(authorSearchParameter.toLowerCase()) ||
        filterOptions?.authors.includes(author.name)
    );
  }, [authorSearchParameter, authorsList, filterOptions?.authors]);
  return (
    <Stack
      minHeight="85vh"
      height="fit-content"
      flexGrow={0}
      minWidth="fit-content"
      borderRadius={{ base: "0.5rem", sm: "1rem", md: "1.5rem", xl: "2rem" }}
      paddingTop={{
        base: "1rem",
        sm: "1.5rem",
        md: "1.75rem",
        xl: "2rem",
        "2xl": "2.5rem",
      }}
      paddingBottom={{
        base: "1rem",
        md: "1.5rem",
        xl: "2.5rem",
        "2xl": "5.45rem",
      }}
      px={{
        base: "1rem",
        md: "2rem",
        xl: "3rem",
        "2xl": "4.75rem",
      }}
      backgroundColor="grey"
    >
      <Text
        size={{ base: "sm", md: "md", xl: "lg" }}
        marginBottom={{ base: "0.75rem", md: "1rem", xl: "1.5rem" }}
        fontWeight={600}
        as='h2'
      >
        Фільтр
      </Text>
      <Divider
        margin="0 !important"
        borderWidth="0.0625rem"
        borderColor="black"
        orientation="horizontal"
      />
      <Stack
        spacing="1.5rem"
        divider={
          <StackDivider height="0.0675rem" borderColor="rgba(0, 0, 0, 0.1)" />
        }
      >
        <Stack
          marginTop={{ base: "0.75rem", md: "1rem", xl: "1.5rem" }}
          spacing={{ base: "0.75rem", md: "1rem", xl: "1.5rem" }}
        >
          <Checkbox
            name="bestsellers"
            isChecked={filterOptions?.bestsellers}
            onChange={handleBookTypeCheck}
            size={{ base: "sm", md: "md", xl: "lg" }}
            colorScheme="none"
            width="100%"
            as='h2'
          >
            Бестселери
          </Checkbox>
          <Checkbox
            isChecked={filterOptions?.newcomings}
            onChange={handleBookTypeCheck}
            size={{ base: "sm", md: "md", xl: "lg" }}
            name="newcomings"
            colorScheme="none"
            as='h2'
          >
            Новинки
          </Checkbox>
        </Stack>
        <Accordion
          width={{
            base: "100%",
            sm: "12rem",
            md: "13.5rem",
            xl: "15rem",
            "2xl": "19.5rem",
          }}
          allowMultiple
        >
          <Stack
            spacing={{ base: "0.75rem", md: "1rem", xl: "1.5rem" }}
            divider={
              <StackDivider
                height="0.0675rem"
                borderColor="rgba(0, 0, 0, 0.1)"
              />
            }
          >
            <AccordionItem borderWidth="0 !important">
              <AccordionButton padding="0" paddingRight="0.25rem" _hover={{}}>
                <Box flex="1" textAlign="left">
                  <Text
                    size={{ base: "sm", md: "md", xl: "lg" }}
                    fontWeight={600}
                    as='h2'
                  >
                    Категорії
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel padding="0" borderWidth={0}>
                <Stack
                  paddingTop={{ base: "0.75rem", md: "1rem", xl: "1.5rem" }}
                  spacing={{ base: "0.75rem", md: "1rem", xl: "1.5rem" }}
                >
                  {categoryList &&
                    categoryList.categories.map((category) => {
                      return (
                        <Checkbox
                          key={category.id}
                          size={{ base: "sm", md: "md", xl: "lg" }}
                          isChecked={filterOptions?.categories.includes(
                            category.name
                          )}
                          name={category.name}
                          onChange={handleCategoryCheck}
                          colorScheme="none"
                        >
                          {category.name}
                        </Checkbox>
                      );
                    })}
                </Stack>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderWidth="0 !important">
              <AccordionButton padding="0" paddingRight="0.25rem" _hover={{}}>
                <Box flex="1" textAlign="left">
                  <Text
                    size={{ base: "sm", md: "md", xl: "lg" }}
                    fontWeight={600}
                    as='h2'
                  >
                    Жанри
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel padding="0" borderWidth={0}>
                <Stack
                  paddingTop={{ base: "0.75rem", md: "1rem", xl: "1.5rem" }}
                  spacing={{ base: "0.75rem", md: "1rem", xl: "1.5rem" }}
                >
                  {genreList &&
                    genreList.genres.map((genre) => {
                      return (
                        <Checkbox
                          key={genre.id}
                          size={{ base: "sm", md: "md", xl: "lg" }}
                          name={genre.name}
                          onChange={handleGenreCheck}
                          isChecked={filterOptions?.genres.includes(genre.name)}
                          colorScheme="none"
                        >
                          {genre.name}
                        </Checkbox>
                      );
                    })}
                </Stack>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderWidth="0 !important">
              <AccordionButton padding="0" paddingRight="0.25rem" _hover={{}}>
                <Box flex="1" textAlign="left">
                  <Text
                    size={{ base: "sm", md: "md", xl: "lg" }}
                    fontWeight={600}
                    as='h2'
                  >
                    Автори
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel padding="0" borderWidth={0}>
                <Box paddingTop={{ base: "0.75rem", md: "1rem", xl: "1.5rem" }}>
                  <SearchBar
                    placeholder="Пошук авторів"
                    setState={setAuthorSearchParameter}
                  />
                  <Stack
                    css={{
                      "&::-webkit-scrollbar-track": {
                        webkitBoxShadow: "inset 0 0 0.25rem rgba(0,0,0,0.3)",
                        backgroundColor: "#F5F5F5",
                      },
                      "&::-webkit-scrollbar": {
                        width: "0.5rem",
                        backgroundColor: "#F5F5F5",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#FF5500",
                        borderRadius: "1.25rem",
                      },
                    }}
                    marginTop={{ base: "0.75rem", md: "1.25rem" }}
                    overflowY="auto"
                    maxHeight="18em"
                    spacing={{ base: "0.75rem", md: "1rem", xl: "1.5rem" }}
                  >
                    {authorsList && filteredAuthors?.length ? (
                      filteredAuthors?.map((author) => {
                        return (
                          <Checkbox
                            key={author.id}
                            onChange={handleAuthorCheck}
                            name={author.name}
                            isChecked={filterOptions?.authors.includes(
                              author.name
                            )}
                            colorScheme="none"
                            size={{ base: "sm", md: "md", xl: "lg" }}
                          >
                            {author.name}
                          </Checkbox>
                        );
                      })
                    ) : (
                      <Text
                        opacity={0.5}
                        textAlign="center"
                        size={{ base: "md", sm: "sm", xl: "md" }}
                        fontWeight={500}
                      >
                        За вашим запитом нічого не знайдено
                      </Text>
                    )}
                  </Stack>
                </Box>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderWidth="0 !important">
              <AccordionButton padding="0" paddingRight="0.25rem" _hover={{}}>
                <Box flex="1" textAlign="left">
                  <Text
                    size={{ base: "sm", md: "md", xl: "lg" }}
                    fontWeight={600}
                    as='h2'
                  >
                    Видавництва
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel padding="0" borderWidth={0}>
                <Box paddingTop={{ base: "0.75rem", md: "1rem", xl: "1.5rem" }}>
                  <SearchBar
                    placeholder="Пошук видавництв"
                    setState={setPublisherSearchParameter}
                  />
                  <Stack
                    css={{
                      "&::-webkit-scrollbar-track": {
                        webkitBoxShadow: "inset 0 0 0.25rem rgba(0,0,0,0.3)",
                        backgroundColor: "#F5F5F5",
                      },
                      "&::-webkit-scrollbar": {
                        width: "0.5rem",
                        backgroundColor: "#F5F5F5",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#FF5500",
                        borderRadius: "1.25rem",
                      },
                    }}
                    marginTop={{ base: "0.75rem", md: "1.25rem" }}
                    overflowY="auto"
                    maxHeight="18em"
                    spacing="1.5rem"
                  >
                    {publisherList && filteredPublishers?.length ? (
                      filteredPublishers?.map((publisher) => {
                        return (
                          <Checkbox
                            key={publisher.id}
                            name={publisher.name}
                            isChecked={filterOptions?.publishers.includes(
                              publisher.name
                            )}
                            onChange={handlePublisherCheck}
                            colorScheme="none"
                            size={{ base: "sm", md: "md", xl: "lg" }}
                          >
                            {publisher.name}
                          </Checkbox>
                        );
                      })
                    ) : (
                      <Text
                        opacity={0.5}
                        textAlign="center"
                        size={{ base: "md", sm: "sm", xl: "md" }}
                        fontWeight={500}
                      >
                        За вашим запитом нічого не знайдено
                      </Text>
                    )}
                  </Stack>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Stack>
        </Accordion>
      </Stack>
    </Stack>
  );
};

export default FilterPanel;
