import { Icon } from "@chakra-ui/icon";
import { StyleProps } from "@chakra-ui/react";

interface BookIconProps extends StyleProps {
  fill?: string;
}

const BookIcon: React.FC<BookIconProps> = (props) => {
  return (
    <Icon
      width="24"
      height="20"
      viewBox="0 0 24 20"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 3.69678H2C1.44772 3.69678 1 4.14449 1 4.69678L1 17.3933C1 17.9456 1.44772 18.3933 2 18.3933H22C22.5523 18.3933 23 17.9456 23 17.3933V4.69678C23 4.14449 22.5523 3.69678 22 3.69678H20.25"
        stroke={props.fill??'black'}
        stroke-width="2"
      />
      <path
        d="M20.4854 0.562074C17.7696 -0.549722 14.7446 0.160921 12 1.19659C9.2554 0.160921 6.23036 -0.549722 3.51464 0.562074C3.36268 0.623885 3.23242 0.730668 3.14068 0.868629C3.04894 1.00659 2.99994 1.16939 3 1.336L3 15.5595C2.99995 15.6956 3.03267 15.8297 3.09529 15.95C3.15791 16.0702 3.24853 16.1731 3.35922 16.2494C3.4699 16.3258 3.59728 16.3734 3.73021 16.388C3.86314 16.4027 3.99757 16.3839 4.12173 16.3334C6.55195 15.3377 9.26623 16.8376 11.6965 17.8333C11.7875 17.8685 11.8838 17.8871 11.9812 17.8883C11.9877 17.8883 11.9935 17.8883 12 17.8883C12.0065 17.8883 12.0123 17.8883 12.0188 17.8883C12.1162 17.8871 12.2125 17.8685 12.3035 17.8333C14.7338 16.8376 17.4481 15.3377 19.8783 16.3334C20.0024 16.384 20.1368 16.4029 20.2697 16.3883C20.4027 16.3738 20.5301 16.3264 20.6408 16.2501C20.7516 16.1737 20.8423 16.0707 20.9049 15.9503C20.9675 15.8299 21.0002 15.6957 21 15.5595V1.336C21.0001 1.16939 20.9511 1.00659 20.8593 0.868629C20.7676 0.730668 20.6373 0.623885 20.4854 0.562074ZM11.1818 15.9006C9.04524 15.2633 6.77435 13.7608 4.63636 14.3932V1.91581C6.75929 1.18208 9.06125 1.9387 11.1818 2.67946V15.9006ZM19.3636 14.3932C17.2258 13.7626 14.956 15.2625 12.8182 15.8931V2.67946C14.9388 1.9387 17.2407 1.18208 19.3636 1.91581V14.3932Z"
        fill={props.fill??'black'}
      />
    </Icon>
  );
};

export default BookIcon;
