import { Icon } from "@chakra-ui/icon";
import { StyleProps } from "@chakra-ui/react";

interface GooglePlayIconProps extends StyleProps {}

const GooglePlayIcon: React.FC<GooglePlayIconProps> = (props) => {
  return (
    <Icon
      {...props}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_753_1983"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="3"
        width="24"
        height="26"
      >
        <path
          d="M27.0484 14.4004C28.3172 15.0986 28.3172 16.9014 27.0484 17.5996L6.75627 28.7658C5.52052 29.4459 4 28.5634 4 27.1663L4 4.83374C4 3.43657 5.52052 2.55415 6.75627 3.23415L27.0484 14.4004Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_753_1983)">
        <path
          d="M4.63473 28.5465L17.2923 15.8178L4.84319 3.29871C4.34653 3.61709 4 4.16678 4 4.83378V27.1663C4 27.7354 4.25223 28.219 4.63473 28.5465Z"
          fill="url(#paint0_linear_753_1983)"
        />
        <path
          d="M27.0484 14.4004C28.3172 15.0986 28.3172 16.9014 27.0484 17.5996L21.9291 20.4166L17.2924 15.8176L21.6926 11.4532L27.0484 14.4004Z"
          fill="url(#paint1_linear_753_1983)"
        />
        <path
          d="M21.9291 20.4166L17.2923 15.8176L4.6347 28.5464C5.19133 29.023 6.02383 29.1689 6.75629 28.7658L21.9291 20.4166Z"
          fill="url(#paint2_linear_753_1983)"
        />
        <path
          d="M4.8432 3.29865L17.2923 15.8177L21.6926 11.4533L6.75625 3.23415C6.11046 2.87878 5.38688 2.95013 4.8432 3.29865Z"
          fill="url(#paint3_linear_753_1983)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_753_1983"
          x1="12.6769"
          y1="10.8739"
          x2="4.07106"
          y2="19.5505"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00C3FF" />
          <stop offset="1" stopColor="#1BE2FA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_753_1983"
          x1="17.2924"
          y1="15.8177"
          x2="28.7385"
          y2="15.8177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCE00" />
          <stop offset="1" stopColor="#FFEA00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_753_1983"
          x1="4.36926"
          y1="30.1002"
          x2="19.5949"
          y2="17.8934"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DE2453" />
          <stop offset="1" stopColor="#FE3944" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_753_1983"
          x1="5.10768"
          y1="1.90137"
          x2="19.5975"
          y2="13.7365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#11D574" />
          <stop offset="1" stopColor="#01F176" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default GooglePlayIcon;
