import {
  Container,
  VStack,
  Image,
  Text,
  Flex,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Center,
  HStack,
  AspectRatio,
  Show,
  Link,
  Box,
} from "@chakra-ui/react";
import { SearchIcon } from "../../icons/SearchIcon";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../icons/ArrowIcon";
import googlePlayBadge from "../../../assets/google-play-badge.png";
import appleStorebadge from "../../../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import logo from "../../../assets/Logo-white.svg";
import { useState, useEffect } from "react";
import useActualUser from "../../../utilities/useActualUser";
import { ExternalLinkIcon } from "../../icons/ExternalLinkIcon";
export const Footer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState<string>("");
  const { user } = useActualUser();

  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // }, [location]);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      searchBooks();
    }
  };
  const searchBooks = () => {
    search !== "" && navigate(`/catalogue?searchParameter=${search}`);
  };

  return (
    <Container
      margin="0rem !important"
      padding="0rem"
      minWidth="100vw"
      bg="black"
    >
      <Container
        px={{ base: "1.25rem", md: "3rem", xl: "3.5rem", "2xl": "8.5rem" }}
        py={{
          base: "1.25rem",
          sm: "1.5rem",
          md: "2rem",
          lg: "2.5rem",
          xl: "2.75rem",
          "2xl": "3.75rem",
        }}
        maxWidth="1920px"
        as="footer"
      >
        <Flex
          gap={{ base: "0.5rem", md: "1.25rem" }}
          flexWrap={{ base: "wrap", sm: "nowrap" }}
          justifyContent="space-between"
          alignItems="flex-end"
          rowGap="1.25rem"
        >
          <VStack
            alignItems="flex-start"
            spacing={{
              base: "1rem",
              sm: "1.5rem",
              md: "1.75rem",
              xl: "2rem",
              "2xl": "2.5rem",
            }}
          >
            <Box>
              <Image
                alt="Logo"
                marginBottom={{ base: "0.25rem", md: "0,5rem" }}
                width={{
                  base: "8.375rem",
                  sm: "6.675rem",
                  lg: "8.765rem",
                  xl: "10.5rem",
                }}
                src={logo}
              />
              <Text
                whiteSpace="nowrap"
                fontSize={{
                  base: "0.75rem",
                  sm: "0.5rem",
                  md: "0.7rem",
                  xl: "0.875rem",
                }}
                color="white"
                opacity={0.5}
              >
                &copy; {new Date().getFullYear()} All rights reserved.
              </Text>
            </Box>
            <HStack
              minWidth="fit-content"
              width={{ base: "100%", sm: "auto" }}
              justifyContent={{ base: "space-between", sm: "flex-start" }}
            >
              <Link
                maxWidth="51.25%"
                isExternal
                href="https://play.google.com/store/apps/details?id=com.beleven.chytanka&hl=uk&gl=US"
              >
                <Image
                  alt="Google badge"
                  width={{ base: "100%", sm: "auto" }}
                  height={{
                    base: "auto",
                    sm: "1.75rem",
                    lg: "2rem",
                    xl: "3rem",
                  }}
                  src={googlePlayBadge}
                />
              </Link>
              <Link
                width="46.25%"
                isExternal
                href="https://apps.apple.com/ua/app/%D1%87%D0%B8%D1%82%D0%B0%D0%BD%D0%BA%D0%B0/id1543172038"
              >
                <Image
                  flexShrink={1}
                  width={{ base: "100%", sm: "auto" }}
                  height={{
                    base: "auto",
                    sm: "1.75rem",
                    lg: "2rem",
                    xl: "3rem",
                  }}
                  src={appleStorebadge}
                  alt="App store badge"
                />
              </Link>
            </HStack>
          </VStack>
          <HStack
            alignItems="flex-start"
            minWidth="fit-content"
            width="52%"
            gap="0.75rem"
            justifyContent="space-between"
          >
            <VStack
              spacing={{ base: "0.3rem", md: "0.5rem", lg: "0.75rem" }}
              alignItems="flex-start"
            >
              <NavLink to="/about">
                <Text
                  whiteSpace="nowrap"
                  size={{ base: "xs", md: "sm", xl: "caption" }}
                  textAlign="left"
                  variant="base"
                >
                  Про читанку
                </Text>
              </NavLink>
              <NavLink to="/terms-and-conditions">
                <Text
                  whiteSpace="nowrap"
                  size={{ base: "xs", md: "sm", xl: "caption" }}
                  textAlign="left"
                  variant="base"
                >
                  Умови використання
                </Text>
              </NavLink>
              <NavLink to="https://t.me/app_chytanka" target="_blank">
                <Text
                  whiteSpace="nowrap"
                  size={{ base: "xs", md: "sm", xl: "caption" }}
                  textAlign="left"
                  variant="base"
                >
                  Звʼязатися з нами
                </Text>
              </NavLink>

              {/* <NavLink to="/returns">
                <Text
                  whiteSpace="nowrap"
                  size={{ base: "xs", md: "sm", xl: "caption" }}
                  textAlign="left"
                  variant="base"
                >
                  Повернення
                </Text>
              </NavLink> */}
            </VStack>
            <VStack
              spacing={{ base: "0.3rem", md: "0.5rem", lg: "0.75rem" }}
              alignItems="flex-start"
            >
              <NavLink to="/catalogue">
                <Text
                  whiteSpace="nowrap"
                  size={{ base: "xs", md: "sm", xl: "caption" }}
                  textAlign="left"
                  variant="base"
                >
                  Каталог
                </Text>
              </NavLink>
              <NavLink to="/books/bestsellers">
                <Text
                  whiteSpace="nowrap"
                  size={{ base: "xs", md: "sm", xl: "caption" }}
                  textAlign="left"
                  variant="base"
                >
                  Бестселери
                </Text>
              </NavLink>
              <NavLink to="/books/newcomings">
                <Text
                  whiteSpace="nowrap"
                  size={{ base: "xs", md: "sm", xl: "caption" }}
                  textAlign="left"
                  variant="base"
                >
                  Новинки
                </Text>
              </NavLink>
              <NavLink to="/categories">
                <Text
                  whiteSpace="nowrap"
                  size={{ base: "xs", md: "sm", xl: "caption" }}
                  textAlign="left"
                  variant="base"
                >
                  Категорії
                </Text>
              </NavLink>
              <NavLink to="/publishers">
                <Text
                  whiteSpace="nowrap"
                  size={{ base: "xs", md: "sm", xl: "caption" }}
                  textAlign="left"
                  variant="base"
                >
                  Видавництва
                </Text>
              </NavLink>
            </VStack>
            <VStack
              width={{ base: "40%", lg: "35%" }}
              gap={{
                base: "2rem",
                sm: "1.75rem",
                md: "2.75rem",
                lg: "2.5rem",
                "2xl": "3rem",
              }}
              alignItems="space-between"
            >
              <Box>
                {user !== null ? (
                  <NavLink to="/profile">
                    <Text
                      marginBottom={{
                        base: "0.3rem",
                        md: "0.5rem",
                        lg: "0.75rem",
                      }}
                      whiteSpace="nowrap"
                      size={{ base: "xs", md: "sm", xl: "caption" }}
                      textAlign="left"
                      variant="base"
                    >
                      Мій кабінет
                    </Text>
                  </NavLink>
                ) : (
                  <NavLink to="/login">
                    <Text
                      whiteSpace="nowrap"
                      size={{ base: "xs", md: "sm", xl: "caption" }}
                      marginBottom={{
                        base: "0.3rem",
                        md: "0.5rem",
                        lg: "0.75rem",
                      }}
                      textAlign="left"
                      variant="base"
                    >
                      Реєстрація / Вхід
                    </Text>
                  </NavLink>
                )}
                <Link
                  as="a"
                  isExternal
                  href="https://publisher.chytanka.com/login"
                >
                  <HStack spacing="0.25rem">
                    <Text
                      whiteSpace="nowrap"
                      size={{ base: "xs", md: "sm", xl: "caption" }}
                      textAlign="left"
                      variant="base"
                    >
                      Кабінет видавця
                    </Text>
                    <ExternalLinkIcon fill="white" width="0.875rem" />
                  </HStack>
                </Link>
              </Box>

              <InputGroup
                minWidth="8rem"
                width="100%"
                position="relative"
                borderRadius={{
                  base: "0.5rem",
                  sm: "0.25rem",
                  md: "0.35rem",
                  xl: "0.5rem",
                }}
              >
                <Input
                  outline="none !important"
                  _focus={{
                    border: "0.0625rem solid black",
                    outline: "none !important",
                    boxShadow: "none",
                  }}
                  spellCheck={false}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  size={{ base: "mobile", sm: "xs", md: "sm", xl: "md" }}
                  background="white"
                  placeholder="Пошук книг"
                  borderRadius="inherit !important"
                />
                <InputRightElement
                  display="flex"
                  justifyContent="flex-end"
                  right={{ base: "0.3rem", sm: "0.25rem" }}
                  top="50%"
                  transform="translateY(-50%)"
                  h={{
                    base: "2.5rem",
                    sm: "1.75rem",
                    md: "1.85rem",
                    xl: "2.5rem",
                  }}
                  w={{ base: "13.55%", sm: "18.75%" }}
                  maxW={{ base: "13.55%", sm: "18.75%" }}
                >
                  <Button
                    onClick={searchBooks}
                    _hover={{ backgroundColor: "orange" }}
                    _active={{ filter: "brightness(150%)" }}
                    background="black"
                    minWidth="100%"
                    maxWidth="100% !important"
                    maxH={{
                      base: "2.5rem",
                      sm: "1.75rem",
                      md: "2rem",
                      xl: "2.5rem",
                    }}
                    height="100%"
                    borderRadius={{
                      base: "0.5rem",
                      sm: "0.35rem",
                      xl: "0.5rem",
                    }}
                  >
                    <Center>
                      <Flex
                        width={{
                          base: "1rem",
                          sm: "0.75rem",
                          lg: "0.875rem",
                          "2xl": "1.25rem",
                        }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <SearchIcon fill="white" />
                      </Flex>
                    </Center>
                  </Button>
                </InputRightElement>
              </InputGroup>
            </VStack>
          </HStack>

          <AspectRatio
            flexShrink={0}
            order={{ base: 2, sm: 5 }}
            width={{
              base: "2.5rem",
              sm: "1.75rem",
              md: "2rem",
              xl: "3rem",
            }}
            ratio={1}
          >
            <Button
              onClick={() =>
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
              }
              maxWidth="100%"
              minWidth="100%"
              _hover={{ backgroundColor: "orange" }}
              padding="0rem !important"
              borderRadius="8%"
              borderColor="white"
              borderWidth={{
                base: "0.125rem",
                sm: "0.075rem",
                "2xl": "0.125rem",
              }}
              bg="black"
            >
              <Center>
                <ArrowIcon
                  height={{
                    base: "1.125rem",
                    sm: "0.7rem",
                    md: "0.73rem",
                    lg: "0.91rem",
                    xl: "1.375rem",
                  }}
                />
              </Center>
            </Button>
          </AspectRatio>
        </Flex>
      </Container>
    </Container>
  );
};
