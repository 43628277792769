import { useQuery } from "react-query";
import {
  BooksResponseType,
  CategoriesResponseType,
  PublisherResponseType,
} from "./types";
import api from "../../../services/api/api";

export const useBooks = (type: string, page: number, limit: number, options?:{ suspense: boolean }) => {
  return useQuery(
    [type, page, limit],
    () => {
      return api.get<string, BooksResponseType>(
        `/${type}?page=${page}&limit=${limit}`
      );
    },
    {
      refetchOnWindowFocus: false,
      suspense: options?.suspense || false,
      staleTime: Infinity
    }
  );
};

export const useCategories = () => {
  return useQuery(
    ["categories"],
    () => {
      return api.get<string, CategoriesResponseType>("/categories");
    },
    {
      refetchOnWindowFocus: false,
      suspense: true,
      staleTime: Infinity
    }
  );
};

export const usePublishers = (fetchAll?: boolean) => {
  return useQuery(
    ["publishers", fetchAll],
    () => {
      return api.get<string, PublisherResponseType>(`/publishers${fetchAll !== undefined ?`?fetchAll=${fetchAll}`:""}`);
    },
    {
      refetchOnWindowFocus: false,
      suspense: !fetchAll,
      staleTime: Infinity
    }
  );
};
