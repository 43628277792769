import { IconButton, useBreakpointValue } from "@chakra-ui/react";
import { ArrowIcon } from "../../../icons/ArrowIcon";
interface RightButtonProps {
  handleClick: () => void;
  isCentered: boolean;
}
export const RightButton: React.FC<RightButtonProps> = ({
  handleClick,
  isCentered,
}) => {
  const topOffset = useBreakpointValue({
    base: "auto",
    sm: "1.5rem",
    md: "2.5rem",
    xl: "3.125rem",
  });

  return (
    <IconButton
      bg="black"
      _hover={{ backgroundColor: "orange" }}
      _active={{ opacity: "0.8" }}
      minWidth={{ base: "2rem", sm: "1.75rem", xl: "2.5rem" }}
      height={{ base: "2rem", sm: "1.75rem", xl: "2.5rem" }}
      width={{ base: "2rem", sm: "1.75rem", xl: "2.5rem" }}
      borderRadius="50%"
      onClick={handleClick}
      position="absolute"
      top={isCentered ? "50%" : `calc(50% - ${topOffset})`}
      transform="translateY(-50%)"
      right={{
        base: "-0.725rem",
        sm: "-0.625rem",
        md: "-0.75rem",
        xl: "-1rem",
      }}
      aria-label={""}
    >
      <ArrowIcon
        transform="rotate(90deg)"
        width={{
          base: "1rem",
          sm: "0.875rem",
          xl: "1.25rem",
        }}
      />
    </IconButton>
  );
};
