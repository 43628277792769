interface SearchIconProps {
  width?: number;
  height?: number;
  fill?: string
}

export const SearchIcon: React.FC<SearchIconProps> = (props) => {
  return (
    <svg
      width="100%"
      height="100%"
      {...props}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.01999 11.5005C3.17393 12.6561 4.71063 13.3498 6.34057 13.451C7.97051 13.5521 9.58118 13.0537 10.8692 12.0497L15.3008 16.4813C15.458 16.6331 15.6685 16.7171 15.887 16.7152C16.1055 16.7133 16.3145 16.6257 16.469 16.4712C16.6235 16.3167 16.7111 16.1077 16.713 15.8892C16.7149 15.6707 16.631 15.4602 16.4792 15.303L12.0475 10.8713C13.0961 9.52565 13.5918 7.83064 13.4336 6.13199C13.2755 4.43334 12.4753 2.85901 11.1962 1.73007C9.91719 0.601125 8.25568 0.00260818 6.55054 0.0565796C4.8454 0.110551 3.22506 0.812945 2.01999 2.02051C1.39732 2.64287 0.903372 3.3818 0.566369 4.19511C0.229365 5.00842 0.0559082 5.88015 0.0559082 6.76051C0.0559082 7.64088 0.229365 8.51261 0.566369 9.32592C0.903372 10.1392 1.39732 10.8782 2.01999 11.5005ZM3.19832 3.20051C4.02213 2.37672 5.10609 1.86405 6.26551 1.74985C7.42493 1.63564 8.58808 1.92697 9.55679 2.5742C10.5255 3.22143 11.2398 4.18451 11.5781 5.29936C11.9163 6.41421 11.8576 7.61185 11.4118 8.68823C10.966 9.76461 10.1608 10.6531 9.13343 11.2024C8.10601 11.7517 6.91993 11.9278 5.77725 11.7006C4.63458 11.4734 3.60602 10.8571 2.86682 9.95663C2.12761 9.05614 1.7235 7.92721 1.72332 6.76218C1.72103 6.10016 1.85024 5.44427 2.10344 4.83258C2.35665 4.22089 2.7288 3.66557 3.19832 3.19885V3.20051Z"
        fill={props.fill}
      />
    </svg>
  );
};
