import { FunctionComponent } from "react";

interface ContentIconProps {
  width?: string | number;
}

const ContentIcon: FunctionComponent<ContentIconProps> = ({ width }) => {
  return (
    <svg
      width={width ?? "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#000100" />
      <path
        d="M9.54124 13.1665C10.5579 13.1565 11.5745 13.037 12.5912 13.0419C12.6831 13.0419 14.0999 13.0469 15.149 13.0419C16.5604 13.032 16.6956 13.0071 17.7501 13.0021C19.5941 12.9871 20.0159 13.0569 22.2817 13.0868C23.8499 13.1067 24.6341 13.1167 25.3749 13.0868C27.3487 13.0071 29.3225 13.2213 31.2963 13.2114C31.4315 13.2114 31.81 13.2064 31.956 13.4156C32.0155 13.5004 32.0101 13.5801 31.9669 14.0784C31.9236 14.5966 31.9182 14.6364 31.8749 14.6962C31.6586 15.0052 31.0421 14.9703 30.8853 14.9653C27.9598 14.8258 25.018 15.0948 22.0924 14.9454C18.664 14.771 14.711 14.9005 13.2076 14.9852C12.6561 15.0151 12.0991 15.0101 11.5475 15.0251C9.48176 15.0948 8.33533 15.1995 8.09198 14.7361C7.99465 14.5467 8.00005 14.183 8.00005 14.0734C8.00546 13.884 8.01087 13.6797 8.09198 13.5352C8.3137 13.1715 9.05455 13.1715 9.54124 13.1665Z"
        fill="white"
      />
      <path
        d="M9.42734 19.2194C10.9401 19.1321 12.1827 19.1903 12.5986 19.2048C12.7391 19.2121 12.6419 19.2048 15.0784 19.1466C16.683 19.1102 17.4826 19.0884 17.6771 19.0884C19.5518 19.0739 20.0002 19.1685 22.2044 19.2121C23.7712 19.2412 24.5545 19.2558 25.2947 19.2121C27.2666 19.0957 29.2386 19.4086 31.2105 19.394C31.3456 19.394 31.7238 19.3867 31.8696 19.6923C31.8804 19.7141 31.8858 19.7214 31.8912 19.7505C32.0155 20.0852 32.0047 20.4417 31.9939 20.66C31.9669 21.2348 31.9507 21.5258 31.8804 21.664C31.6427 22.1443 31.0052 22.1006 30.8269 22.0933C27.8447 21.9187 24.8571 22.3625 21.8749 22.1661C19.2114 21.9915 15.381 21.9987 13.1929 22.1734C12.5446 22.2243 11.8909 22.1879 11.2426 22.1952C9.40573 22.2316 8.21716 22.2752 8.02807 21.6204C7.99566 21.504 7.99566 21.2566 8.00646 20.7546C8.01186 20.2525 8.01727 19.9979 8.0767 19.8306C8.28199 19.2921 8.99513 19.2412 9.42734 19.2194Z"
        fill="white"
      />
      <path
        d="M9.48307 26.3136C10.2965 26.2541 10.8082 26.2541 10.8082 26.2541C11.7886 26.2541 12.505 26.2633 12.7528 26.2633C13.7601 26.2724 13.8678 26.2404 15.1768 26.222C15.8932 26.2129 16.5127 26.2175 17.7139 26.2312C19.5292 26.2495 21.059 26.2816 22.2279 26.309C25.573 26.3869 25.1529 26.4235 25.5515 26.4006C25.5515 26.4006 28.6165 26.2495 31.0405 26.3319C31.1913 26.3365 31.6922 26.3594 31.87 26.6112C31.8808 26.625 31.8862 26.6341 31.8916 26.6479C32.0154 26.8585 32.0047 27.0828 31.9939 27.2202C31.967 27.5727 31.9508 27.7513 31.8808 27.852C31.6438 28.2 30.9704 28.2183 30.728 28.2229C23.4345 28.3694 21.7 28.2733 21.7 28.2733C18.6781 28.1084 15.0744 28.1084 13.2483 28.1725C12.6019 28.1954 11.9502 28.168 11.3038 28.1863C9.43459 28.2458 8.38958 28.406 8.0987 27.9757C7.97481 27.7971 7.99635 27.527 8.01251 27.2797C8.03406 27.0004 8.05022 26.7852 8.21182 26.6112C8.37342 26.4464 8.61043 26.3823 9.48307 26.3136Z"
        fill="white"
      />
    </svg>
  );
};

export default ContentIcon;
