import { ListItem, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";

interface NavigationItemProps {
  title: string;
  isActive: boolean;
  index: number;
  handleClick(index: number): void;
}

const NavigationItem: FunctionComponent<NavigationItemProps> = ({
  title,
  isActive,
  handleClick,
  index,
}) => {
  return (
    <ListItem
      onClick={() => handleClick(index)}
      cursor="pointer"
      fontFamily="Commissioner"
      fontWeight={600}
      fontSize={{
        base: "1rem",
        sm: "0.75rem",
        md: "1rem",
        xl: "1.25rem",
      }}
      color={isActive ? "#FF5500" : "black"}
      as="h2"
    >
      {title}
    </ListItem>
  );
};

export default NavigationItem;
