import {
  Button,
  Flex,
  Image,
  Stack,
  Text,
  forwardRef,
  useBreakpointValue,
} from "@chakra-ui/react";
import background from "../../../../assets/Authors-landing/tab-8-bg-2.png";
import element1 from "../../../../assets/Authors-landing/tab-8-element-1.png";
import element2 from "../../../../assets/Authors-landing/tab-8-element-2.png";

import { LandingSlide } from "../../../types";
import { backgroundAnimation, elementAnimation } from "../../utils/animations";
import DiscoverMoreIcon from "../../../../ui/icons/DiscoverMoreIcon";

interface CommissionSectionProps extends LandingSlide {}

const CommissionSection = forwardRef<CommissionSectionProps, "div">(
  ({ nextStep }, ref) => {
    const buttonIconWidth = useBreakpointValue({
      base: "1rem",
      lg: "1.5rem",
      "2xl": "2.5rem",
    });

    return (
      <Stack
        ref={ref}
        width="100%"
        flexShrink={0}
        flexGrow={1}
        height="100%"
        flex="0 0 auto"
        maxHeight="100%"
        justifyContent={{ base: "flex-start", lg: "flex-end" }}
        alignItems="flex-end"
        position="relative"
        gap={{ base: "1.25rem", lg: "3rem" }}
      >
        <Flex
          height={{ base: "100%", lg: "fit-content" }}
          flex="0 0 auto"
          alignItems="center"
          maxHeight="100%"
          justifyContent="space-between"
          width="100%"
          pl={{ base: "auto", lg: "2rem" }}
          maxWidth={{ base: "100%", lg: 1320, "2xl": 1730 }}
          pb={{ base: "0", lg: "2.75rem" }}
          zIndex={2}
          flexDirection={{ base: "column", lg: "row" }}
          gap={{ base: "1.25rem", lg: "4rem", xl: "6.25rem" }}
          px={{ base: "1.25rem", lg: 0 }}
        >
          <Stack
            order={{ base: 2, lg: 1 }}
            width={{ base: "100%", lg: "fit-content" }}
            gap={{ base: "1.25rem", lg: "3rem" }}
          >
            <Stack
              gap={{ base: "0.75rem", lg: "1.25rem" }}
              maxWidth={{ base: "100%", lg: 680, "2xl": 807 }}
            >
              <Text
                fontFamily="OddvalText"
                fontWeight="600"
                color="#000100"
                lineHeight={{ base: "2rem", lg: "3rem", "2xl": "4rem" }}
                fontSize={{ base: "1.75rem", lg: "2.5rem", "2xl": "3.75rem" }}
                fontStyle="normal"
                maxW={700}
              >
                Про комісію від продажів
              </Text>

              <Stack
                fontFamily="Work Sans"
                letterSpacing={{ base: "-0.8px", "2xl": "-1px" }}
                mt="0 !important"
                whiteSpace="pre-wrap"
                lineHeight={{ base: "1.5rem", "2xl": "1.75rem" }}
                fontSize={{ base: "1rem", lg: "1.25rem" }}
                gap={{ base: "1rem", "2xl": "1.75rem" }}
                maxWidth={668}
              >
                <Text fontSize="inherit" lineHeight="inherit">
                  Перейди на платформу Читанка та зареєструйся, щоб опублікувати
                  свою книжку. Опис, деталі, подробиці. Договір нам потрібен,
                  тому що... опис, деталі, подробиці
                </Text>
                <Text fontSize="inherit" lineHeight="inherit" mt="0 !important">
                  Перейди на платформу Читанка та зареєструйся, щоб опублікувати
                  свою книжку. Опис, деталі, подробиці. Договір нам потрібен,
                  тому що... опис, деталі, подробиці
                </Text>
              </Stack>
            </Stack>
            <Button
              py={{ base: "1.5rem", "2xl": "2.375rem" }}
              mt="0 !important"
              boxShadow="0.35rem 0.35rem black"
              variant="transparent"
              letterSpacing="-1.2px"
              fontSize={{ lg: "1.25rem", "2xl": "1.5rem" }}
              width={{ base: "auto", lg: "20rem", "2xl": "24rem" }}
              iconSpacing="0"
              gap={{ base: "0.75rem", lg: "1rem", "2xl": "1.5rem" }}
              rightIcon={<DiscoverMoreIcon width={buttonIconWidth} />}
              onClick={nextStep}
            >
              Розпочати
            </Button>
          </Stack>
          <Flex
            justifyContent="flex-end"
            position="relative"
            height="100%"
            width="100%"
            flexGrow={1}
            flexBasis="100%"
            alignSelf="flex-start"
            flexShrink={{ base: "auto", lg: 0 }}
            maxWidth={{ base: "100%", lg: 598, "2xl": 740 }}
            order={{ base: 1, lg: 2 }}
          >
            <Image
              position="absolute"
              left={{ base: "7.5%", md:'1%', lg: "0" }}
              top={{ base: "1rem", lg: "auto" }}
              bottom={{ base: "0", lg: "auto" }}
              height={{ base: "90%", lg: "100%" }}
              maxHeight="100%"
              objectFit="contain"
              animation={backgroundAnimation(20)}
              zIndex={2}
              src={element2}
            />
            <Image
              position="absolute"
              right={{ base: "0", lg: "-10%" }}
              bottom={{ base: "1rem", lg: "auto" }}
              height={{ base: "auto", lg: "100%" }}
              width={{ base: "80%", md:'100%', lg: "auto" }}
              src={background}
              animation={backgroundAnimation(20)}
            />
            <Image
              bottom={{ base: "0", lg: "auto" }}
              maxHeight="100%"
              zIndex={2}
              left="30%"
              src={element1}
              animation={elementAnimation}
              height={{ base: "95%", lg: "100%" }}
              position="absolute"
              top={{ base: "1.5rem", lg: "auto" }}
            />
          </Flex>
        </Flex>

        <Flex
          display={{ base: "none", lg: "flex" }}
          position="absolute"
          left="0"
          bottom="0"
          backgroundColor="main"
          height="3.75rem"
          width="80%"
          alignSelf="flex-end"
          alignItems="center"
          mt="0 !important"
        />
      </Stack>
    );
  }
);

export default CommissionSection;
