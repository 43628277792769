import { FunctionComponent } from "react";

interface QuestionMarkIconProps {
  height?: string | number;
}

const QuestionMarkIcon: FunctionComponent<QuestionMarkIconProps> = ({
  height,
}) => {
  return (
    <svg
      height={height ?? "40"}
      viewBox="0 0 22 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4165 7.77067C21.645 9.88837 21.2814 12.3287 16.4192 22.6061C15.8356 23.8341 14.9715 25.6186 13.4589 25.8538C13.3262 25.8735 12.1247 26.0433 11.3483 25.1634C10.6191 24.338 10.8507 23.2381 10.889 23.1095C11.0886 22.6641 11.3224 22.1481 11.595 21.5984C11.7474 21.3028 11.9848 20.8381 12.2865 20.2905C12.722 19.5043 13.0838 18.9317 13.1502 18.8197C15.3668 15.1543 16.8635 12.6852 16.4242 9.63312C16.1615 7.79025 15.2929 4.62001 12.9983 3.95304C10.4251 3.20056 7.55491 6.00224 7.24073 6.3155C6.48689 7.06441 5.90575 7.94967 5.90575 7.94967C5.26708 8.92568 3.7959 11.5498 3.37537 11.6147C3.2504 11.6277 3.11869 11.6329 3.11869 11.6329C3.11869 11.6329 2.94342 11.635 2.77022 11.608C1.83941 11.4615 0.731076 10.3245 0.731076 10.3245C0.526734 10.1202 0.227997 9.80705 0.111806 9.59447C-0.694791 8.11416 2.94136 2.75487 8.48377 0.851361C9.60583 0.464331 14.0708 -1.06356 17.5828 1.25891C19.527 2.54301 20.5344 4.58487 20.6169 4.75857C20.8996 5.39893 21.2622 6.35143 21.4165 7.77067Z"
        fill="#000100"
      />
      <path
        d="M17.4308 35.6206C17.1678 37.9909 14.62 40.4267 12.0066 39.9363C10.4122 39.642 9.22874 38.3506 8.75206 37.0101C8.20965 35.5225 8.42333 33.6589 9.63966 32.3675C10.9711 30.978 12.7791 30.9943 13.2887 31.027C13.6338 31.0434 15.5241 31.1578 16.6747 32.7435C16.7733 32.907 17.5951 34.084 17.4308 35.6206Z"
        fill="#000100"
      />
    </svg>
  );
};

export default QuestionMarkIcon;
