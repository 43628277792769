import { Box, Flex, ResponsiveValue } from "@chakra-ui/react";
import { LeftButton } from "../LeftButton/LeftButton";
import { RightButton } from "../RightButton/RightButton";
import { useState, useEffect, useRef } from "react";
import React from "react";
interface CarouselProps {
  children: React.ReactElement[] | undefined;
  gap: string | ResponsiveValue<string>;
  hasButtons: boolean;
  childrenType?: "categories" | "books";
}

export const Carousel: React.FC<CarouselProps> = ({
  children = [],
  gap,
  hasButtons,
  childrenType = "books",
}) => {
  const [showPrevButton, setShowPrevButton] = useState<boolean>(false);
  const [showNextButton, setShowNextButton] = useState<boolean>(true);

  const sliderRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    handleScroll();
  }, []);

  const isSnapNeeded = () => {
    return (
      childrenType !== 'categories' &&
      sliderRef.current &&
      itemsRef.current?.length &&
      sliderRef?.current?.scrollWidth - sliderRef?.current?.offsetWidth >
        itemsRef?.current[0]?.offsetWidth
    );
  };

  const slideForward = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += itemsRef.current[0].offsetWidth;
    }
  };

  const slideBack = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= itemsRef.current[0].offsetWidth;
    }
  };

  const handleScroll = () => {
    if (sliderRef.current) {
      if (
        showNextButton &&
        sliderRef.current?.scrollLeft + sliderRef.current.offsetWidth >=
          sliderRef.current.scrollWidth - 5
      ) {
        setShowNextButton(false);
      } else if (
        sliderRef.current?.scrollLeft + sliderRef.current.offsetWidth <
        sliderRef.current.scrollWidth - 5
      ) {
        !showNextButton && setShowNextButton(true);
      }
      if (sliderRef.current.scrollLeft < 10 && showPrevButton) {
        setShowPrevButton(false);
      } else if (sliderRef.current.scrollLeft >= 10) {
        !showPrevButton && setShowPrevButton(true);
      }
    }
  };

  return (
    <Flex overflow="visible" width="100%" direction="column">
      <Flex overflow="visible" width="100%" position="relative">
        <Box
          width="100%"
          height="100%"
          overflowY={"visible"}
          marginLeft="-0.5rem"
        >
          <Flex
            scrollSnapType={{
              base: undefined,
              sm: isSnapNeeded() ? "x mandatory" : "none",
            }}
            marginTop="-2rem"
            scrollBehavior="smooth"
            scrollPadding="0.5rem"
            paddingTop="2rem"
            onScroll={handleScroll}
            paddingLeft="0.5rem"
            marginRight="-0.5rem"
            gap={gap}
            ref={sliderRef}
            overflowY="clip"
            overflowX="scroll"
            css={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            width={`auto`}
            sx={{ scrollbarWidth: "none", transition: "all 0.2s ease" }}
          >
            {React.Children.map(children, (child, index) =>
              React.cloneElement(child, {
                ref: (ref: any) => (itemsRef.current[index] = ref),
              })
            )}
          </Flex>
          {hasButtons && showPrevButton && (
            <LeftButton
              isCentered={childrenType !== "books"}
              handleClick={slideBack}
            />
          )}
          {hasButtons && showNextButton && (
            <RightButton
              isCentered={childrenType !== "books"}
              handleClick={slideForward}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
