import { Flex, Image, Text, StyleProps } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import imageCover from '../../../assets/Franko.svg';
interface IllustrationProps extends StyleProps {}

const Illustration: FunctionComponent<IllustrationProps> = (props) => {
  return (
    <Flex
      {...props}
      order={{ base: 2, lg: 1 }}
      px={{
        base: "1.25rem",
        sm: "2rem",
        md: "2.5rem",
        xl: "4rem",
        "2xl": "5.5rem",
      }}
      paddingTop={{
        base: "1.25rem",
        sm: "2rem",
        md: "2.5rem",
        xl: "4rem",
        "2xl": "5rem",
      }}
      paddingBottom={{
        base: "1.25rem",
        sm: "1.5rem",
        md: "1.75rem",
        xl: "2rem",
        "2xl": "2.5rem",
      }}
      flexDirection={{ base: "column", sm: "row", lg: "column" }}
      alignItems="center"
      width={{ base: "100", lg: "43.15%" }}
      backgroundColor="rgba(255, 85, 0, 0.1);"
      borderRadius={{ base: "1rem", lg: "2rem" }}
    >
      <Image
        width={{ base: "100%", sm: "50%", lg: "90.25%" }}
        src={imageCover}
        alt='Illustration'
      />
      <Flex px={{ base: "1rem", sm: "0rem" }} flexDirection="column">
        <Text
          marginTop={{
            base: "1.25rem",
            sm: "1rem",
            md: "1.25rem",
            xl: "1.625rem",
          }}
          marginBottom={{ base: "1rem", sm: "0.75rem", xl: "1rem" }}
          textAlign={{ base: "left", sm: "center" }}
          fontWeight={850}
          fontSize={{
            base: "1rem",
            sm: "0.75rem",
            md: "1rem",
            xl: "1.25rem",
          }}
          lineHeight={{ base: "1.5rem", md: "1.8rem" }}
          fontFamily="GaretHeavy"
          as='h2'
        >
          «Книги — морська глибина: хто в них пірне аж до дна, Той, хоч і труду
          мав досить, дивнії перли виносить»
        </Text>
        <Text
          alignSelf={{ base: "flex-start", sm: "flex-end", lg: "flex-start" }}
          size={{ base: "caption", sm: "xs", md: "sm", xl: "md" }}
        >
          Іван Франко
        </Text>
      </Flex>
    </Flex>
  );
};

export default Illustration;
