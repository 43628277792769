import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  forwardRef,
  useBreakpointValue,
} from "@chakra-ui/react";
import { backgroundAnimation, elementAnimation } from "../../utils/animations";
import element from "../../../../assets/Authors-landing/tab-9-element.png";
import background from "../../../../assets/Authors-landing/tab-9-bg.png";
import DiscoverMoreIcon from "../../../../ui/icons/DiscoverMoreIcon";
import { useNavigate } from "react-router-dom";
import InstagramIcon from "../../../../ui/icons/InstagramIcon";

interface AboutSectionProps {}

const AboutSection = forwardRef<AboutSectionProps, "div">(({}, ref) => {
  const buttonIconWidth = useBreakpointValue({
    base: "1rem",
    lg: "1.5rem",
    "2xl": "2.5rem",
  });

  const navigate = useNavigate();

  return (
    <Flex
      ref={ref}
      width="100%"
      flexShrink={0}
      flexGrow={1}
      justifyContent="center"
    >
      <Flex
        height="100%"
        flex="0 0 auto"
        alignItems="center"
        maxHeight="100%"
        justifyContent="flex-start"
        gap={{ base: "1.25rem", lg: "3.5rem", "2xl": "5.75rem" }}
        width="100%"
        pl={{ base: "1.25rem", lg: "2rem" }}
        pr={{ base: "1.25rem", lg: 0 }}
        maxWidth={{ base: "100%", lg: 1320, "2xl": 1670 }}
        flexDir={{ base: "column", lg: "row" }}
      >
        <Flex
          justifyContent="flex-end"
          position="relative"
          alignSelf="flex-start"
          flexGrow={1}
          flexShrink={{ base: "auto", lg: 0 }}
          width="100%"
          height="100%"
          maxWidth={{ base: "100%", lg: 397, "2xl": 535 }}
        >
          <Image
            position="absolute"
            left={{ base: "15%", lg: "1.5%" }}
            bottom="0"
            animation={backgroundAnimation(20)}
            width={{ base: "auto", lg: "100%" }}
            maxHeight="100%"
            src={background}
          />
          <Image
            animation={elementAnimation}
            top={{ base: "1rem", lg: "1.5rem" }}
            height={{ base: "100%", lg: "89.5%" }}
            left={{ base: "40%", lg: "27%" }}
            zIndex={2}
            position="absolute"
            src={element}
          />
        </Flex>
        <Stack width="fit-content" gap={{ base: "1.25rem", lg: "3rem" }}>
          <Stack gap="1.25rem" maxWidth={{ base: 680, "2xl": 807 }}>
            <Stack gap="2rem">
              <Text
                mt="0 !important"
                fontFamily="OddvalText"
                fontWeight="600"
                color="#000100"
                lineHeight={{ base: "2rem", lg: "3rem", "2xl": "4rem" }}
                fontSize={{ base: "1.75rem", lg: "2.5rem", "2xl": "3.75rem" }}
                fontStyle="normal"
                maxWidth={668}
              >
                Про Читанку
              </Text>
            </Stack>

            <Text
              mt="0 !important"
              whiteSpace="pre-wrap"
              fontFamily="Work Sans, sans-serif"
              letterSpacing={{ base: "-0.8px", "2xl": "-1px" }}
              lineHeight={{ base: "1.5rem", "2xl": "1.75rem" }}
              fontSize={{ base: "1rem", lg: "1.25rem" }}
            >
              Перейди на платформу Читанка та зареєструйся, щоб опублікувати
              свою книжку. Опис, деталі, подробиці. Договір нам потрібен, тому
              що... опис, деталі, подробиці. Перейди на платформу Читанка та
              зареєструйся, щоб опублікувати свою книжку. Опис, деталі,
              подробиці. Договір нам потрібен, тому що... опис, деталі,
              подробиці. Перейди на платформу Читанка та зареєструйся, щоб
              опублікувати свою книжку.
            </Text>
          </Stack>
          <Flex mt="0 !important" gap="1.25rem" alignItems="center">
            <Button
              py={{ base: "1.5rem", "2xl": "2.375rem" }}
              mt="0 !important"
              boxShadow="0.35rem 0.35rem black"
              variant="transparent"
              letterSpacing="-1.2px"
              fontSize={{ lg: "1.25rem", "2xl": "1.5rem" }}
              width={{ base: "100%", lg: "20rem", "2xl": "24rem" }}
              iconSpacing="0"
              gap={{ base: "0.75rem", lg: "1rem", "2xl": "1.5rem" }}
              rightIcon={<DiscoverMoreIcon width={buttonIconWidth} />}
              onClick={() => navigate("/")}
            >
              Наш сайт
            </Button>
            <Button
              padding={{ base: "1.5rem", lg: "1.35rem", "2xl": "2.375rem" }}
              as="a"
              minW="fit-content"
              target="_blank"
              href="https://www.instagram.com/app.chytanka/"
              mt="0 !important"
              boxShadow="0.35rem 0.35rem black"
              variant="transparent"
              letterSpacing="-1.2px"
              fontSize={{ lg: "1.25rem", "2xl": "1.5rem" }}
              iconSpacing="0"
              gap={{ base: "0.75rem", lg: "1rem", "2xl": "1.5rem" }}
              rightIcon={<InstagramIcon width={buttonIconWidth} />}
            ></Button>
          </Flex>
        </Stack>
      </Flex>
    </Flex>
  );
});

export default AboutSection;
