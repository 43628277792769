import {
  Image,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerOverlay,
  Text,
  DrawerContent,
  Flex,
  Box,
  HStack,
  List,
  ListItem,
  VStack,
  Link,
  Stack,
} from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { SearchIcon } from "../../icons/SearchIcon";
import { CatalogueIcon } from "../../icons/CatalogueIcon";
import { useState, useEffect } from "react";
import googlePlayBadge from "../../../assets/google-play-badge.png";
import appleStorebadge from "../../../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import AboutIcon from "../../icons/AboutIcon";
import SearchPanel from "../../../features/SearchPanel/components/SearchPanel";
import UserIcon from "../../icons/UserIcon";
import useActualUser from "../../../utilities/useActualUser";
import LogoutIcon from "../../icons/LogoutIcon";
import { signOut } from "firebase/auth";
import { auth } from "../../../utilities/firebase";
import { ExternalLinkIcon } from "../../icons/ExternalLinkIcon";

interface HamburgerMenuProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ isOpen, onClose }) => {
  const [displaySearchPanel, setDisplaySearchPanel] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    onClose(false);
  }, [location]);

  const { user } = useActualUser();
  const isLoggedIn = user !== null && user?.emailVerified != false;
  return (
    <>
      <SearchPanel
        isOpen={displaySearchPanel}
        onClose={setDisplaySearchPanel}
      />
      <Drawer
        isOpen={isOpen}
        onClose={() => {
          setDisplaySearchPanel(false);
          onClose(false);
        }}
        placement="right"
        size="xs"
        blockScrollOnMount={true}
      >
        <DrawerOverlay />
        <DrawerBody>
          <DrawerContent minWidth="76%" width="fit-content !important">
            <DrawerCloseButton size="lg" />
            <Flex
              width="fit-content"
              height="100%"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              padding="2.5rem"
            >
              <List paddingTop="3.75rem">
                <Stack
                  height="100%"
                  flexDirection="column"
                  justifyContent="center"
                  gap="3rem"
                >
                  <ListItem>
                    <NavLink to="/catalogue">
                      {({ isActive, isPending }) => (
                        <HStack spacing="0.875rem">
                          <Box
                            fill={isActive ? "#FF5500" : "black"}
                            width="1.25rem"
                          >
                            <CatalogueIcon />
                          </Box>
                          <Text
                            fontSize="1.25rem"
                            variant={isActive ? "link" : "primary"}
                          >
                            Каталог
                          </Text>
                        </HStack>
                      )}
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <NavLink to="/about">
                      {({ isActive, isPending }) => (
                        <HStack spacing="0.875rem">
                          <Box width="1.25rem">
                            <AboutIcon fill={isActive ? "#FF5500" : "none"} />
                          </Box>
                          <Text
                            fontSize="1.25rem"
                            variant={isActive ? "link" : "primary"}
                          >
                            Про читанку
                          </Text>
                        </HStack>
                      )}
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    <HStack
                      onClick={() => {
                        setDisplaySearchPanel(true);
                        onClose(false);
                      }}
                      spacing="0.875rem"
                    >
                      <Box width="1.25rem">
                        <SearchIcon fill="black" />
                      </Box>
                      <Text fontSize="1.25rem" variant="primary">
                        Пошук книг
                      </Text>
                    </HStack>
                  </ListItem>

                  <ListItem>
                    {!isLoggedIn ? (
                      <NavLink to="/login">
                        <HStack spacing="0.875rem">
                          <Box width="1.25rem">
                            <UserIcon width="100%" />
                          </Box>
                          <Text fontSize="1.25rem" variant="primary">
                            Реєстрація / Вхід
                          </Text>
                        </HStack>
                      </NavLink>
                    ) : (
                      <NavLink to="profile">
                        {({ isActive, isPending }) => (
                          <HStack spacing="0.875rem" alignItems="center">
                            <UserIcon
                              fill={isActive ? "#FF5500" : "black"}
                              width="1.375rem"
                            />
                            <Text
                              color={isActive ? "#FF5500" : "black"}
                              fontSize="1.25rem"
                            >
                              Мій кабінет
                            </Text>
                          </HStack>
                        )}
                      </NavLink>
                    )}
                  </ListItem>
                  <ListItem>
                    <HStack
                      onClick={() => signOut(auth)}
                      cursor="pointer"
                      alignItems="center"
                      spacing="0.875rem"
                    >
                      <LogoutIcon width="1.25rem" />
                      <Text fontSize="1.25rem">Вийти</Text>
                    </HStack>
                  </ListItem>
                </Stack>
              </List>
              <VStack spacing="0.75rem">
                <Text marginBottom="0.25rem" size="md" variant="primary">
                  Читайте в додатку
                </Text>
                <Link
                  as="a"
                  isExternal
                  href="https://play.google.com/store/apps/details?id=com.beleven.chytanka&hl=uk&gl=US"
                >
                  {" "}
                  <Image
                    alt="Google badge"
                    width="10.125rem"
                    src={googlePlayBadge}
                  />
                </Link>

                <Link
                  as="a"
                  isExternal
                  href="https://apps.apple.com/ua/app/%D1%87%D0%B8%D1%82%D0%B0%D0%BD%D0%BA%D0%B0/id1543172038"
                >
                  <Image
                    alt="App store badge"
                    width="10.125rem"
                    src={appleStorebadge}
                  />
                </Link>
              </VStack>
              <Box>
                <Link
                  as="a"
                  isExternal
                  href="https://publisher.chytanka.com/login"
                >
                  <HStack spacing="0.25rem">
                    <Text fontSize="1.25rem" variant="primary">
                      Кабінет видавця
                    </Text>
                    <ExternalLinkIcon width="0.875rem" />
                  </HStack>
                </Link>
              </Box>
            </Flex>
          </DrawerContent>
        </DrawerBody>
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
