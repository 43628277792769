import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  forwardRef,
  useBreakpointValue,
} from "@chakra-ui/react";
import { backgroundAnimation, elementAnimation } from "../../utils/animations";
import leftElement from "../../../../assets/Authors-landing/tab-2-bg.png";
import rightBackground from "../../../../assets/Authors-landing/tab-2-bg-2.png";
import rightElement from "../../../../assets/Authors-landing/tab-2-element.png";

import ThinkingEmojiIcon from "../../../../ui/icons/MonocleIcon";
import { LandingSlide } from "../../../types";

interface IntroductionSectionProps extends LandingSlide {}

const IntroductionSection = forwardRef<IntroductionSectionProps, "div">(
  ({ nextStep }, ref) => {
    const height = useBreakpointValue({
      base: "0.75rem",
      // lg: "1.25rem",
      lg: "3rem",
      "2xl": "4.18rem",
    });

    const buttonIconWidth = useBreakpointValue({
      base: "1rem",
      lg: "1.25rem",
      "2xl": "2rem",
    });

    const showLeftElement = useBreakpointValue({ base: false, lg: true });

    return (
      <Flex
        ref={ref}
        maxHeight="100%"
        height="100%"
        flexDirection={{ base: "column", lg: "row" }}
        flex="0 0 auto"
        alignItems={{ base: "flex-start", lg: "center" }}
        justifyContent={{ base: "flex-start", lg: "space-between" }}
        width="100%"
        position="relative"
      >
        {showLeftElement && (
          <Box height="100%" flexGrow={1} maxWidth={{ base: 200, "2xl": 283 }}>
            <Image
              position="absolute"
              left="0"
              top={height}
              animation={backgroundAnimation(19.95)}
              height={`calc(100% - ${height})`}
              src={leftElement}
            />
          </Box>
        )}
        <Stack
          px={{ base: "1.25rem", lg: "0" }}
          width={{ base: "100%", lg: "fit-content" }}
          gap={{ base: "1.25rem", lg: "3rem" }}
          order={{ base: 2, lg: 1 }}
        >
          <Stack
            gap={{ base: "0.75rem", lg: "1.25rem" }}
            maxWidth={{ base: "100%", lg: 680, "2xl": 807 }}
          >
            <Text
              fontFamily="OddvalText"
              fontWeight="600"
              color="#000100"
              lineHeight={{ base: "2rem", lg: "3rem", "2xl": "4rem" }}
              fontSize={{ base: "1.75rem", lg: "2.5rem", "2xl": "3.75rem" }}
              fontStyle="normal"
            >
              Ти - автор, чи авторка, що шукає видавництво?
            </Text>

            <Stack
              fontFamily="Work Sans"
              letterSpacing={{ base: "-0.8px", "2xl": "-1px" }}
              mt="0 !important"
              whiteSpace="pre-wrap"
              lineHeight={{ base: "1.5rem", "2xl": "1.75rem" }}
              fontSize={{ base: "1rem", lg: "1.25rem" }}
              gap={{ base: "1rem", "2xl": "1.75rem" }}
              maxWidth={{ base: "100%", lg: 668 }}
            >
              <Text fontSize="inherit" lineHeight="inherit">
                Перейди на платформу Читанка та зареєструйся, щоб опублікувати
                свою книжку. Опис, деталі, подробиці. Договір нам потрібен, тому
                що... опис, деталі, подробиці
              </Text>
              <Text fontSize="inherit" lineHeight="inherit" mt="0 !important">
                Перейди на платформу Читанка та зареєструйся, щоб опублікувати
                свою книжку. Опис, деталі, подробиці. Договір нам потрібен, тому
                що... опис, деталі, подробиці
              </Text>
            </Stack>
          </Stack>
          <Button
            py={{ base: "1.5rem", "2xl": "2.375rem" }}
            mt="0 !important"
            boxShadow="0.35rem 0.35rem black"
            variant="transparent"
            letterSpacing="-1.2px"
            fontSize={{ lg: "1.25rem", "2xl": "1.5rem" }}
            width={{ base: "auto", lg: "20rem", "2xl": "24rem" }}
            iconSpacing="0"
            gap={{ base: "0.75rem", lg: "1rem", "2xl": "1.5rem" }}
            rightIcon={<ThinkingEmojiIcon width={buttonIconWidth} />}
            onClick={nextStep}
          >
            Що робити
          </Button>
        </Stack>
        <Flex
          justifyContent="flex-end"
          position="relative"
          alignSelf="flex-start"
          flexGrow={1}
          height="100%"
          width="100%"
          order={{ base: 1, lg: 2 }}
          maxWidth={{ base: "100%", lg: 430, "2xl": 608 }}
        >
          <Image
            position="absolute"
            top="0"
            bottom={{ base: undefined, lg: "0" }}
            right={{ base: "20%", md: "8%", lg: "1%" }}
            height={{ base: "95%", lg: "100%" }}
            animation={backgroundAnimation(20)}
            src={rightBackground}
          />
          <Image
            animation={elementAnimation}
            top={height}
            zIndex={2}
            mx="auto"
            position="absolute"
            left={{ base: undefined, lg: "0%" }}
            right={{ base: "25%", md: "15%", lg: "0%" }}
            bottom="0"
            height="95%"
            src={rightElement}
          />
        </Flex>
      </Flex>
    );
  }
);

export default IntroductionSection;
