import { Flex } from "@chakra-ui/react";
import React, { FunctionComponent, ReactNode, useEffect, useRef } from "react";

interface SectionsCarouselProps {
  children: React.ReactElement[];
  tabIndex: number;
}

const SectionsCarousel: FunctionComponent<SectionsCarouselProps> = ({
  children,
  tabIndex,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (containerRef.current && itemsRef.current) {
          containerRef.current.scrollLeft = itemsRef?.current
            ?.slice(0, tabIndex)
            .reduce((sum, current) => {
              return sum + current.offsetWidth;
            }, 0);
        }
      });

      resizeObserver.observe(container);

      return () => resizeObserver.disconnect();
    }
  }, [tabIndex]);

  return (
    <Flex
      ref={containerRef}
      onScroll={(e) => e.preventDefault()}
      overflowX="hidden"
      overflowY="clip"
      scrollBehavior="smooth"
      minWidth="100%"
      minHeight="100%"
      maxHeight="100%"
      pt={{ base: "0", lg: "1rem" }}
      pb={{ base: "1.25rem", lg: "3rem" }}
    >
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          ref: (ref: any) => (itemsRef.current[index] = ref),
        })
      )}
    </Flex>
  );
};

export default SectionsCarousel;
