import * as Yup from "yup";

const registrationScheme = Yup.object().shape({
  name: Yup.string()
    .min(2, "Ім'я занадто коротке.")
    .max(50, "Ім`я занадто довге.")
    .required("Це поле є обов'язковим."),
  surname: Yup.string()
    .min(2, "Прізвище занадто коротке.")
    .max(50, "Прізвище занадто довге.")
    .required("Це поле є обов'язковим."),
  email: Yup.string()
    .min(5, "Email занадто короткий")
    .max(50, "Email занадто довгий.")
    .required("Це поле є обов'язковим.")
    .email("Хибний email, спробуйте ще раз."),
  password: Yup.string()
    .min(6, "Пароль занадто короткий.")
    .max(50, "Пароль занадто довгий.")
    .required("Це поле є обов'язковим."),
});

const signInScheme = Yup.object().shape({
  email: Yup.string()
    .min(5, "Email занадто короткий")
    .max(320, "Email занадто довгий.")
    .required("Це поле є обов'язковим.")
    .email("Хибний email, спробуйте ще раз."),
  password: Yup.string()
    .min(6, "Пароль занадто короткий.")
    .max(50, "Пароль занадто довгий.")
    .required("Це поле є обов'язковим."),
});

const userDataScheme = Yup.object().shape({
  name: Yup.string()
    .min(2, "Ім'я занадто коротке.")
    .max(50, "Ім`я занадто довге.")
    .required("Це поле є обов'язковим."),
  surname: Yup.string()
    .min(2, "Прізвище занадто коротке.")
    .max(50, "Прізвище занадто довге.")
    .required("Це поле є обов'язковим."),
  email: Yup.string()
    .min(5, "Email занадто короткий")
    .max(320, "Email занадто довгий.")
    .required("Це поле є обов'язковим.")
    .email("Хибний email, спробуйте ще раз."),
});

const resetPasswordScheme = Yup.object().shape({
  password: Yup.string()
    .min(6, "Пароль занадто короткий.")
    .max(50, "Пароль занадто довгий.")
    .required("Це поле є обов'язковим."),
  newPassword: Yup.string()
    .min(6, "Пароль занадто короткий.")
    .max(50, "Пароль занадто довгий.")
    .required("Це поле є обов'язковим."),
  confirmedNewPassword: Yup.string()
    .min(6, "Пароль занадто короткий.")
    .max(50, "Пароль занадто довгий.")
    .required("Це поле є обов'язковим.")
    .oneOf([Yup.ref('newPassword')], 'Паролі не співпадають'),
});

const emailScheme = Yup.object().shape({
  email: Yup.string()
    .min(5, "Email занадто короткий")
    .max(320, "Email занадто довгий.")
    .required("Це поле є обов'язковим.")
    .email("Хибний email, спробуйте ще раз."),
});

export {
  registrationScheme,
  signInScheme,
  userDataScheme,
  resetPasswordScheme,
  emailScheme
};
