import {
  Box,
  Flex,
  Stack,
  Text,
  forwardRef,
  StackProps,
  BoxProps,
  FlexProps,
  useBreakpoint,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import ChevronDownIcon from "../../../../ui/icons/ChevronDownIcon";

interface QuestionItemProps extends StackProps {
  question: string;
  answer: string;
}

const QuestionItem = forwardRef<QuestionItemProps, "div">(
  ({ answer, question, ...rest }, ref) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const breakpoint = useBreakpoint();

    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
      <Stack
        ref={ref}
        cursor="default"
        letterSpacing={{ base: "-0.8px", "2xl": "-1px" }}
        whiteSpace="pre-wrap"
        lineHeight={{ base: "1.5rem", "2xl": "1.75rem" }}
        gap="0"
        fontSize={{ base: "md", lg: "xl" }}
        position="relative"
        onMouseLeave={isMobile ? undefined : () => setIsExpanded(false)}
        onMouseEnter={isMobile ? undefined : () => setIsExpanded(true)}
        {...rest}
      >
        <Flex
          onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
          padding="1.25rem"
          borderColor="black"
          borderWidth="0.065rem"
          borderRadius={isExpanded ? "0.5rem 0.5rem 0 0" : "0.5rem"}
          borderBottomColor={isExpanded ? "white" : "black"}
          alignItems="center"
          justifyContent="space-between"
          gap='0.5rem'
          bg={isExpanded ? "white" : "transparent"}
          transition="all 0.2s"
          boxShadow={isExpanded ? "2px 2px black" : "none"}
        >
          <Text fontSize="inherit" lineHeight="inherit">
            {question}
          </Text>
          <Box
            transition="all 0.3s"
            transform={isExpanded ? `rotate(180deg)` : undefined}
          >
            <ChevronDownIcon width="1rem" />
          </Box>
        </Flex>

        {isExpanded && (
          <Box
            boxShadow="2px 2px black"
            padding="0 1.25rem 1.25rem"
            fontSize={{ base: "sm", "2xl": "md" }}
            lineHeight={{ base: "1rem", "2xl": "1.5rem" }}
            letterSpacing="-0.8px"
            borderWidth="0 0.065rem 0.065rem"
            borderColor="black"
            borderRadius="0 0 0.5rem 0.5rem"
            top="100%"
            zIndex={2}
            width="100%"
            position="absolute"
            mt="0 !important"
            transition="all 0.2s"
            bg={isExpanded ? "white" : "transparent"}
          >
            {answer}
          </Box>
        )}
      </Stack>
    );
  }
);

export default QuestionItem;
