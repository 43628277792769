import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

interface BreadcrumbProps {
  breadcrumbItems: {
    title: string;
    path?: string;
    payload?: object;
  }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbItems }) => {

  const filteredItems = breadcrumbItems.filter(item => item.title !== '');

  return (
    <Stack
      flexDirection="row"
      flexWrap='wrap'
      gap={{ base: "0.25rem", md: "0.5rem" }}
      fontSize={{
        base: "0.75rem",
        sm: "0.75rem",
        xl: "0.875rem",
      }}
      divider={
        <Text variant="secondary" fontSize="inherit">
          /
        </Text>
      }
    >
      <Stack
        gap={{ base: "0.25rem", md: "0.5rem" }}
        flexDirection="row"
        divider={
          <Text variant="primary" fontSize="inherit">
            /
          </Text>
        }
      >
        {filteredItems.slice(0, filteredItems.length - 1).map((item, index) => {
          return (
            <Box key={index}>
              {typeof item.path !== "undefined" ? (
                <NavLink
                  to={item?.path || "/"}
                  state={item.payload}
                >
                  <Text whiteSpace='nowrap' fontSize="inherit" fontWeight={400} variant="primary">
                    {item.title}
                  </Text>
                </NavLink>
              ) : (
                <Text whiteSpace='nowrap' fontSize="inherit" variant="secondary">{item.title}</Text>
              )}
            </Box>
          );
        })}
      </Stack>
      <Text fontSize="inherit" variant="secondary">
        {filteredItems[filteredItems.length - 1].title}
      </Text>
    </Stack>
  );
};

export default Breadcrumb;
