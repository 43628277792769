import { FunctionComponent } from "react";

interface DiscoverMoreIconProps {
  width?: string | number;
}

const DiscoverMoreIcon: FunctionComponent<DiscoverMoreIconProps> = ({
  width,
}) => {
  return (
    <svg
      width={width??"40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.314 29.1708C33.314 29.1708 34.1947 22.4783 34.0491 20.1837C33.9143 17.8794 34.8944 7.33279 34.8944 7.33279L32.8389 5.05066C32.8389 5.05066 22.9827 4.53552 21.3416 4.0924C19.692 3.61821 6.68762 5.12344 6.68762 5.12344L9.84314 8.627C9.84314 8.627 21.5315 7.49201 23.4125 7.44738C25.3041 7.3931 32.1174 7.33059 32.1174 7.33059C31.4652 14.5353 31.0186 18.3917 30.7086 20.6115C30.6455 21.037 30.4948 22.1624 30.3538 23.6865C30.1318 26.1763 30.1176 28.2074 30.1395 29.546L33.314 29.1708Z"
        fill="#000100"
      />
      <path
        d="M32.5638 5.75684C32.5638 5.75684 25.3273 13.5253 23.5813 15.2632C21.8352 17.001 14.9024 23.2568 13.7673 24.882C12.6321 26.5072 5 33.9021 5 33.9021L7.69902 36.0618C7.69902 36.0618 13.6058 29.1293 15.2949 27.9683C16.984 26.8073 23.4875 19.7594 24.6676 18.4962C25.8478 17.233 34.1853 8.00899 34.1853 8.00899L32.5638 5.75684Z"
        fill="#000100"
      />
    </svg>
  );
};

export default DiscoverMoreIcon;
