import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { Book } from "../../../HomePage/components/Book/Book";
import { BookPreviewType } from "../../../HomePage/api/types";
interface BookListProps {
  books: BookPreviewType[];
}

const BookList: React.FC<BookListProps> = ({ books }) => {
  return (
    <Box
      borderRadius={{ base: "1rem", md: "1.5rem", xl: "2rem" }}
      width="100%"
      transition="all 0.3s"
      marginBottom={{
        base: "2rem",
        sm: "1.5rem",
        md: "3rem",
        "2xl": "6.25rem",
      }}
    >
      <SimpleGrid
        columns={{ base: 2, sm: 3, md: 4, xl: 5 }}
        spacing={{ base: "1rem", md: "1.25rem", lg: "1.5rem", xl: "2rem" }}
        rowGap={{ base: "2rem", md: "2.5rem", lg: "3.15rem", xl: "4.25rem" }}
      >
        {books.map((book) => {
          return (
            <Book
              key={book.id}
              transliteratedName={book.transliteratedName}
              id={book.id}
              name={book.name}
              cover={book.cover}
              coverFullSize={book.coverFullSize}
              authors={book.authors}
            />
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default BookList;
